@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.accordion {
    width: 100%;
    max-width: 568px;
    margin-top: 45px;

    .accordion-address {
        position: relative;
        padding: 10px 19px;
        font-size: 18px;
        color: $charcoal-grey;
        @media (max-width: 667px) {
            padding: 12px 14px;
            font-size: 12px;
        }

        &:hover {
            cursor: pointer;
        }

        .shipping-tab__name {
            margin-bottom: 5px;
            font-family: proxima_nova_bold, Helvetica, sans-serif;
        }

        .shipping-tab__address {
            font-family: proxima_nova_regular, Helvetica, sans-serif;
        }

        &.default:after {
            position: absolute;
            display: block;
            content: '';
            width: 5px;
            height: 8px;
            top: 50%;
            right: 12px;
            border-right: 2px solid #0baff0;
            border-bottom: 2px solid #0baff0;
            border-radius: .5px;
            transform: translateY(-50%) rotate(45deg);
        }

        .radio-input {
            display: none;
        }

        @media (max-width: 480px) {
            display: flex;
            padding: 20px 14px;
            border-bottom: 1px solid $pale-grey;

            .radio-input {
                display: inline-block;
            }
        }

        &.empty-state {
            @media (max-width: 480px) {
                .shipping-tab__name {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
            }
        }
    }

    .card-head {
        display: flex;
        justify-content: space-between;

        font-family: proxima_nova_light, Helvetica, sans-serif;
        font-size: 12px;
        line-height: 1.17;
        text-align: left;
        color: $charcoal-grey;
        margin-bottom: 10px;

        & > span:last-of-type {
            display: inline-block;
            width: 30%;
        }

        @media (max-width: 480px) {
            display: none;
        }
    }

    .headline {
        display: flex;
        justify-content: space-between;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        font-size: 17px;
        font-weight: bold;
        color: #4a4a4a;
        margin-bottom: 12px;

        .edit-button {
            font-size: 14px;
            font-weight: 600;
            color: #0baff0;
            padding-left: 10px;

            &.remove {
                opacity: .5;
            }

            &:hover {
                cursor: pointer;
            }
        }

        @media (max-width: 480px) {
            background: #f6f7f8;
            border-top: solid 1px $pinkish-grey;
            line-height: 49px;
            padding: 0 15px;
            font-size: 14px;
            margin-bottom: 0;

        }
    }

    .accordion-default-item {
        position: relative;
        background: #fafafc;
        font-family: proxima_nova_regular, Helvetica, sans-serif;

        text-align: left;
        color: $charcoal-grey;
        border-radius: 2px;
        border: solid 1px $pinkish-grey;

        &:hover {
            cursor: pointer;
        }

        @media (max-width: 480px) {
            display: none;
        }
    }

    .accordion-arrow {
        position: absolute;
        width: 5px;
        height: 5px;
        border-right: 2px solid $cool-grey;
        border-bottom: 2px solid $cool-grey;
        border-radius: .5px;
        top: 50%;
        right: 12px;
        transform: translateY(-50%) rotate(45deg);
        transition: .5s;

        &.opened {
            transform: translateY(-50%) rotate(-135deg);
        }
    }

    .accordion-items-list {
        position: relative;
        overflow: hidden;
        height: 0;

        &.list-opened {
            height: auto;
            border: solid 1px $pinkish-grey;
            border-top: none;

            @media (max-width: 480px) {
                border: none;
            }
        }

        & > span {
            display: inline-block;
            font-size: 14px;
            color: $blue;
            padding: 10px 14px 14px;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;

            &:hover {
                cursor: pointer;
            }
        }
        @media (max-width: 480px) {
            height: auto;

            & > div:after {
                position: absolute;
                display: block;
                content: '';
                width: 12px !important;
                height: 12px !important;
                top: 50%;
                right: 12px;
                border-right: 2px solid $pinkish-grey !important;
                border-bottom: 2px solid $pinkish-grey !important;
                border-radius: .5px;
                transform: translateY(-50%) rotate(-45deg) !important;
            }
        }
    }

    @media (max-width: 480px) {
        margin-top: 0;
    }
}

.with-loader {
    &:after {
        position: absolute;
        content: '';
        right: 50%;
        top: 50%;
        display: block;
        font-size: 10px;
        width: 1em;
        height: 1em;
        margin-top: -0.5em;
        animation: spinner 1500ms infinite linear;
        border-radius: 0.5em;
        z-index: 3;
        box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    }
    &:before {
        position: absolute;
        content: '';
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: $white;
        opacity: .5;
    }
}

.empty-state {
    background: #fafafc;
    border-radius: 2px;
    border: solid 1px #c7c7c7;
}

.card-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    padding: 12px 14px;
    font-size: 14px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;

    @media (max-width: 480px) {
        flex-direction: column;
    }

    &:hover {
        cursor: pointer;
    }

    &.default:after {
        position: absolute;
        display: block;
        content: '';
        width: 5px;
        height: 8px;
        top: 50%;
        right: 12px;
        border-right: 2px solid #0baff0;
        border-bottom: 2px solid #0baff0;
        border-radius: .5px;
        transform: translateY(-50%) rotate(45deg);

        @media (max-width: 480px) {
            display: none;
        }
    }

    &-logo {
        width: 60%;
        display: flex;
        align-items: center;

        img {
            display: inline-block;
            margin-right: 12px;
            width: 37px;
            height: 22px;
        }


        .radio-input {
            display: none;

            @media (max-width: 480px) {
                display: inline-block;
                margin-right: 5px;
            }
        }
    }

    .expires {
        width: 40%;
        text-align: center;

        @media (max-width: 480px) {
            height: 14px;
        }
    }

    &__info {
        @include flex(row, space-between, center);
        width: 50%;

        @media (max-width: 480px) {
            width: 100%;
        }
    }

    &__address {
        position: relative;
        left: -13px;
        width: calc(50% - 13px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;


        @media (max-width: 480px) {
            left: 5px;
            top: 5px;
            width: calc(100% - 44px);
        }
    }
}

.card-form-wrapper {
    form {
        display: flex;
        flex-direction: column;
    }

    input::placeholder {
        opacity: 1;
        font-weight: 100;
        color: #bbbbc2;
    }

    .card-element__wrapper {
        min-height: 58px;
        padding-bottom: 15px;
    }

    #stripe-iframe-wrapper {
        &.StripeElement--invalid {
            background-color: #fbe8ea;
            border: solid 1px $scarlet;
            color: $scarlet;
        }
        &.StripeElement--complete + .block-errors {
            .block-errors__text {
                visibility: hidden;
            }
        }

    }

    .block-errors__text {
        bottom: -8px;
    }

    @media (max-width: 480px) {
        padding: 15px;

        .market-place-settings-content__actions {
            margin-top: 15px;

            .common-button {
                margin: 5px;
            }
        }
    }
}
