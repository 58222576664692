@import "./colors.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1024px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.input {
    display: block;
    font-size: 14px;
    color: $black;
    font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
    border: solid 1px $pinkish-grey;
    box-sizing: border-box;
    padding-left: 17px;
    padding-right: 17px;
    border-radius: 4px;
    background-color: $white;
    font-weight: normal;

    &-large {
        width: 568px;
        height: 44px;
    }

    &-big {
        width: 440px;
        height: 44px;
    }

    &-middle {
        width: 278px;
        height: 44px;
    }

    &-small {
        width: 216px;
        height: 44px;
    }

    &-label {
        display: block;
        font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
        font-size: 12px;
        line-height: 1.17;
        text-align: left;
        color: $purpley-grey;
        text-transform: uppercase;

        &__text {
            display: block;
            padding-top: 25px;
            padding-bottom: 14px;
            font-family: 'proxima_nova_regular', Helvetica, sans-serif;;
        }

        &_cards {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding-bottom: 14px;
        }
    }

    &:focus {
        outline: none;
    }

    &.error {
        border: solid 1px $cerise;
    }

    &::-webkit-input-placeholder {
        font-family: 'proxima_nova_regular', Helvetica, sans-serif;
        font-size: 14px;
        text-align: left;
        color: $pinkish-grey;
        font-weight: 100;
    }

    &::-moz-placeholder {
        font-family: 'proxima_nova_regular', Helvetica, sans-serif;
        font-size: 14px;
        text-align: left;
        color: $pinkish-grey;
        font-weight: 100;
    }

    &:-ms-input-placeholder {
        font-family: 'proxima_nova_regular', Helvetica, sans-serif;
        font-size: 14px;
        text-align: left;
        color: $pinkish-grey;
        font-weight: 100;
    }
}

input:disabled {
    background: $white-one;
}

.form {
    position: relative;

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__btns {
            display: flex;
            justify-content: flex-end;
        }

        &__part {
            display: inline-block;
        }
    }

    &__title {
        display: block;
        font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
        font-size: 17px;
        font-weight: bold;
        color: $charcoal-grey;

        &--bold {
            font-family: 'proxima_nova_bold', Helvetica, sans-serif;
        }
    }

    &__text {
        display: block;
        font-family: 'proxima_nova_regular', Helvetica, sans-serif;
        font-size: 14px;
        font-weight: normal;
        color: $purpley-grey;
        line-height: 20px;

        &__link {
            text-decoration: none;
            cursor: pointer;
        }

        &-bold {
            font-family: 'proxima_nova_regular', Helvetica, sans-serif;
            font-weight: bold;
        }
    }
}

.cerise {
    color: $cerise;
}

.azure {
    color: $blue;
}

.green {
    color: $green;
}

.violet {
    color: $violet;

    &-bg {
        background: $violet;
    }
}

.squash {
    color: $squash;

    &-bg {
        background: $squash;
    }
}

.hide {
    display: none;

    @media (max-width: 480px) {
        &-for-mobile {
            display: none;
        }
    }
}

.text-align {
    &__left {
        text-align: left;
    }

    &__right {
        text-align: right;
    }

    &__center {
        text-align: right;
    }
}

.fw-bold {
    font-weight: bold;
}

.fw-normal {
    font-weight: normal;
}

.fw-lighter {
    font-weight: lighter;
}

.z-index-100 {
    z-index: 100;
}

.divider {
    display: flex;
    background: $gray-in-active;

    &-row {
        flex-direction: row;
        width: 100%;
        height: 2px;
    }

    &-col {
        flex-direction: column;
        width: 2px;
        height: 100%;
    }
}

.space-between {
    display: flex;
    justify-content: space-between;

    &-col {
        flex-direction: column;
        height: 100%;
    }

    &-row {
        flex-direction: row;
        width: 100%;
    }
}

.width {
    &-100 {
        width: 100%;
    }
}

.wrapper-tooltip {
    font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
}


@keyframes right-panel-appear {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}
