@import "../styles/colors";
@import "../styles/mixins";
@import "../styles/variables";


.form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__title {
        display: block;
        margin-bottom: 20px;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        font-size: 17px;
    }

    &__link {
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        font-size: 12px;
        color: $blue;
    }

    &-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 26px;
        align-items: flex-end;

        &__part {
            width: 49%;

            &_small {
                max-width: 110px;
                width: 100%;

                .Select-arrow-zone {
                    padding-left: 8px;
                    border-left: 1px solid $pinkish-grey;
                }
            }

            &.card {
                max-width: 174px;
                width: 100%;
            }

            &.card,
            &.cvv {
                .form-control__input {
                    padding-left: 15px;

                    &::-webkit-input-placeholder {
                        font-size: 16px;
                    }

                    &::-moz-placeholder {
                        font-size: 16px;
                    }

                    &:-ms-input-placeholder {
                        font-size: 16px;
                    }

                    &:-moz-placeholder {
                        font-size: 16px;
                    }
                }
            }
        }

        &__one-third {
            width: 32%;
        }

        &__two-third {
            width: 66%;
        }
    }

    &-edit-btn {
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        font-size: 14px;
        color: $blue;
        line-height: 14px;
        cursor: pointer;
    }

    &-control {
        width: 100%;

        &__top {
            display: flex;
            justify-content: space-between;

            & > .form__title {
                margin: 0;
            }
        }

        &__label {
            display: inline-block;
            margin-bottom: 13px;
            text-indent: 3px;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
            font-size: 12px;
            color: $purpley-grey;
            text-transform: uppercase;
        }

        &-tab {
            width: 100%;
            margin-top: 12px;

            &__top {
                display: flex;
                justify-content: space-between;
                padding-right: 105px;
                margin-bottom: 10px;

                span {
                    font-family: proxima_nova_light, Helvetica, sans-serif;
                    font-size: 12px;
                    color: $charcoal-grey;
                }
            }

            .menu-shipping-address, .menu-payment {
                position: relative;
                display: flex;
                flex-direction: column;
                height: 50px;
                background-color: #fafafc;
                border: solid 1px #c7c7c7;
                border-radius: 2px;

                &:after {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 5px;
                    border-right: 2px solid #a8aab7;
                    border-bottom: 2px solid #a8aab7;
                    border-radius: .5px;
                    top: 50%;
                    right: 12px;
                    transform: translateY(-50%) rotate(45deg);
                }
            }

            .menu-payment {
                height: 42px;
            }

            .shipping-tab, .payment-tab {
                position: absolute;
                width: 100%;
                top: 100%;
                left: -1px;
                height: 0;
                visibility: hidden;
                overflow: hidden;
                background: #ffffff;
                border: solid 1px #c7c7c7;

                &.show {
                    height: auto;
                    visibility: visible;
                    transition: .4s;
                    overflow-y: 100%;
                    z-index: 201;
                }

                &__add {
                    padding: 2px 0;
                    font-size: 14px;
                    color: #0baff0;
                    margin-bottom: 5px;
                }

                &__block {
                    position: relative;
                    height: 50px;
                    background-color: #fafafc;
                    border: solid 1px #c7c7c7;
                    border-radius: 2px;
                }

                &__box {
                    position: relative;
                    height: 50px;
                    box-sizing: border-box;
                    
                    &:hover, &:active {
                        background: #fafafc;
                    }
                }

                &__label {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 8px 18px;
                    cursor: pointer;
                }

                &__input {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    opacity: 0;
                }

                &__name {
                    font-family: proxima_nova_semibold, Helvetica, sans-serif;
                    font-size: 14px;
                }

                &__address {
                    margin-top: 10px;
                    font-family: proxima_nova_regular, Helvetica, sans-serif;
                    font-size: 12px;
                }
            }

            .shipping-tab__input:checked + .shipping-tab__label {
                &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 5px;
                    height: 8px;
                    top: 50%;
                    right: 8px;
                    border-right: 2px solid #0baff0;
                    border-bottom: 2px solid #0baff0;
                    border-radius: .5px;
                    transform: translateY(-50%) rotate(45deg);
                }
            }

            & .payment-tab {
                background-color: #fafafc;
                border: solid 1px #c7c7c7;
                border-radius: 2px;

                &__left {
                    display: flex;
                    align-items: center;
                }

                &__right {
                    font-size: 14px;
                }

                &__box {
                    height: 42px;
                }

                &__label {
                    padding: 10px 105px 10px 18px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    box-sizing: border-box;
                }

                &__card {

                    &-icon {
                        width: 37px;
                        height: 22px;
                    }

                    &-ending {
                        font-size: 14px;
                        margin-left: 12px;
                    }

                }
            }
        }

        &__select {
            width: 100%;
            background-color: #fafafc;
            border-radius: 2px;

            .Select {

                &-placeholder {
                    line-height: 44px;
                    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
                }

                &-control {
                    height: 44px;
                    max-width: 110px;
                    width: 100%;

                    .Select-value {
                        line-height: 44px;
                        font-family: proxima_nova_regular, Helvetica, sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: #354052;
                        background-image: linear-gradient(to top, #f2f4f7, #ffffff);
                    }
                }

                &.is-focused:not(.is-open) > .Select-control {
                    box-shadow: none;
                    border-color: #d9d9d9 #ccc #b3b3b3;
                }

                &-arrow {
                    border-color: transparent #a8aab7 #a8aab7 transparent;
                    border-style: solid;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    height: 5px;
                    width: 5px;
                    position: relative;
                    transform: rotate(45deg);
                    border-radius: .5px;

                    &-zone {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }

                &.is-open {
                    .Select-arrow {
                        border-color: #a8aab7 transparent transparent #a8aab7;
                        border-style: solid;
                        border-width: 2px 0 0 2px;
                        display: inline-block;
                        height: 5px;
                        width: 5px;
                        position: relative;
                        transform: rotate(45deg);
                        border-radius: .5px;
                    }
                }

                &-menu {
                    max-height: 160px;
                    overflow-y: auto;
                }
            }
        }

        &__input {
            width: 100%;
            padding: 13px;
            border: 1px solid $pinkish-grey;
            border-radius: 2px;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
            color: $charcoal-grey;
            font-size: 14px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            &:focus {
                outline: none;
            }

            &::-webkit-input-placeholder {
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                color: $pinkish-grey-dk;
                font-size: 14px;
            }

            &::-moz-placeholder {
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                color: $pinkish-grey-dk;
                font-size: 14px;
            }

            &:-ms-input-placeholder {
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                color: $pinkish-grey-dk;
                font-size: 14px;
            }

            &:-moz-placeholder {
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                color: $pinkish-grey-dk;
                font-size: 14px;
            }
        }
    }

    &-logos {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 50%;
        bottom: -10px;
        margin-left: auto;

        &__icon {
            margin-left: 7px;
            width: 50px;
            height: 30px;
        }
    }
}

.sign-up-form {
    .common-input-wraper {
        margin-bottom: 9px;
    }
}

.flex-start {
    align-items: flex-start;
}

.form-settings-shipping {
    .state {
        width: 100%;
    }

    .shipping-wrapper {
        input::placeholder {
            font-family: proxima_nova_regular, Helvetica, sans-serif;;
            font-size: 14px;
            text-align: left;
            color: #9b9b9b;
        }

    }

    @media all and (max-width: 480px) {
        .state, .zip {
            width: 48%;
            padding-bottom: 0;
            margin-top: 5px;
        }
    }
}
