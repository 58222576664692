@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.product-card {
    position: relative;
    width: 170px;
    border: solid 1px $pinkish-grey-dk;
    box-sizing: border-box;
    background: $white;
    cursor: pointer;

    .product-main-info {
        padding: 8px 10px;
    }

    &-link {
        text-decoration: none;
    }

    &__img {
        position: relative;
        margin: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        cursor: pointer;
        display: block;
    }

    &__favorites {
        position: absolute;
        right: 10px;
        width: 15px;
        height: 13px;
        bottom: 5px;
        cursor: pointer;
        z-index: 1;

        &__img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            min-width: 100%;
            min-height: 100%;
        }
    }

    &__body {
        position: relative;
    }

    &-coins {
        @include flex(row, center, center);
        position: absolute;
        top: 0;
        left: 3px;
        padding: 2px 4px;
        font-family: $font-semibold;
        font-size: 14px;
        line-height: 1;
        text-transform: capitalize;
        background-color: $white;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .5);
    }

    &-coins__text {
        line-height: normal;
        color: $cerise;
    }

    &-coins__count {
        line-height: normal;
        color: $charcoal-grey;
    }

    &-coins__icon {
        margin: 0 3px;
        max-width: 14px;
        height: 13px;
    }
}

.product-main-info {
    @include flex(row, space-between, flex-start);
    width: 100%;
    cursor: pointer;
    font-size: 12px;

    &__left {
        max-width: 60%;
        width: 100%;
        text-align: left;
    }

    &__right {
        min-width: 25%;
        text-align: right;
    }

    &__title {
        max-width: 100%;
        padding-right: 5px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 14px;
        letter-spacing: normal;
        color: $charcoal-grey;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: $font-semibold;
    }

    &__brand {
        max-width: 100%;
        padding-right: 5px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 14px;
        letter-spacing: normal;
        color: $purpley-grey;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: $font-regular;
    }

    &__price-current {
        font-family: $font-semibold;
        color: $cerise;
        line-height: 14px;
    }

    &__price-icon {
        max-width: 11px;
        max-height: 10px;
        vertical-align: middle;
        transform: translateY(-1px);
    }

    &__price-old {
        margin-top: 2px;
        text-decoration: line-through;
        color: $charcoal-grey;
        line-height: 14px;
        font-family: $font-regular;
    }
}

