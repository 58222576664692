@import '../../../../styles/colors';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

$logo-width: 190px;
$logo-height: 66px;

.logged-in {
    @include flex(column, center, center);
    padding: 60px 0 140px;
    max-width: 420px;
    width: 50%;
    margin: 0 auto;

    @media (max-width: 768px) and (min-width: 481px) {
        width: 70%;
        padding-bottom: 90px;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        padding: 48px 12px 50px;
        width: 100%;
    }

    &__logo-wrapper {
        width: $logo-width;
        height: $logo-height;

        @media (max-width: 480px) {
            display: none;
        }
    }

    &__logo {
        width: 100%;
        height: auto;
        max-height: 100%;
    }

    &__heading {
        margin-top: 51px;
        font-family: $font-semibold;
        font-size: 30px;
        line-height: 1.38;
        color: $charcoal-grey;
        @media (max-width: 1024px) and (min-width: 961px) {
            font-size: 28px;
        }

        @media (max-width: 960px) and (min-width: 481px) {
            font-size: 24px;
        }

        @media (max-width: 480px) {
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 18px;
        }
    }

    &-form {
        width: 100%;
        margin-top: 20px;
        font-family: $font-regular;
        @media (max-width: 480px) {
            max-width: 100%;
            margin-top: 0;
        }
    }

    &-form__item {
        @include flex(column, center, stretch);
    }

    &-form__label {
        align-self: flex-end;
        font-size: 12px;
        color: $blue;
        line-height: 2;
        cursor: pointer;
    }

    &-form__field {
        height: 62px;
        padding: 0 16px;
        font-size: 20px;
        line-height: 62px;
        border-radius: 2px;
        background-color: #fafafa;
        border: 1px solid #c7c7c7;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 667px) {
            height: 44px;
            line-height: 44px;
            padding-left: 10px;
            font-size: 14px;
        }
    }

    &-form__submit {
        width: 100%;
        margin-top: 25px;
        padding: 19px 16px;
        font-family: $font-regular;
        font-size: 26px;
        line-height: 23px;

        @media (max-width: 960px) and (min-width: 668px) {
            padding: 16px 16px;
            font-size: 22px;
        }

        @media (max-width: 667px) {
            padding: 12px 10px;
            font-size: 17px;
        }

        @media (max-width: 480px) {
            margin-top: 12px;
        }
    }
}
