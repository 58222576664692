@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.mp-profile {
    display: flex;
    flex-direction: column;
    max-width: 568px;
    width: 100%;

    &-form {
        max-width: 100%;
        margin: 0;
        margin-top: 40px;

        @media (max-width: 480px) {
            padding: 0 15px;
            margin-top: 0;

            .header-btn {
                position: absolute;
                top: 20px;
                margin-top: 0;
                padding: 0 !important;
                background: transparent;
                right: 12px;
                color: $purpley-grey;
                font-size: 17px;
                font-family: proxima_nova_regular, Helvetica, sans-serif;
            }
            .common-button.transparent {
                display: none;
            }
        }

        &__title {
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            font-size: 17px;
            color: $charcoal-grey;
            margin-bottom: 20px;

            @media (max-width: 480px) {
                display: none;
            }

        }

        form {
            width: 100%;
        }

        .common-input-wraper, .form-control {
            margin-top: 25px;

            label {
                display: inline-block;
                font-family: proxima_nova_light, Helvetica, sans-serif;
                font-size: 12px;
                font-weight: 600;
                line-height: 1.17;
                color: $purpley-grey;
                margin-bottom: 14px;
                text-transform: uppercase;
            }
        }
    }

    &-coins {
        margin-left: 20px;
    }

    &-coins__value-container {
        @include flex(row, flex-start, center);
    }

    &-coins__value {
        margin-left: 10px;
        font-family: $font-semibold;
        font-size: 34px;
        line-height: normal;
        color: $cerise;
    }

    &-coins__icon {
        width: 32px;
        height: 31px;
    }

    &-coins__text {
        margin-top: 8px;
        font-family: $font-semibold;
        font-size: 22px;
        line-height: 1;
        color: $charcoal-grey;
    }

}
.profile-photo {
    position: relative;
    display: inline-block;
    height: 122px;
    width: 122px;

    &-container {
        @include flex(row, flex-start, center);
    }

    input[name=file] {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    &.no-photo {
        &:hover {
            &:before {
                position: absolute;
                content: '+';
                cursor: pointer;
                left: 2px;
                top: 2px;
                font-size: 40px;
                line-height: 116px;
                height: 116px;
                width: 116px;
                border-radius: 999rem;
                background: $purpley-grey;
                color: $white;
            }
        }
    }

    @media (max-width: 480px) {
        margin-left: 20px;
    }
}

.cropper-modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;

    .cropper-block {
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: 780px;
        width: 100%;
        transform: translate(-50%, -50%);
        background: $white;
        border-radius: 5px;

        .title {
            line-height: 44px;
            padding-left: 24px;
            font-size: 17px;
            text-align: left;
            color: $charcoal-grey;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
        }

        .cropper-no-image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 320px;
            height: 320px;
            border-radius: 50%;
            color: $cerise;
            font-size: 24px;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            text-align: center;
            cursor: pointer;
            background: #e9e9e9;
            margin: 0 auto;
        }
    }

    .cropper-content {
        position: relative;
        padding: 28px 0 12px;
        background: #d9d9d9;
    }

    .cropper-controls {
        position: relative;
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .cropper-action-btns {
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);

            .common-button {
                width: 93px;
                height: 32px;
                padding: 0;

                &.transparent {
                    border: none;
                }
            }
        }

        @media (max-width: 480px) {
            display: block;

            .cropper-action-btns {
                position: relative;
                top: 0;
                right: 0;
                transform: translateY(0);
                margin-top: 20px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }

    .zoom-input {
        display: flex;
        justify-content: center;
        align-items: center;

        .zoom-icon {
            display: inline-block;
            background: url('../../../images/img-landscape.svg') 0 0 no-repeat;
            background-size: cover;
            &.mini {
                width: 32px;
                height: 14px;
            }

            &.big {
                width: 44px;
                height: 21px;
            }
        }
        // new
        input[type=range] {
            -webkit-appearance: none;
            width: 100%;
            margin: 7.65px 20px;
        }
        input[type=range]:focus {
            outline: none;
        }
        input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 1.7px;
            cursor: pointer;
            box-shadow: 0px 0px 0.5px #201c16, 0px 0px 0px #2f2920;
            background: rgba(0, 16, 87, 0.5);
            border-radius: 0.4px;
            border: 0.4px solid rgba(1, 1, 1, 0.68);
        }
        input[type=range]::-webkit-slider-thumb {
            box-shadow: 0px 0px 0px rgba(52, 100, 165, 0.7), 0px 0px 0px rgba(58, 112, 184, 0.7);
            border: 0.9px solid rgba(94, 60, 180, 0.6);
            height: 17px;
            width: 17px;
            border-radius: 47px;
            background: #ffffff;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -8.05px;
        }
        input[type=range]:focus::-webkit-slider-runnable-track {
            background: rgba(0, 21, 113, 0.5);
        }
        input[type=range]::-moz-range-track {
            width: 100%;
            height: 1.7px;
            cursor: pointer;
            box-shadow: 0px 0px 0.5px #201c16, 0px 0px 0px #2f2920;
            background: rgba(0, 16, 87, 0.5);
            border-radius: 0.4px;
            border: 0.4px solid rgba(1, 1, 1, 0.68);
        }
        input[type=range]::-moz-range-thumb {
            box-shadow: 0px 0px 0px rgba(52, 100, 165, 0.7), 0px 0px 0px rgba(58, 112, 184, 0.7);
            border: 0.9px solid rgba(94, 60, 180, 0.6);
            height: 17px;
            width: 17px;
            border-radius: 47px;
            background: #ffffff;
            cursor: pointer;
        }
        input[type=range]::-ms-track {
            width: 100%;
            height: 1.7px;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
        input[type=range]::-ms-fill-lower {
            background: rgba(0, 11, 62, 0.5);
            border: 0.4px solid rgba(1, 1, 1, 0.68);
            border-radius: 0.8px;
            box-shadow: 0px 0px 0.5px #201c16, 0px 0px 0px #2f2920;
        }
        input[type=range]::-ms-fill-upper {
            background: rgba(0, 16, 87, 0.5);
            border: 0.4px solid rgba(1, 1, 1, 0.68);
            border-radius: 0.8px;
            box-shadow: 0px 0px 0.5px #201c16, 0px 0px 0px #2f2920;
        }
        input[type=range]::-ms-thumb {
            box-shadow: 0px 0px 0px rgba(52, 100, 165, 0.7), 0px 0px 0px rgba(58, 112, 184, 0.7);
            border: 0.9px solid rgba(94, 60, 180, 0.6);
            height: 17px;
            width: 17px;
            border-radius: 47px;
            background: #ffffff;
            cursor: pointer;
            height: 1.7px;
        }
        input[type=range]:focus::-ms-fill-lower {
            background: rgba(0, 16, 87, 0.5);
        }
        input[type=range]:focus::-ms-fill-upper {
            background: rgba(0, 21, 113, 0.5);
        }

        @media (max-width: 480px) {
            width: 80%;
            margin: 10px auto;
        }

        // new
    }


}
.cropper-crop-box,
.cropper-view-box,
.cropper-container,
.cropper-wrap-box,
.cropper-drag-box {
    border-radius: 50%;
}

.cropper-view-box {
    outline: 0;
}

.cropper-face {
    background-color: #d9d9d9;
}

.cropper-line, .cropper-point {
    display: none;
}

.close-cropper {
    position: absolute;
    top: 18px;
    right: 22px;
    height: 18px;
    width: 18px;
    background: url('../../../images/close-mini__icon.svg') 0 0 no-repeat;
    cursor: pointer;
    fill: red;
    background-size: cover;
}
