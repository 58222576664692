@-webkit-keyframes animateOpacity {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-moz-keyframes collage {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes collage {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@mixin favorites-block-animation($type: appear, $view: desktop){
    @if $type == appear {
        from {
            -webkit-transform: translateX(100%);
            -moz-transform: translateX(100%);
            -o-transform: translateX(100%);
            -ms-transform: translateX(100%);
            transform: translateX(100%);
            opacity: 0;
        }
        to {
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

    @if $type == disappear and $view == desktop {
        from {
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
            min-width: 220px;
            width: 220px;
            margin-left: -10px;
        }
        to {
            -webkit-transform: translateX(100%);
            -moz-transform: translateX(100%);
            -o-transform: translateX(100%);
            -ms-transform: translateX(100%);
            transform: translateX(100%);
            opacity: 0;
            min-width: 0;
            width: 0;
            margin-left: 0;
        }
    }

    @if $type == disappear and $view == mobile {
        from {
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
            min-width: 100%;
            width: 100%;
        }
        to {
            -webkit-transform: translateX(100%);
            -moz-transform: translateX(100%);
            -o-transform: translateX(100%);
            -ms-transform: translateX(100%);
            transform: translateX(100%);
            opacity: 0;
            min-width: 0;
            width: 0;
        }
    }
}

// Animation favorites-appear
@keyframes favorites-appear {
    @include favorites-block-animation(appear, desktop);
}

@-webkit-keyframes favorites-appear {
    @include favorites-block-animation(appear, desktop);
}

@-moz-keyframes favorites-appear {
    @include favorites-block-animation(appear, desktop);
}

// Animation favorites-disappear
@keyframes favorites-disappear {
    @include favorites-block-animation(disappear, desktop);
}

@-webkit-keyframes favorites-disappear {
    @include favorites-block-animation(disappear, desktop);
}

@-moz-keyframes favorites-disappear {
    @include favorites-block-animation(disappear, desktop);
}

// Animation mobile-favorites-appear
@keyframes mobile-favorites-appear {
    @include favorites-block-animation(appear, mobile);
}

@-webkit-keyframes mobile-favorites-appear {
    @include favorites-block-animation(appear, mobile);
}

@-moz-keyframes mobile-favorites-appear {
    @include favorites-block-animation(appear, mobile);
}

// Animation mobile-favorites-disappear
@keyframes mobile-favorites-disappear {
    @include favorites-block-animation(disappear, mobile);
}

@-webkit-keyframes mobile-favorites-disappear {
    @include favorites-block-animation(disappear, mobile);
}

@-moz-keyframes mobile-favorites-disappear {
    @include favorites-block-animation(disappear, mobile);
}

@keyframes hintloading {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
}
