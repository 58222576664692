@import url(https://fonts.googleapis.com/css?family=Prompt&display=swap);
.how-works {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  overflow-y: auto;
  overflow-x: hidden; }
  .how-works--wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    overflow: hidden;
    z-index: 8; }
  .how-works .close {
    position: absolute;
    top: 20px;
    right: 26px;
    display: block;
    width: 28px;
    height: 28px;
    background-image: url(/static/media/cancel.138bf265.svg);
    background-size: cover;
    cursor: pointer;
    z-index: 0; }
  .how-works--top {
    min-height: 500px;
    padding-top: 105px;
    text-align: center;
    background: #ffffff url(/static/media/bkgrnd.3c5744bd.svg) 0 0 no-repeat;
    background-size: cover; }
  .how-works__title {
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #313d4f;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .how-works__button {
    max-width: 350px;
    width: 100%;
    max-height: 60px;
    margin-top: 10px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    background: #0baff0;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .how-works--gallery {
    display: flex;
    justify-content: space-around; }
    .how-works--gallery__img {
      width: 160px;
      height: 130px; }
    .how-works--gallery__lockmart {
      background: url(/static/media/lockmart.9f19bc5f.svg) center center no-repeat; }
    .how-works--gallery__shorts {
      background: url(/static/media/shorts.02709592.svg) center center no-repeat; }
    .how-works--gallery__spirits {
      background: url(/static/media/spirits.217c2229.svg) center center no-repeat; }
    .how-works--gallery__box {
      background: url(/static/media/box.4f25e80e.svg) center center no-repeat; }
    .how-works--gallery__glasses {
      background: url(/static/media/glasses.f1fc41b3.svg) center center no-repeat; }
    .how-works--gallery__paper {
      background: url(/static/media/paper.c4c115d8.svg) center center no-repeat; }
    .how-works--gallery__picture {
      background: url(/static/media/picture.b22f5f8c.svg) center center no-repeat; }
    .how-works--gallery__bag {
      background: url(/static/media/bag.1dd701bc.svg) center center no-repeat; }
    .how-works--gallery__notebook {
      background: url(/static/media/notebook.e0ff9341.svg) center center no-repeat; }
  .how-works--info-box {
    display: flex;
    justify-content: space-around;
    margin-top: 50px; }
    .how-works--info-box__col {
      width: 25%;
      padding: 10px 20px 40px;
      text-align: center; }
    .how-works--info-box__top {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 125px; }
    .how-works--info-box__icon {
      margin-bottom: 20px; }
    .how-works--info-box__name {
      font-size: 30px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #da1781;
      font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    .how-works--info-box__text {
      margin-top: 10px;
      font-size: 26px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .how-works .line-rectangle {
    position: fixed;
    width: 100%;
    height: 6px;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(262deg, #ffffff, #fff1a3 0, #ffd700 29%, #ff41f2 80%, #df33ff 100%); }
    .how-works .line-rectangle__top {
      top: 0; }
    .how-works .line-rectangle__bottom {
      bottom: 0; }

@media (max-width: 1280px) {
  .how-works--gallery__bag, .how-works--gallery__notebook {
    display: none; } }

@media (max-width: 1024px) {
  .how-works--gallery__paper, .how-works--gallery__picture {
    display: none; } }

@media (max-width: 768px) {
  .how-works--gallery__img {
    width: 120px;
    height: 90px; }
  .how-works--gallery__glasses {
    display: none; }
  .how-works--gallery__shorts {
    background: url(/static/media/shortsMob.1c994bef.svg) center center no-repeat; }
  .how-works--gallery__lockmart {
    background: url(/static/media/lockmartMob.ca831830.svg) center center no-repeat; }
  .how-works--gallery__glasses {
    background: url(/static/media/glassesMob.bfdb7fb8.svg) center center no-repeat; }
  .how-works--gallery__spirits {
    background: url(/static/media/spiritsMob.aa85c537.svg) center center no-repeat; }
  .how-works--info-box {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; }
    .how-works--info-box__top {
      position: absolute;
      left: 20px; }
    .how-works--info-box__icon {
      width: 80%;
      margin-top: 0;
      margin-bottom: auto; }
    .how-works--info-box__col {
      width: 100%;
      padding-left: 130px;
      text-align: left; }
    .how-works--info-box__name, .how-works--info-box__text {
      text-align: left; } }

@media (max-width: 480px) {
  .how-works__title {
    max-width: 80%;
    margin: 0 auto 15px;
    font-size: 18px; }
  .how-works .close {
    top: 18px;
    right: 20px;
    width: 18px;
    height: 18px; }
  .how-works--top {
    min-height: 230px;
    padding-top: 60px;
    background: #ffffff url(/static/media/bkgrndMob.f2e64bfc.svg) 0 0 no-repeat;
    background-size: cover; }
  .how-works__button {
    max-width: 80%;
    max-height: 44px;
    padding: 10px 0;
    font-size: 18px; }
  .how-works--gallery {
    margin-top: 30px; }
    .how-works--gallery__img {
      width: 80px;
      height: 60px; }
  .how-works--info-box__top {
    left: 10px; }
  .how-works--info-box__icon {
    width: 50%; }
  .how-works--info-box__col {
    width: 100%;
    padding-left: 100px; }
  .how-works--info-box__name {
    font-size: 18px; }
  .how-works--info-box__text {
    font-size: 14px; } }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  margin: 0 auto; }

.input {
  display: block;
  font-size: 14px;
  color: #000000;
  font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
  border: solid 1px #c7c7c7;
  box-sizing: border-box;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: normal; }
  .input-large {
    width: 568px;
    height: 44px; }
  .input-big {
    width: 440px;
    height: 44px; }
  .input-middle {
    width: 278px;
    height: 44px; }
  .input-small {
    width: 216px;
    height: 44px; }
  .input-label {
    display: block;
    font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.17;
    text-align: left;
    color: #9b9b9b;
    text-transform: uppercase; }
    .input-label__text {
      display: block;
      padding-top: 25px;
      padding-bottom: 14px;
      font-family: 'proxima_nova_regular', Helvetica, sans-serif; }
    .input-label_cards {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding-bottom: 14px; }
  .input:focus {
    outline: none; }
  .input.error {
    border: solid 1px #da1781; }
  .input::-webkit-input-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }
  .input::-moz-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }
  .input:-ms-input-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }

input:disabled {
  background: #e7e7e7; }

.form {
  position: relative; }
  .form__row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .form__row__btns {
      display: flex;
      justify-content: flex-end; }
    .form__row__part {
      display: inline-block; }
  .form__title {
    display: block;
    font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #4a4a4a; }
    .form__title--bold {
      font-family: 'proxima_nova_bold', Helvetica, sans-serif; }
  .form__text {
    display: block;
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #9b9b9b;
    line-height: 20px; }
    .form__text__link {
      text-decoration: none;
      cursor: pointer; }
    .form__text-bold {
      font-family: 'proxima_nova_regular', Helvetica, sans-serif;
      font-weight: bold; }

.cerise {
  color: #da1781; }

.azure {
  color: #0baff0; }

.green {
  color: #87b576; }

.violet {
  color: #bd0fe1; }
  .violet-bg {
    background: #bd0fe1; }

.squash {
  color: #f6a623; }
  .squash-bg {
    background: #f6a623; }

.hide {
  display: none; }
  @media (max-width: 480px) {
    .hide-for-mobile {
      display: none; } }

.text-align__left {
  text-align: left; }

.text-align__right {
  text-align: right; }

.text-align__center {
  text-align: right; }

.fw-bold {
  font-weight: bold; }

.fw-normal {
  font-weight: normal; }

.fw-lighter {
  font-weight: lighter; }

.z-index-100 {
  z-index: 100; }

.divider {
  display: flex;
  background: #e2e2e2; }
  .divider-row {
    flex-direction: row;
    width: 100%;
    height: 2px; }
  .divider-col {
    flex-direction: column;
    width: 2px;
    height: 100%; }

.space-between {
  display: flex;
  justify-content: space-between; }
  .space-between-col {
    flex-direction: column;
    height: 100%; }
  .space-between-row {
    flex-direction: row;
    width: 100%; }

.width-100 {
  width: 100%; }

.wrapper-tooltip {
  font-family: 'proxima_nova_semibold', Helvetica, sans-serif; }

@-webkit-keyframes right-panel-appear {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

@keyframes right-panel-appear {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

.common-button {
  font-family: proxima_nova_light, Helvetica, sans-serif;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 12px;
  color: #ffffff;
  letter-spacing: .5px; }
  .common-button.transparent {
    background-color: transparent;
    color: #c6c6c6;
    border: 1px solid #c6c6c6; }
  .common-button.dark-pink {
    background-color: #da1781; }
  .common-button.gray {
    background-color: #c6c6c6;
    color: #000000; }
  .common-button[disabled] {
    pointer-events: none;
    background-color: #e2e2e2; }
  .common-button:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3); }

.btn {
  position: relative;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  background: transparent; }
  .btn-orange {
    width: 100%;
    height: 42px;
    min-height: 42px;
    border-radius: 1px;
    background: #ffaf06;
    font-family: proxima_nova_bold, Helvetica, sans-serif;
    font-size: 17px;
    color: #ffffff; }
  .btn-arrow:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 14px;
    width: 8px;
    height: 8px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: translateY(-50%) rotate(45deg); }
  .btn-continue {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 14px;
    font-size: 18px;
    color: #f5a623;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    font-family: proxima_nova_bold, Helvetica, sans-serif; }
    .btn-continue:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 10px;
      height: 10px;
      margin-top: -2px;
      border-top: 3px solid #f5a623;
      border-right: 3px solid #f5a623;
      transform: translateY(-50%) rotate(45deg);
      box-sizing: border-box; }
  .btn-edit-number {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 13px;
    text-align: left;
    color: #4990e2;
    outline: none;
    border: none;
    background: transparent; }
  .btn-arrow-back {
    width: 17px;
    height: 17px;
    margin-right: auto;
    z-index: 1;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAIAAAC0D9CtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAj0lEQVQokZXSsRKDIAwG4B/OjTmd42pfwr7/XMeSnZk5DvbUeqGSjBzfEf4kqCqcFc1TySJZWmawgUgIgR6UUrp/ZwfTczLB1ZwBEbV6i15wmH7wNS4AIORPFpHbe3vNr9mez/8aeGQArt4iAB6ZmVV1eS+llC7jZcd/+tlPBp3smtuZ1VpNY+z1liSA1o6uQHJjRpynKHIAAAAASUVORK5CYII=) 0 0 no-repeat; }
  .btn-fixed-bottom {
    position: absolute;
    top: 12px;
    max-width: 278px;
    width: 100%;
    z-index: 4; }

.tutorial-modal {
  position: relative;
  max-width: 540px;
  width: 100%;
  margin: 40px auto;
  padding-bottom: 100px;
  background: #ffffff; }
  @media (max-width: 667px) {
    .tutorial-modal {
      max-width: 100%;
      height: 100%;
      margin: 0; } }
  .tutorial-modal__wrapper {
    z-index: 8; }
  .tutorial-modal__main {
    background: #ffffff; }
    @media (max-width: 667px) {
      .tutorial-modal__main {
        padding-top: 30px;
        padding-bottom: 70px; } }
  .tutorial-modal__top-line {
    height: 5px;
    width: 100%;
    background-image: linear-gradient(262deg, #ffffff, #fff1a3 0, #ffd700 29%, #ff41f2 80%, #df33ff 100%); }
  .tutorial-modal__close {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 14px;
    height: 14px;
    object-fit: cover;
    object-position: center;
    cursor: pointer; }
    @media (max-width: 667px) {
      .tutorial-modal__close {
        top: 16px;
        z-index: 2; } }
  .tutorial-modal__header {
    padding: 26px 40px 10px; }
    @media (max-width: 667px) {
      .tutorial-modal__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding: 20px 14px 26px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.44);
        z-index: 1; } }
    .tutorial-modal__header__logo {
      display: inline-block;
      max-width: 80px;
      width: 100%;
      max-height: 30px;
      height: 100%;
      margin-bottom: 12px; }
      @media (max-width: 667px) {
        .tutorial-modal__header__logo {
          max-height: 24px;
          margin-bottom: 0; } }
    .tutorial-modal__header__title {
      display: none; }
      @media (max-width: 667px) {
        .tutorial-modal__header__title {
          display: inline-block;
          margin-left: 10px;
          line-height: normal;
          letter-spacing: normal;
          color: #da1781;
          font-family: proxima_nova_light, Helvetica, sans-serif;
          font-size: 15px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal; } }
    .tutorial-modal__header__line {
      width: 100%;
      height: 2px;
      background: #e2e2e2; }
      @media (max-width: 667px) {
        .tutorial-modal__header__line {
          display: none; } }
  .tutorial-modal__title {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 29px;
    letter-spacing: normal;
    text-align: center;
    color: #da1781;
    font-family: proxima_nova_bold, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal; }
    @media (max-width: 667px) {
      .tutorial-modal__title {
        width: 90%;
        margin: 10px auto 0;
        font-size: 20px;
        line-height: 24px; } }
  .tutorial-modal__sub-title {
    width: 75%;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    line-height: 29px;
    letter-spacing: normal;
    text-align: center;
    color: #313d4f;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal; }
    @media (max-width: 667px) {
      .tutorial-modal__sub-title {
        width: 80%;
        font-size: 18px;
        line-height: 24px; } }
    @media (max-width: 374px) {
      .tutorial-modal__sub-title {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        padding: 0; } }
    .tutorial-modal__sub-title:first-of-type {
      margin-top: 14px; }
  .tutorial-modal .text-bold {
    font-family: proxima_nova_bold, Helvetica, sans-serif; }
  .tutorial-modal__block-img {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center; }
    .tutorial-modal__block-img__img {
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat; }
  .tutorial-modal .step-welcome .tutorial-modal__sub-title {
    width: 90%; }
    @media (max-width: 667px) {
      .tutorial-modal .step-welcome .tutorial-modal__sub-title {
        width: 95%; } }
  .tutorial-modal .step-welcome .tutorial-modal__block-img {
    height: 342px;
    margin-top: -10px;
    padding: 0; }
    @media (max-width: 667px) {
      .tutorial-modal .step-welcome .tutorial-modal__block-img {
        height: auto; } }
    @media (max-width: 375px) {
      .tutorial-modal .step-welcome .tutorial-modal__block-img {
        height: 306px; } }
    @media (max-width: 360px) {
      .tutorial-modal .step-welcome .tutorial-modal__block-img {
        height: 260px; } }
    .tutorial-modal .step-welcome .tutorial-modal__block-img__img {
      height: 342px;
      background-position: 0 10px; }
      @media (max-width: 667px) {
        .tutorial-modal .step-welcome .tutorial-modal__block-img__img {
          height: 544px; } }
      @media (max-width: 568px) {
        .tutorial-modal .step-welcome .tutorial-modal__block-img__img {
          height: 462px; } }
      @media (max-width: 480px) {
        .tutorial-modal .step-welcome .tutorial-modal__block-img__img {
          height: 392px; } }
      @media (max-width: 375px) {
        .tutorial-modal .step-welcome .tutorial-modal__block-img__img {
          height: 306px; } }
      @media (max-width: 360px) {
        .tutorial-modal .step-welcome .tutorial-modal__block-img__img {
          height: 260px; } }
  .tutorial-modal .step-favorite .tutorial-modal__block-img {
    height: 400px;
    padding: 0; }
    @media (max-width: 667px) {
      .tutorial-modal .step-favorite .tutorial-modal__block-img {
        height: auto;
        margin-top: 36px; } }
    @media (max-width: 480px) {
      .tutorial-modal .step-favorite .tutorial-modal__block-img {
        margin-top: 20px; } }
    @media (max-width: 375px) {
      .tutorial-modal .step-favorite .tutorial-modal__block-img {
        height: 300px; } }
    @media (max-width: 360px) {
      .tutorial-modal .step-favorite .tutorial-modal__block-img {
        height: 260px; } }
    .tutorial-modal .step-favorite .tutorial-modal__block-img__img {
      height: 346px;
      background-position: -574px 0; }
      @media (max-width: 667px) {
        .tutorial-modal .step-favorite .tutorial-modal__block-img__img {
          height: 540px;
          background-position: -696px 0; } }
      @media (max-width: 568px) {
        .tutorial-modal .step-favorite .tutorial-modal__block-img__img {
          height: 452px;
          background-position: -580px 0; } }
      @media (max-width: 480px) {
        .tutorial-modal .step-favorite .tutorial-modal__block-img__img {
          height: 385px;
          background-position: -495px 0; } }
      @media (max-width: 375px) {
        .tutorial-modal .step-favorite .tutorial-modal__block-img__img {
          height: 302px;
          background-position: -385px 0; } }
      @media (max-width: 360px) {
        .tutorial-modal .step-favorite .tutorial-modal__block-img__img {
          height: 260px;
          background-position: -338px 0; } }
  .tutorial-modal .step-favorite .tutorial-modal__sub-title {
    width: 85%; }
    @media (max-width: 667px) {
      .tutorial-modal .step-favorite .tutorial-modal__sub-title {
        width: 80%; } }
  .tutorial-modal .step-confirm .tutorial-modal__block-img {
    height: 371px;
    padding: 0; }
    @media (max-width: 667px) {
      .tutorial-modal .step-confirm .tutorial-modal__block-img {
        height: auto;
        margin-top: 36px; } }
    @media (max-width: 480px) {
      .tutorial-modal .step-confirm .tutorial-modal__block-img {
        margin-top: 20px; } }
    .tutorial-modal .step-confirm .tutorial-modal__block-img__img {
      height: 346px;
      background-position: -1136px 0; }
      @media (max-width: 667px) {
        .tutorial-modal .step-confirm .tutorial-modal__block-img__img {
          height: 540px;
          background-position: -1396px 0; } }
      @media (max-width: 568px) {
        .tutorial-modal .step-confirm .tutorial-modal__block-img__img {
          height: 460px;
          background-position: -1190px 0; } }
      @media (max-width: 480px) {
        .tutorial-modal .step-confirm .tutorial-modal__block-img__img {
          height: 388px;
          background-position: -1003px 0; } }
      @media (max-width: 375px) {
        .tutorial-modal .step-confirm .tutorial-modal__block-img__img {
          height: 302px;
          background-position: -780px 0; } }
      @media (max-width: 360px) {
        .tutorial-modal .step-confirm .tutorial-modal__block-img__img {
          height: 260px;
          background-position: -674px 0; } }
  .tutorial-modal .step-confirm .tutorial-modal__sub-title {
    width: 70%;
    margin: 14px auto 0; }
    @media (max-width: 667px) {
      .tutorial-modal .step-confirm .tutorial-modal__sub-title {
        width: 80%; } }
  .tutorial-modal__block-btn {
    text-align: center; }
    @media (max-width: 667px) {
      .tutorial-modal__block-btn {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 10px 14px;
        background: #ffffff; } }
  .tutorial-modal .common-button {
    max-width: 260px;
    width: 100%;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 17px; }
    @media (max-width: 667px) {
      .tutorial-modal .common-button {
        max-width: 100%; } }

.flash-sale {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  width: 100%;
  background: #da1781; }
  .flash-sale-wrapper {
    position: relative;
    width: 100%;
    margin-top: -40px;
    z-index: 7;
    height: 100%;
    min-height: 40px;
    transition: .4s; }
    .flash-sale-wrapper.display {
      margin-top: 0;
      transition: .4s; }
  .flash-sale__text {
    position: relative;
    top: 2px;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .flash-sale__link {
    margin-left: 20px;
    text-decoration: underline;
    color: #ffffff;
    cursor: pointer; }

@media (max-width: 768px) {
  .flash-sale {
    padding-top: 8px;
    padding-bottom: 8px; }
    .flash-sale-wrapper {
      min-height: 26px; }
    .flash-sale__text {
      top: 4px;
      line-height: 10px;
      font-size: 13px; }
    .flash-sale__link {
      margin-left: 12px; } }

@media (max-width: 375px) {
  .flash-sale {
    padding-top: 8px;
    padding-bottom: 8px; }
    .flash-sale__text {
      top: 2px;
      font-size: 11px; }
    .flash-sale__link {
      margin-left: 10px; } }

@media (max-width: 350px) {
  .flash-sale__text {
    top: 2px;
    font-size: 9px; } }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  margin: 0 auto; }

.input {
  display: block;
  font-size: 14px;
  color: #000000;
  font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
  border: solid 1px #c7c7c7;
  box-sizing: border-box;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: normal; }
  .input-large {
    width: 568px;
    height: 44px; }
  .input-big {
    width: 440px;
    height: 44px; }
  .input-middle {
    width: 278px;
    height: 44px; }
  .input-small {
    width: 216px;
    height: 44px; }
  .input-label {
    display: block;
    font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.17;
    text-align: left;
    color: #9b9b9b;
    text-transform: uppercase; }
    .input-label__text {
      display: block;
      padding-top: 25px;
      padding-bottom: 14px;
      font-family: 'proxima_nova_regular', Helvetica, sans-serif; }
    .input-label_cards {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding-bottom: 14px; }
  .input:focus {
    outline: none; }
  .input.error {
    border: solid 1px #da1781; }
  .input::-webkit-input-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }
  .input::-moz-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }
  .input:-ms-input-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }

input:disabled {
  background: #e7e7e7; }

.form {
  position: relative; }
  .form__row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .form__row__btns {
      display: flex;
      justify-content: flex-end; }
    .form__row__part {
      display: inline-block; }
  .form__title {
    display: block;
    font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #4a4a4a; }
    .form__title--bold {
      font-family: 'proxima_nova_bold', Helvetica, sans-serif; }
  .form__text {
    display: block;
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #9b9b9b;
    line-height: 20px; }
    .form__text__link {
      text-decoration: none;
      cursor: pointer; }
    .form__text-bold {
      font-family: 'proxima_nova_regular', Helvetica, sans-serif;
      font-weight: bold; }

.cerise {
  color: #da1781; }

.azure {
  color: #0baff0; }

.green {
  color: #87b576; }

.violet {
  color: #bd0fe1; }
  .violet-bg {
    background: #bd0fe1; }

.squash {
  color: #f6a623; }
  .squash-bg {
    background: #f6a623; }

.hide {
  display: none; }
  @media (max-width: 480px) {
    .hide-for-mobile {
      display: none; } }

.text-align__left {
  text-align: left; }

.text-align__right {
  text-align: right; }

.text-align__center {
  text-align: right; }

.fw-bold {
  font-weight: bold; }

.fw-normal {
  font-weight: normal; }

.fw-lighter {
  font-weight: lighter; }

.z-index-100 {
  z-index: 100; }

.divider {
  display: flex;
  background: #e2e2e2; }
  .divider-row {
    flex-direction: row;
    width: 100%;
    height: 2px; }
  .divider-col {
    flex-direction: column;
    width: 2px;
    height: 100%; }

.space-between {
  display: flex;
  justify-content: space-between; }
  .space-between-col {
    flex-direction: column;
    height: 100%; }
  .space-between-row {
    flex-direction: row;
    width: 100%; }

.width-100 {
  width: 100%; }

.wrapper-tooltip {
  font-family: 'proxima_nova_semibold', Helvetica, sans-serif; }

@-webkit-keyframes right-panel-appear {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

@keyframes right-panel-appear {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

.mp-header {
  width: 100%;
  position: fixed;
  background: #ffffff;
  border-bottom: 1px solid #0000002b;
  z-index: 3; }
  .mp-header-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 87px; }
  .mp-header-rectangle {
    position: fixed;
    height: 6px;
    width: 100%;
    background-color: #da1781; }
  .mp-header__logo {
    cursor: pointer; }
  .mp-header-placeholder {
    max-width: 580px;
    width: 100%;
    height: 44px; }
  .mp-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 24px 32px 18px; }
  .mp-header-actions {
    display: flex;
    align-items: center; }
    .mp-header-actions .common-button {
      padding: 6px 8px;
      white-space: nowrap; }
    .mp-header-actions-user-menu {
      position: relative; }
      .mp-header-actions-user-menu__switcher {
        width: 36px;
        height: 36px;
        padding-right: 20px;
        box-sizing: content-box;
        cursor: pointer; }
        .mp-header-actions-user-menu__switcher:after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: rotate(45deg) translate(-50%, -50%);
          content: '';
          width: 8px;
          height: 8px;
          border-bottom: 2px solid #c7c7c7;
          border-right: 2px solid #c7c7c7;
          cursor: pointer; }
        .mp-header-actions-user-menu__switcher .user-logo {
          width: 100%;
          border: 1px solid #dfe2e5;
          border-radius: 50%; }
  .mp-header-actions__aside {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0 4px; }
  .mp-header__aside-trigger {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start; }
  .mp-header-badge {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 3px 7px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 12px;
    background-color: #da1781;
    vertical-align: middle;
    transform: translateX(-8px);
    transition: .3s; }
    .mp-header-badge.is-hidden {
      opacity: 0; }
  .mp-header-badge__value {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 15px;
    line-height: normal;
    color: #ffffff;
    transform: translateY(1px); }
  .mp-header-coins {
    position: relative; }
  .mp-header-coins__icon {
    width: auto;
    height: 30px;
    cursor: pointer; }
  .mp-header-cart {
    margin: 0 10px; }
  .mp-header-cart__icon {
    width: auto;
    height: 30px;
    cursor: pointer; }
  .mp-header-favorites__icon {
    width: auto;
    height: 28px;
    cursor: pointer; }
  .mp-header__gift-me {
    margin-left: 20px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 20px;
    height: 50px; }
  .mp-header .common-button.transparent {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 20px;
    color: #4a4a4a;
    background-color: transparent;
    border: none;
    box-shadow: none; }
  .mp-header .common-button.transparent-pink {
    width: 100px;
    height: 47px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 18px;
    color: #da1781;
    background-color: transparent;
    border: 1px solid #da1781; }

.user-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 245px;
  padding: 20px 8px 25px;
  border: 1px solid #dfe3e9;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
  box-sizing: border-box;
  background-color: #ffffff;
  z-index: 5; }
  .user-menu .common-button {
    width: 100%; }
    .user-menu .common-button.dark-pink {
      margin-top: 17px; }
  .user-menu-wrapper {
    position: absolute;
    right: -20px;
    top: 40px;
    width: 245px;
    height: 0;
    overflow: hidden;
    transition: .2s;
    z-index: 6; }
    .user-menu-wrapper.is-active {
      height: 160px;
      transition: .4s; }
  .user-menu-top {
    display: none;
    margin-bottom: 30px; }
    .user-menu-top__title {
      margin-left: 25px;
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 17px;
      color: #000000; }
  .user-menu-sign-up {
    padding: 10px;
    background-image: radial-gradient(circle at 50% 25%, #fcfae2, #fffab2);
    border: solid 1px #dfdcc0;
    text-align: center; }
    .user-menu-sign-up__icon {
      display: block;
      width: 28px;
      height: 35px;
      margin: auto; }
    .user-menu-sign-up__title {
      margin-top: 7px;
      font-family: proxima_nova_bold, Helvetica, sans-serif;
      font-size: 14px;
      color: black; }
    .user-menu-sign-up__text {
      margin-top: 10px;
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 12px;
      color: #000000; }
    .user-menu-sign-up .bold {
      font-family: proxima_nova_bold, Helvetica, sans-serif; }
    .user-menu-sign-up__button {
      width: 100%;
      margin-top: 17px;
      padding: 12px 0;
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 14px;
      color: #ffffff;
      background-color: #da1781;
      border: none;
      border-radius: 2px;
      cursor: pointer; }
  .user-menu-devidor {
    position: relative;
    display: flex; }
    .user-menu-devidor__text {
      padding: 10px;
      margin-left: 50%;
      transform: translateX(-50%);
      font-family: proxima_nova_semibold, Helvetica, sans-serif;
      font-size: 14px;
      background-color: #ffffff;
      color: #4a4a4a;
      text-transform: uppercase;
      z-index: 1; }
    .user-menu-devidor__line {
      position: absolute;
      top: 50%;
      width: 50%;
      height: 2px;
      background-color: #c6c6c6;
      transform: translateY(-50%); }
      .user-menu-devidor__line_left {
        left: 0; }
      .user-menu-devidor__line_right {
        right: 0; }
  .user-menu-login {
    margin-top: 8px;
    padding: 0 7px; }
    .user-menu-login__button {
      width: 100%;
      padding: 12px 0;
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 14px;
      color: #4a4a4a;
      background-color: #c6c6c6;
      border-radius: 2px;
      border: none;
      cursor: pointer; }
  .user-menu__link {
    text-decoration: none;
    position: relative;
    display: block;
    width: 100%;
    padding: 9px 14px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #354052;
    box-sizing: border-box;
    box-shadow: inset 0 -1px 0 0 #dfe3e9;
    cursor: pointer; }
    .user-menu__link:hover, .user-menu__link:active {
      background: #f1f4f8;
      color: #0baff0; }
  .user-menu__item:last-child .user-menu__link {
    box-shadow: none; }

.is-login .user-menu {
  padding: 0; }

@media (max-width: 768px) {
  .mp-header {
    box-shadow: none; }
    .mp-header-rectangle {
      height: 5px; }
    .mp-header-wrapper {
      min-height: 125px; }
    .mp-header__logo {
      width: 96px;
      height: 54px; }
    .mp-header-content {
      align-items: flex-start;
      padding: 14px 32px 14px;
      background: #fafafa; }
    .mp-header-actions {
      height: 54px; }
      .mp-header-actions-user-menu__switcher {
        width: 32px;
        height: 32px; }
      .mp-header-actions-user-menu:after {
        width: 6px;
        height: 6px; }
      .mp-header-actions .common-button {
        padding: 6px 8px;
        white-space: nowrap; }
      .mp-header-actions .common-button.transparent-pink {
        height: 30px;
        padding: 4px 8px;
        font-size: 16px; }
    .mp-header-badge {
      padding: 0 7px 1px;
      min-height: 18px;
      min-width: 18px; }
    .mp-header-badge__value {
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 12px; }
    .mp-header-coins__icon {
      height: 23px; }
    .mp-header-cart {
      margin: 0 5px; }
    .mp-header-cart__icon {
      height: 23px; }
    .mp-header-favorites__icon {
      height: 22px; }
    .mp-header-wrapper--landing {
      min-height: 80px; }
    .mp-header-wrapper--landing .mp-header__logo {
      width: 150px;
      height: auto; }
    .mp-header-wrapper--landing .mp-header-content {
      align-items: center;
      padding: 26px 24px 12px; }
    .mp-header-wrapper--landing .common-button,
    .mp-header-wrapper--landing .common-button.transparent-pink {
      height: 40px;
      font-size: 17px; }
    .mp-header__gift-me {
      margin-left: 10px; } }

@media (max-width: 480px) {
  .mp-header {
    z-index: 6; }
    .mp-header-wrapper {
      min-height: 102px; }
    .mp-header-placeholder {
      display: none; }
    .mp-header__logo {
      width: 66px;
      height: 24px; }
    .mp-header-actions {
      height: auto;
      position: relative;
      z-index: 5; }
      .mp-header-actions__favorites {
        height: 22px; }
        .mp-header-actions__favorites .count {
          width: 18px;
          height: 18px;
          left: 50%;
          font-size: 12px;
          line-height: 18px;
          opacity: 0;
          transition: .3s; }
          .mp-header-actions__favorites .count.is-active {
            opacity: 1; }
      .mp-header-actions-user-menu__switcher {
        padding-right: 0; }
        .mp-header-actions-user-menu__switcher:after {
          display: none; }
    .mp-header-content {
      padding: 20px 16px 48px; }
    .mp-header-content a {
      align-self: center; }
    .mp-header .market-place-search-form {
      margin-top: 50px; }
    .mp-header .common-button.transparent-pink {
      width: 60px;
      height: 28px;
      font-size: 14px; }
    .mp-header-wrapper--landing {
      min-height: 80px; }
    .mp-header-wrapper--landing .mp-header__logo {
      width: 110px; }
    .mp-header-wrapper--landing .mp-header-content {
      padding: 26px 14px 12px; }
    .mp-header-wrapper--landing .common-button,
    .mp-header-wrapper--landing .common-button.transparent-pink {
      font-size: 14px; }
    .mp-header-wrapper--landing .common-button.transparent-pink {
      width: 80px; }
  .user-menu {
    width: 100%;
    height: 100%;
    padding-left: 13px;
    padding-right: 13px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
    background-color: #fafafa; }
    .user-menu-wrapper {
      display: none; }
    .user-menu-top {
      display: flex; }
    .user-menu-devidor__text {
      background-color: #fafafa; }
  .is-login .user-menu {
    border: 1px solid #dfe3e9;
    border-radius: 4px;
    background-color: #ffffff; } }

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  z-index: 6;
  top: 0;
  left: 0; }
  .loader__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.product-card {
  position: relative;
  width: 170px;
  border: solid 1px #c6c6c6;
  box-sizing: border-box;
  background: #ffffff;
  cursor: pointer; }
  .product-card .product-main-info {
    padding: 8px 10px; }
  .product-card-link {
    text-decoration: none; }
  .product-card__img {
    position: relative;
    margin: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    cursor: pointer;
    display: block; }
  .product-card__favorites {
    position: absolute;
    right: 10px;
    width: 15px;
    height: 13px;
    bottom: 5px;
    cursor: pointer;
    z-index: 1; }
    .product-card__favorites__img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      min-width: 100%;
      min-height: 100%; }
  .product-card__body {
    position: relative; }
  .product-card-coins {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 3px;
    padding: 2px 4px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1;
    text-transform: capitalize;
    background-color: #ffffff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5); }
  .product-card-coins__text {
    line-height: normal;
    color: #da1781; }
  .product-card-coins__count {
    line-height: normal;
    color: #4a4a4a; }
  .product-card-coins__icon {
    margin: 0 3px;
    max-width: 14px;
    height: 13px; }

.product-main-info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  font-size: 12px; }
  .product-main-info__left {
    max-width: 60%;
    width: 100%;
    text-align: left; }
  .product-main-info__right {
    min-width: 25%;
    text-align: right; }
  .product-main-info__title {
    max-width: 100%;
    padding-right: 5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: normal;
    color: #4a4a4a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .product-main-info__brand {
    max-width: 100%;
    padding-right: 5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: normal;
    color: #9b9b9b;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .product-main-info__price-current {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    color: #da1781;
    line-height: 14px; }
  .product-main-info__price-icon {
    max-width: 11px;
    max-height: 10px;
    vertical-align: middle;
    transform: translateY(-1px); }
  .product-main-info__price-old {
    margin-top: 2px;
    text-decoration: line-through;
    color: #4a4a4a;
    line-height: 14px;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }

.banner {
  width: 100%;
  padding: 60px 90px;
  border-bottom: 1px solid #0000002b; }
  @media (max-width: 667px) {
    .banner {
      padding: 24px; } }
  .banner__body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-beetwen;
    align-items: flex-start;
    width: 100%; }
    @media (max-width: 1200px) {
      .banner__body {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center; } }
  .banner__info, .banner__cards {
    width: 50%; }
    @media (max-width: 1200px) {
      .banner__info, .banner__cards {
        width: 100%; } }
  .banner__title {
    margin-bottom: 34px;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #da1781;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    @media (max-width: 667px) {
      .banner__title {
        font-size: 22px;
        margin-bottom: 15px; } }
  .banner__sub-title {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: #9b9b9b;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
    @media (max-width: 667px) {
      .banner__sub-title {
        font-size: 14px; } }
  .banner__list {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start; }
    .banner__list__item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: normal;
      color: #9b9b9b;
      font-family: proxima_nova_regular, Helvetica, sans-serif; }
      @media (max-width: 667px) {
        .banner__list__item {
          font-size: 14px;
          padding-top: 4px;
          padding-bottom: 4px;
          align-items: flex-start; } }
    .banner__list__number {
      display: inline-block;
      width: 27px;
      min-width: 27px;
      height: 27px;
      margin-right: 18px;
      line-height: 27px;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9b9b9b;
      font-family: proxima_nova_semibold, Helvetica, sans-serif;
      border: solid 1px #9b9b9b;
      background-color: #f2f2f2;
      box-sizing: border-box;
      border-radius: 50%; }
      @media (max-width: 667px) {
        .banner__list__number {
          font-size: 12px;
          width: 20px;
          min-width: 20px;
          height: 20px;
          margin-right: 12px;
          margin-top: 1.4px;
          line-height: 20px; } }
  .banner .banner-button {
    margin-top: 8px;
    padding: 15px 80px;
    font-size: 18px; }
    @media (max-width: 1200px) {
      .banner .banner-button {
        margin-top: 30px; } }
    @media (max-width: 667px) {
      .banner .banner-button {
        margin-top: 20px;
        padding: 10px 60px;
        font-size: 14px; } }
  .banner__cards {
    padding-top: 34px;
    padding-left: 75px; }
    @media (max-width: 1200px) {
      .banner__cards {
        padding-left: 0; } }
    @media (max-width: 768px) {
      .banner__cards {
        display: none; } }
    .banner__cards__wrapper {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 200px; }
      @media (max-width: 1200px) {
        .banner__cards__wrapper {
          justify-content: center; } }
  .banner .relative .product-card {
    width: 198px;
    height: 208px; }
    .banner .relative .product-card__body {
      max-height: 160px;
      height: 100%;
      width: 100%; }
    .banner .relative .product-card__title, .banner .relative .product-card__brand {
      font-size: 12px; }
    .banner .relative .product-card__price-current {
      font-size: 13px; }
    .banner .relative .product-card__price-old {
      font-size: 9px; }
    .banner .relative .product-card__favorites {
      width: 21px;
      height: 17px; }
    .banner .relative .product-card__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .banner .relative:first-of-type .product-card {
    width: 187px;
    right: -26px;
    z-index: 0; }
    .banner .relative:first-of-type .product-card__img {
      margin-left: 0; }
  .banner .relative:first-of-type .content-placeholder {
    height: 25%;
    padding-top: 15px; }
  .banner .relative:last-of-type .product-card {
    width: 187px;
    left: -26px;
    z-index: 0; }
    .banner .relative:last-of-type .product-card__img {
      margin-right: 0; }
  .banner .relative:last-of-type .content-placeholder {
    height: 25%;
    padding-top: 15px; }
  .banner .relative:nth-of-type(2) .product-card {
    width: 196px;
    height: 252px;
    z-index: 1; }
    .banner .relative:nth-of-type(2) .product-card__body {
      max-height: 205px; }
  .banner .relative:nth-of-type(2) .content-placeholder {
    height: 20%;
    padding-top: 15px; }
  .banner__footer {
    width: 100%; }

@-webkit-keyframes animateOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes collage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes collage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes favorites-appear {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes favorites-appear {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@keyframes favorites-disappear {
  from {
    transform: translateX(0);
    opacity: 1;
    min-width: 220px;
    width: 220px;
    margin-left: -10px; }
  to {
    transform: translateX(100%);
    opacity: 0;
    min-width: 0;
    width: 0;
    margin-left: 0; } }

@-webkit-keyframes favorites-disappear {
  from {
    transform: translateX(0);
    opacity: 1;
    min-width: 220px;
    width: 220px;
    margin-left: -10px; }
  to {
    transform: translateX(100%);
    opacity: 0;
    min-width: 0;
    width: 0;
    margin-left: 0; } }

@keyframes mobile-favorites-appear {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes mobile-favorites-appear {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@keyframes mobile-favorites-disappear {
  from {
    transform: translateX(0);
    opacity: 1;
    min-width: 100%;
    width: 100%; }
  to {
    transform: translateX(100%);
    opacity: 0;
    min-width: 0;
    width: 0; } }

@-webkit-keyframes mobile-favorites-disappear {
  from {
    transform: translateX(0);
    opacity: 1;
    min-width: 100%;
    width: 100%; }
  to {
    transform: translateX(100%);
    opacity: 0;
    min-width: 0;
    width: 0; } }

@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

@keyframes hintloading {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

.filter-bar {
  display: flex;
  overflow: hidden; }
  .filter-bar-wrapper {
    width: 100%;
    position: relative; }
    .filter-bar-wrapper .more-bar {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      width: 210px;
      background: #FFF; }
  .filter-bar__item {
    position: relative;
    display: inline-block;
    padding-left: 26px;
    padding-right: 26px;
    white-space: nowrap;
    cursor: pointer; }
    .filter-bar__item:first-of-type {
      padding-left: 0; }
    .filter-bar__item.is-active .filter-bar__text {
      color: #da1781; }
      .filter-bar__item.is-active .filter-bar__text:after {
        width: 100%; }
    .filter-bar__item.more-filter .filter-bar__text {
      position: relative; }
      .filter-bar__item.more-filter .filter-bar__text:before {
        position: absolute;
        content: '';
        border: 6px solid transparent;
        border-top: 6px solid #9b9b9b;
        right: -18px;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 2px; }
    .filter-bar__item.is-opened .select {
      visibility: visible;
      z-index: 1;
      transform: translateY(0);
      transition-delay: 0s;
      overflow-y: auto;
      max-height: 300px; }
    .filter-bar__item.is-opened .wrapper-select {
      padding: 10px;
      z-index: 2; }
    .filter-bar__item .wrapper-select {
      width: 240px;
      visibility: hidden;
      overflow: hidden;
      position: absolute;
      top: 100%;
      left: -20px;
      padding: 0; }
  .filter-bar__text {
    position: relative;
    display: inline-block;
    padding-bottom: 6px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #9b9b9b;
    cursor: pointer; }
    .filter-bar__text:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 3px;
      width: 0;
      background-color: #da1781;
      transition: .3s; }

.more-bar .select {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.22);
  border: solid 1px #dfe3e9;
  max-height: 0;
  visibility: hidden;
  transform: translateY(-100%);
  transition: visibility 0s linear 200ms, transform 200ms linear; }
  .more-bar .select__item {
    height: 37px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 37px;
    text-align: left;
    color: #4a4a4a;
    border-bottom: 1px solid #dfe3e9; }
    .more-bar .select__item.is-active .select__text {
      background: #f1f4f8;
      border-bottom: 1px solid #dfe3e9;
      color: #0baff0; }
      .more-bar .select__item.is-active .select__text:after {
        background: url(/static/media/check-mark_icon.d793cfba.svg) 0 0 no-repeat; }
  .more-bar .select__text {
    position: relative;
    display: block;
    height: 37px;
    padding-left: 14px;
    color: #4a4a4a;
    cursor: pointer;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 14px;
    text-decoration: none; }
    .more-bar .select__text::after {
      content: '';
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 11px;
      height: 8px;
      transition: all .35s; }
    .more-bar .select__text:hover {
      cursor: pointer;
      background: #f6fafe;
      border-bottom: 1px solid #dfe3e9; }

@media (max-width: 768px) {
  .filter-bar-wrapper .more-bar {
    background: transparent; } }

@media (max-width: 667px) {
  .filter-bar-wrapper {
    width: 100%;
    padding: 9px 10px;
    box-sizing: border-box; }
    .filter-bar-wrapper .content {
      touch-action: manipulation; }
    .filter-bar-wrapper .scrollarea .scrollbar-container.horizontal {
      height: 5px;
      background: #e3e3e3;
      opacity: 1;
      z-index: 2; }
      .filter-bar-wrapper .scrollarea .scrollbar-container.horizontal .scrollbar {
        height: 5px;
        margin-top: 0;
        background: #c6c6c6;
        border-radius: 2px; }
    .filter-bar-wrapper .more-bar {
      display: none; }
  .filter-bar {
    display: flex;
    overflow: visible;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    min-width: calc(100vw - 20px);
    height: 64px;
    align-items: flex-start; }
    .filter-bar__icon {
      display: block;
      width: 38px;
      height: 38px;
      margin-bottom: 5px; }
      .filter-bar__icon.popular {
        background: url(/static/media/popular.ce08350f.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.newest {
        background: url(/static/media/newest.d4a39b7c.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.under10 {
        background: url(/static/media/under10.89ea43fc.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.arts {
        background: url(/static/media/arts.6cfb98d2.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.automotive {
        background: url(/static/media/automotive.a80d70a7.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.baby {
        background: url(/static/media/baby.beb9baf6.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.beauty {
        background: url(/static/media/beauty.76cbd9a6.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.clothing {
        background: url(/static/media/clothing.f70cb4da.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.electronics {
        background: url(/static/media/electronics.cb3d3658.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.games {
        background: url(/static/media/games.395f0409.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.garden {
        background: url(/static/media/garden.aaf3a902.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.grocery {
        background: url(/static/media/grocery.b2f4742e.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.home {
        background: url(/static/media/home.92ed15d6.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.movies {
        background: url(/static/media/movies.b206109f.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.music {
        background: url(/static/media/music.72d59259.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.office {
        background: url(/static/media/office.fba3b1b8.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.outdoors {
        background: url(/static/media/outdoors.3802c528.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.personal {
        background: url(/static/media/personal.475d662e.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.pets {
        background: url(/static/media/pets.7e32399c.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.phones {
        background: url(/static/media/phones.712bdfe3.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__icon.tools {
        background: url(/static/media/tools.37933424.svg) 0 0 no-repeat;
        background-size: contain; }
    .filter-bar__text {
      padding-bottom: 0;
      font-size: 8px;
      line-height: 10px;
      color: #313d4f;
      display: inline-block; }
    .filter-bar__item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin-right: 12px;
      margin-bottom: 12px;
      padding: 0;
      box-sizing: border-box; }
      .filter-bar__item.is-active .popular {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/popular-active.a769f041.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .newest {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/newest-active.b34d2562.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .under10 {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/under10-active.7eab25be.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .arts {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/arts-active.b9ebb5ea.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .automotive {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/automotive-active.adefa232.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .baby {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/baby-active.8dae0e89.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .beauty {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/beauty-active.433212af.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .clothing {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/clothing-active.8f24a99b.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .electronics {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/electronics-active.4d83a719.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .games {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/games-active.5a0bb215.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .garden {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/garden-active.ad5ae2c8.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .grocery {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/grocery-active.65e8e9e0.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .home {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/home-active.477e8712.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .movies {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/movies-active.645f7368.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .music {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/music-active.22294145.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .office {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/office-active.63ce710a.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .outdoors {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/outdoors-active.16eb20a1.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .personal {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/personal-active.1ca87f16.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .pets {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/pets-active.02f77332.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .phones {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/phones-active.f5ed447e.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .tools {
        -webkit-animation-name: animateOpacity;
                animation-name: animateOpacity;
        transition: .4s;
        background: url(/static/media/tools-active.5de10be9.svg) 0 0 no-repeat;
        background-size: contain; }
      .filter-bar__item.is-active .filter-bar__text {
        color: #313d4f; }
        .filter-bar__item.is-active .filter-bar__text:after {
          display: none; } }

.category-filter-wrapper {
  position: relative;
  min-height: 70px; }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  margin: 0 auto; }

.input {
  display: block;
  font-size: 14px;
  color: #000000;
  font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
  border: solid 1px #c7c7c7;
  box-sizing: border-box;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: normal; }
  .input-large {
    width: 568px;
    height: 44px; }
  .input-big {
    width: 440px;
    height: 44px; }
  .input-middle {
    width: 278px;
    height: 44px; }
  .input-small {
    width: 216px;
    height: 44px; }
  .input-label {
    display: block;
    font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.17;
    text-align: left;
    color: #9b9b9b;
    text-transform: uppercase; }
    .input-label__text {
      display: block;
      padding-top: 25px;
      padding-bottom: 14px;
      font-family: 'proxima_nova_regular', Helvetica, sans-serif; }
    .input-label_cards {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding-bottom: 14px; }
  .input:focus {
    outline: none; }
  .input.error {
    border: solid 1px #da1781; }
  .input::-webkit-input-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }
  .input::-moz-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }
  .input:-ms-input-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    color: #c7c7c7;
    font-weight: 100; }

input:disabled {
  background: #e7e7e7; }

.form {
  position: relative; }
  .form__row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .form__row__btns {
      display: flex;
      justify-content: flex-end; }
    .form__row__part {
      display: inline-block; }
  .form__title {
    display: block;
    font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #4a4a4a; }
    .form__title--bold {
      font-family: 'proxima_nova_bold', Helvetica, sans-serif; }
  .form__text {
    display: block;
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #9b9b9b;
    line-height: 20px; }
    .form__text__link {
      text-decoration: none;
      cursor: pointer; }
    .form__text-bold {
      font-family: 'proxima_nova_regular', Helvetica, sans-serif;
      font-weight: bold; }

.cerise {
  color: #da1781; }

.azure {
  color: #0baff0; }

.green {
  color: #87b576; }

.violet {
  color: #bd0fe1; }
  .violet-bg {
    background: #bd0fe1; }

.squash {
  color: #f6a623; }
  .squash-bg {
    background: #f6a623; }

.hide {
  display: none; }
  @media (max-width: 480px) {
    .hide-for-mobile {
      display: none; } }

.text-align__left {
  text-align: left; }

.text-align__right {
  text-align: right; }

.text-align__center {
  text-align: right; }

.fw-bold {
  font-weight: bold; }

.fw-normal {
  font-weight: normal; }

.fw-lighter {
  font-weight: lighter; }

.z-index-100 {
  z-index: 100; }

.divider {
  display: flex;
  background: #e2e2e2; }
  .divider-row {
    flex-direction: row;
    width: 100%;
    height: 2px; }
  .divider-col {
    flex-direction: column;
    width: 2px;
    height: 100%; }

.space-between {
  display: flex;
  justify-content: space-between; }
  .space-between-col {
    flex-direction: column;
    height: 100%; }
  .space-between-row {
    flex-direction: row;
    width: 100%; }

.width-100 {
  width: 100%; }

.wrapper-tooltip {
  font-family: 'proxima_nova_semibold', Helvetica, sans-serif; }

@-webkit-keyframes right-panel-appear {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

@keyframes right-panel-appear {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

.mp-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 6;
  transition: .4s; }
  .mp-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 40px;
    box-sizing: border-box; }
    @media (max-width: 768px) {
      .mp-footer-content {
        padding: 15px 20px; } }
  .mp-footer__link {
    position: relative;
    margin: 0 7px;
    padding-left: 3px;
    padding-right: 3px;
    text-decoration: none;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: right;
    color: #9b9b9b;
    white-space: nowrap; }
    .mp-footer__link:after {
      position: absolute;
      content: '';
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: .4s;
      background: #ffffff; }
    .mp-footer__link:hover:after {
      transition: .4s;
      width: 100%; }
  .mp-footer__copy:after {
    display: none; }
  .mp-footer.is-active {
    transition: .4s;
    background: rgba(0, 0, 0, 0.5); }
  .mp-footer.is-active .mp-footer__link {
    transition: .4s;
    color: #ffffff; }

#___reactour > div:first-child {
  opacity: .5; }

.tutorial-wrapper.hidden {
  display: none; }

.tutorial.reactour__helper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 40px 30px 25px;
  min-height: 140px;
  width: 100%;
  max-width: 300px;
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.25;
  border-radius: 4px;
  color: #4a4a4a;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
  .tutorial.reactour__helper:focus {
    outline: none; }
  @media (max-width: 480px) {
    .tutorial.reactour__helper {
      min-width: 200px;
      max-width: 250px;
      padding-left: 12px;
      padding-right: 12px; } }

.tutorial [data-tour-elem="badge"] {
  font-family: inherit; }

.tutorial .reactour__dot {
  width: 10px;
  height: 10px;
  border-width: 2px; }

.tutorial .reactour__dot--is-active {
  transform: none; }

.tutorial__mask {
  border-radius: 4px; }

.tutorial .reactour__dot {
  border-color: #9b9b9b; }

.tutorial .reactour__dot--is-active {
  background-color: #9b9b9b; }

.tutorial__text-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start; }

.tutorial__text {
  padding: 4px 0; }

.tutorial__finish {
  align-self: flex-end;
  height: 30px;
  width: 60px;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #da1781;
  color: #ffffff;
  border-radius: 4px; }

.tutorial__arrow {
  width: 17px;
  height: 17px;
  background: transparent url(/static/media/tutorial-arrow.c2d04fbf.svg) no-repeat;
  background-size: 17px;
  background-position: center center;
  border: none;
  cursor: pointer; }
  .tutorial__arrow:disabled {
    opacity: .5;
    cursor: not-allowed;
    background: transparent url(/static/media/tutorial-arrow.c2d04fbf.svg) no-repeat;
    background-size: 15px;
    background-position: center center; }

@media (max-width: 480px) {
  .tutorial [data-tour-elem="left-arrow"] {
    margin-right: 10px; } }

@media (max-width: 480px) {
  .tutorial [data-tour-elem="right-arrow"] {
    margin-left: 10px; } }

.tutorial__arrow--next {
  transform: rotate(180deg); }

.tutorial-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 580px;
  padding: 25px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5); }
  @media (max-width: 667px) {
    .tutorial-popup {
      max-width: 336px; } }

.tutorial-popup__content {
  max-width: 400px;
  margin: 0 auto;
  text-align: center; }
  @media (max-width: 667px) {
    .tutorial-popup__content {
      max-width: 300px; } }

.tutorial-popup__text {
  padding: 15px 0;
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1.2;
  color: #313d4f; }

.tutorial-popup__action-box {
  padding: 15px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start; }
  @media (max-width: 667px) {
    .tutorial-popup__action-box {
      flex-direction: column;
      align-items: center; } }

.tutorial-popup__action {
  margin: 15px 7px;
  padding: 10px;
  max-width: 194px;
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  font-size: 18px;
  border-radius: 2px;
  border: 1px solid #da1781;
  background-color: #ffffff;
  color: #da1781; }
  @media (max-width: 667px) {
    .tutorial-popup__action {
      max-width: 100%;
      width: 100%; } }

.tutorial-popup__action--secondary {
  background-color: #da1781;
  color: #ffffff; }

.tutorial-popup__badge {
  position: absolute;
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  background-color: #4a4a4a;
  height: 1.875em;
  line-height: 2;
  padding-left: 0.8125em;
  padding-right: 0.8125em;
  font-size: 1em;
  border-radius: 1.625em;
  color: white;
  text-align: center;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.3);
  top: -0.8125em;
  left: -0.8125em; }

.tutorial__icon {
  margin: 0 2px;
  width: 20px;
  height: 18px;
  vertical-align: middle; }

.switch {
  width: 41px;
  height: 22px;
  overflow: hidden; }
  .switch__input {
    display: none; }
  .switch__input:checked + .switch__label:before {
    border: 1px solid #7ab55c;
    background-color: #7ab55c; }
  .switch__input:checked + .switch__label:after {
    left: calc(41px - 22px + 1px); }
  .switch__label {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer; }
    .switch__label:before {
      position: absolute;
      content: '';
      left: 0;
      right: auto;
      top: 0;
      width: 100%;
      height: 100%;
      transition: .3s;
      border-radius: 40px;
      border: 1px solid #afafaf;
      background-color: #afafaf; }
    .switch__label:after {
      display: block;
      position: absolute;
      left: 1px;
      top: 1px;
      content: '';
      width: calc(22px - 2px);
      height: calc(22px - 2px);
      border-radius: 50%;
      background-color: #ffffff;
      transition: .3s; }

.circle-progress {
  position: relative; }
  .circle-progress__value-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .circle-progress__value {
    margin-left: 4px;
    font-family: proxima_nova_bold, Helvetica, sans-serif;
    font-size: 28px;
    letter-spacing: 0;
    color: #4a4a4a;
    transform: translateY(-3px); }
  .circle-progress__logo {
    max-width: 22px;
    transform: translateY(3px); }
  .circle-progress__container {
    transform: skew(-20deg) rotate(-90deg); }
  .circle-progress__bar, .circle-progress__fill {
    fill: none; }
  .circle-progress__bar {
    stroke: #e7e7e7; }
  .circle-progress__fill {
    stroke: #da1781;
    stroke-linecap: round; }

.quick-coin {
  width: 220px;
  padding: 12px 12px 20px;
  background-color: #f6f7f8;
  -webkit-animation-name: right-panel-appear;
          animation-name: right-panel-appear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-duration: .5s;
          animation-duration: .5s; }
  @media (max-width: 480px) {
    .quick-coin {
      width: auto;
      min-width: 100%;
      padding: 10px 6px 14px; } }
  .quick-coin-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 4px 0; }
  .quick-coin-heading__title {
    display: flex;
    align-items: center; }
  .quick-coin-heading__icon {
    width: 21px;
    height: 19px; }
  .quick-coin-heading__text {
    margin-left: 8px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 17px;
    line-height: 1;
    transform: translateY(1px);
    color: #4a4a4a; }
    @media (max-width: 480px) and (min-width: 361px) {
      .quick-coin-heading__text {
        font-size: 15px; } }
    @media (max-width: 360px) {
      .quick-coin-heading__text {
        font-size: 14px; } }
  .quick-coin-heading__checkbox {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .quick-coin__checkbox-label {
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 20px;
    color: #c6c6c6; }
  .quick-coin__content {
    padding-top: 20px;
    border-top: 1px solid #e2e2e2; }
    @media (max-width: 480px) {
      .quick-coin__content {
        padding-top: 5px; } }
  .quick-coin__progress {
    display: flex;
    justify-content: center; }
  .quick-coin__title {
    margin-top: 20px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    color: #4a4a4a; }
    @media (max-width: 480px) {
      .quick-coin__title {
        margin-top: 8px; } }
  .quick-coin-list {
    margin-top: 15px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    color: #4a4a4a; }
    @media (max-width: 480px) {
      .quick-coin-list {
        margin-top: 6px;
        line-height: 1.3; } }
  .quick-coin-list__item {
    margin-top: 20px; }
    @media (max-width: 480px) {
      .quick-coin-list__item {
        margin-top: 10px; } }

.quickto-cart {
  width: 220px;
  min-width: 220px;
  padding: 11px;
  min-height: inherit;
  background-color: #f6f7f8;
  transition: .3s;
  -webkit-animation-name: right-panel-appear;
          animation-name: right-panel-appear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-duration: .5s;
          animation-duration: .5s; }
  @media (max-width: 480px) {
    .quickto-cart {
      width: auto;
      min-width: 100%; } }
  .quickto-cart.is-active {
    transform: translateX(0); }
  .quickto-cart__head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 13px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 17px;
    color: #4a4a4a;
    border-bottom: 1px solid #e2e2e2; }
    @media (max-width: 480px) {
      .quickto-cart__head {
        font-size: 14px; } }
  .quickto-cart-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center; }
  .quickto-cart-title__icon {
    margin-right: 9px; }
  .quickto-cart-title span {
    transform: translateY(2px); }
  .quickto-cart__count {
    transform: translateY(4px); }
  .quickto-cart__list {
    padding-bottom: 12px; }
  .quickto-cart-product {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: 16px 0; }
  .quickto-cart-product:not(:first-child) {
    border-top: 1px solid #e2e2e2; }
  .quickto-cart-product__img-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    width: 60px;
    height: 67px;
    border: 1px solid #e2e2e2; }
  .quickto-cart-product__img {
    max-width: 100%;
    max-height: 100%; }
  .quickto-cart-product__info {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 8px;
    overflow: hidden; }
  .quickto-cart-product__name {
    max-width: 114px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.08;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #4a4a4a; }
  .quickto-cart-product__brand {
    max-width: 114px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #9b9b9b; }
  .quickto-cart-product__price-box {
    margin-top: 3px;
    max-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 13px;
    white-space: nowrap;
    color: #da1781; }
  .quickto-cart-product__price {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .quickto-cart-product__coins-icon {
    height: 11px;
    margin-right: 3px;
    transform: translateY(-1px); }
  .quickto-cart-product__delete {
    padding: 0;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 11px;
    line-height: 1.18;
    color: #4a4a4a; }
  .quickto-cart__checkout {
    height: 45px;
    width: 100%;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    background-color: #da1781; }
    .quickto-cart__checkout:disabled {
      background-color: #e2e2e2;
      cursor: not-allowed; }
  .quickto-cart__checkout > span {
    display: inline-block;
    transform: translateY(1px); }

.favorites-block {
  width: 100%;
  min-width: 250px;
  min-height: 50px;
  height: 60vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  -webkit-animation-name: right-panel-appear;
          animation-name: right-panel-appear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-duration: .5s;
          animation-duration: .5s; }
  .favorites-block__top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    border-bottom: 1px solid #e2e2e2;
    background-color: #fafafa; }
  .favorites-block__top .title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    .favorites-block__top .title__icon {
      display: block;
      width: 16px;
      height: 13px;
      background-image: url(/static/media/heart-active.e2d7d8fe.svg);
      background-size: cover; }
    .favorites-block__top .title__text {
      font-size: 17px;
      color: #4a4a4a;
      margin-left: 10px; }
  .favorites-block__top .count {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 17px;
    color: #4a4a4a; }
  .favorites-block__products {
    max-height: calc(100vh - 340px);
    padding: 0 10px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #fafafa; }
    .favorites-block__products .favorite-product {
      display: flex;
      padding: 12px 0 12px 0;
      border-top: 1px solid #e2e2e2; }
      .favorites-block__products .favorite-product:first-child {
        border-top: none; }
      .favorites-block__products .favorite-product__photo {
        min-width: 60px;
        width: 60px;
        height: 60px;
        background-color: #ffffff;
        position: relative;
        cursor: pointer; }
        .favorites-block__products .favorite-product__photo > img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%; }
      .favorites-block__products .favorite-product-col {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start; }
      .favorites-block__products .favorite-product-row {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start; }
      .favorites-block__products .favorite-product__info {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        align-items: space-between;
        width: 100%;
        margin-left: 7px;
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        color: #4a4a4a;
        min-width: 130px; }
        .favorites-block__products .favorite-product__info a {
          text-decoration: none; }
        .favorites-block__products .favorite-product__info__actions {
          display: flex;
          margin-top: 5px;
          font-size: 12px; }
          .favorites-block__products .favorite-product__info__actions__delete {
            display: block;
            padding-right: 5px;
            cursor: pointer; }
          .favorites-block__products .favorite-product__info__actions__buy {
            display: block;
            position: relative;
            padding-left: 5px;
            text-decoration: none;
            color: #da1781;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            cursor: pointer; }
            .favorites-block__products .favorite-product__info__actions__buy:before {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              width: 1px;
              height: 100%;
              background-color: #4a4a4a; }

@media (max-width: 768px) {
  .favorites-block {
    min-width: 0;
    max-height: none; }
    .favorites-block__products .favorite-product__info {
      min-width: 0;
      width: auto; } }

@media (max-width: 480px) {
  .favorites-block {
    margin: 0;
    height: 100%; }
  .favorites-block__products {
    max-height: 100%;
    flex-grow: 1; } }

.mp-aside-panel {
  position: relative;
  width: 270px;
  min-width: 270px;
  padding: 10px; }
  @media (max-width: 768px) and (min-width: 481px) {
    .mp-aside-panel {
      padding: 5px 10px; } }
  @media (max-width: 480px) {
    .mp-aside-panel {
      min-width: 190px;
      width: 190px;
      padding-top: 15px;
      padding-bottom: 15px; } }
  @media (max-width: 360px) {
    .mp-aside-panel {
      min-width: 172px;
      width: 172px;
      padding-left: 5px;
      padding-right: 5px; } }
  .mp-aside-panel--fixed {
    position: fixed;
    right: 22px;
    transition: top .4s; }
    @media (max-width: 768px) {
      .mp-aside-panel--fixed {
        right: 0; } }
  .mp-aside-panel--full-width {
    position: fixed;
    min-width: 100%;
    width: 100%;
    height: 100%;
    top: 83px;
    padding: 0;
    z-index: 2; }

.products-recommend {
  width: 100%;
  padding: 22px 42px;
  background: #ebf1f6; }
  .products-recommend:last-of-type {
    margin-top: 10px; }
  @media (max-width: 667px) {
    .products-recommend {
      padding: 12px 12px 0; } }
  .products-recommend__overflow {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    width: 100%;
    min-height: 182px; }
  .products-recommend__title {
    margin: 0 0 10px 42px;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    @media (max-width: 667px) {
      .products-recommend__title {
        margin: 0;
        padding-left: 12px;
        font-size: 14px; } }
  .products-recommend .product-card {
    height: 170px;
    margin-right: 10px;
    max-height: 170px;
    min-width: 170px;
    margin-bottom: 12px; }
    @media (max-width: 667px) {
      .products-recommend .product-card {
        width: 130px;
        min-width: 130px;
        height: 130px;
        max-height: 130px;
        margin-right: 10px; } }
    .products-recommend .product-card__body {
      max-height: 130px;
      height: 100%;
      width: 100%; }
      @media (max-width: 667px) {
        .products-recommend .product-card__body {
          max-height: 100px; } }
  @media (max-width: 667px) {
    .products-recommend .product-main-info {
      padding: 6px 5px; } }
  @media (max-width: 667px) {
    .products-recommend .product-main-info__title {
      font-size: 8px;
      line-height: 10px; } }
  @media (max-width: 667px) {
    .products-recommend .product-main-info__brand {
      font-size: 8px;
      line-height: 10px; } }
  @media (max-width: 667px) {
    .products-recommend .product-main-info__price-current {
      font-size: 9px;
      line-height: 10px; } }
  @media (max-width: 667px) {
    .products-recommend .product-main-info__price-old {
      font-size: 7px;
      line-height: 7px; } }
  @media (max-width: 667px) {
    .products-recommend .content-placeholder {
      height: 28%; } }
  @media (max-width: 667px) {
    .products-recommend .line {
      height: 9px; } }

@-webkit-keyframes animateOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes collage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes collage {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes favorites-appear {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes favorites-appear {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@keyframes favorites-disappear {
  from {
    transform: translateX(0);
    opacity: 1;
    min-width: 220px;
    width: 220px;
    margin-left: -10px; }
  to {
    transform: translateX(100%);
    opacity: 0;
    min-width: 0;
    width: 0;
    margin-left: 0; } }

@-webkit-keyframes favorites-disappear {
  from {
    transform: translateX(0);
    opacity: 1;
    min-width: 220px;
    width: 220px;
    margin-left: -10px; }
  to {
    transform: translateX(100%);
    opacity: 0;
    min-width: 0;
    width: 0;
    margin-left: 0; } }

@keyframes mobile-favorites-appear {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes mobile-favorites-appear {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@keyframes mobile-favorites-disappear {
  from {
    transform: translateX(0);
    opacity: 1;
    min-width: 100%;
    width: 100%; }
  to {
    transform: translateX(100%);
    opacity: 0;
    min-width: 0;
    width: 0; } }

@-webkit-keyframes mobile-favorites-disappear {
  from {
    transform: translateX(0);
    opacity: 1;
    min-width: 100%;
    width: 100%; }
  to {
    transform: translateX(100%);
    opacity: 0;
    min-width: 0;
    width: 0; } }

@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

@keyframes hintloading {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

.product-placeholder {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding: 10px;
  background: #ffffff; }
  .product-placeholder--loading .img-placeholder__img,
  .product-placeholder--loading .line {
    animation: hintloading 2s ease-in-out 0s infinite reverse; }

.img-placeholder {
  width: 100%;
  height: 60%;
  flex-grow: 1; }
  .img-placeholder__img {
    border: 1px solid white;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2; }

.content-placeholder {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 25%;
  padding-top: 8px; }

.line {
  height: 12px;
  background: #f2f2f2;
  border-radius: 2px;
  margin-bottom: 5px; }
  .line--70 {
    width: 70%; }
  .line--50 {
    width: 50%; }
  .line--25 {
    width: 25%; }

.two-inputs-row {
  display: flex;
  justify-content: space-between; }
  .two-inputs-row.displaced > div:first-child {
    flex-basis: 65%; }
  .two-inputs-row.displaced > div:last-child {
    flex-basis: 35%; }
  .two-inputs-row > div:first-child {
    margin-right: 5px; }
  .two-inputs-row > div:last-child {
    margin-left: 5px; }

.three-inputs-row {
  display: flex;
  justify-content: space-between; }
  .three-inputs-row > div:nth-child(2) {
    margin: 0 10px; }
  @media all and (max-width: 480px) {
    .three-inputs-row {
      flex-wrap: wrap; }
      .three-inputs-row > div:nth-child(2) {
        margin: 5px 0; } }

.common-input-wraper {
  width: 100%;
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  padding-bottom: 10px; }
  .common-input-wraper label {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    font-family: proxima_nova_light, Helvetica, sans-serif;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b;
    margin-bottom: 5px;
    text-transform: uppercase; }
  .common-input-wraper input {
    width: 100%;
    padding: 13px;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    color: #4a4a4a;
    font-size: 14px; }
  .common-input-wraper.input-with-error {
    padding-bottom: 0; }
    .common-input-wraper.input-with-error input {
      background-color: #fbe8ea;
      border: solid 1px #d0011b;
      color: #d0011b; }
    .common-input-wraper.input-with-error label, .common-input-wraper.input-with-error .error-message {
      color: #f64316; }
    .common-input-wraper.input-with-error .error-message {
      min-height: 10px;
      font-size: 10px;
      text-align: right;
      line-height: 1; }

.radio-input input {
  visibility: hidden; }

.radio-input + .radio-input__label {
  cursor: pointer; }

/* The decor of the checkbox in the off state (background). */
.radio-input:not(checked) + .radio-input__label:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  top: 50%;
  left: 18px;
  transform: translateY(-50%) scale(0);
  z-index: 1;
  transition: .4s; }

/* The decor of the checkbox is off (switch). */
.radio-input:not(checked) + .radio-input__label:after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 15px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  border-radius: 50%;
  background-image: linear-gradient(to top, #f2f4f8, #feffff);
  border: solid 0.6px #ced0da;
  box-sizing: border-box; }

/* Change the background of the checkbox when it's on. */
.radio-input:checked + .radio-input__label:before {
  width: 9px;
  height: 9px;
  background-color: #7ab55c;
  top: 50%;
  left: 18px;
  transform: translateY(-50%) scale(1);
  border-radius: 50%;
  transition: .4s; }

.radio-input:checked + .radio-input__label:after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 15px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  border-radius: 50%;
  background-image: linear-gradient(to top, #f2f4f8, #feffff);
  border: solid 0.6px #ced0da;
  box-sizing: border-box; }

input::-webkit-input-placeholder {
  font-family: 'proxima_nova_regular', Helvetica, sans-serif;
  color: #c3c3c3; }

input::-moz-placeholder {
  font-family: 'proxima_nova_regular', Helvetica, sans-serif;
  color: #c3c3c3; }

input:-ms-input-placeholder {
  font-family: 'proxima_nova_regular', Helvetica, sans-serif;
  color: #c3c3c3; }

@media (max-width: 667px) {
  .product-carousel {
    height: 225px;
    max-width: 300px;
    margin: 40px auto 0; } }

.product-carousel__item {
  margin: 4px; }

.product-carousel__item-img {
  width: auto;
  height: auto;
  max-width: 70px;
  max-height: 70px;
  cursor: pointer;
  border: 1px solid transparent; }
  @media (max-width: 970px) and (min-width: 769px) {
    .product-carousel__item-img {
      max-width: 50px;
      max-height: 50px; } }
  @media (max-width: 768px) and (min-width: 668px) {
    .product-carousel__item-img {
      max-width: 40px;
      max-height: 40px; } }
  @media (max-width: 667px) {
    .product-carousel__item-img {
      max-width: 34px;
      max-height: 34px; } }

.product-carousel__item-img.is-active {
  border: 1px solid #c6c6c6; }

.product-carousel--desktop {
  transform: translateY(-4px); }

.product-carousel .slick-slider {
  width: 100%; }

.product-carousel .slick-list {
  margin: 0 auto; }

.product-carousel .slick-track {
  align-items: center; }

.product-carousel .slick-dots {
  top: -45px;
  max-height: 20px; }
  @media (max-width: 480px) {
    .product-carousel .slick-dots {
      top: -42px;
      padding: 0 24px; } }

.product-carousel .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 10px; }

.product-carousel .slick-dots button {
  padding: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #c6c6c6; }

.product-carousel .slick-dots button:before {
  content: '';
  width: 10px;
  height: 10px;
  line-height: 10px; }

.product-carousel .slick-dots .slick-active button {
  background-color: #c6c6c6; }

.carousel {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%; }
  @media (max-width: 667px) and (min-width: 600px) {
    .carousel {
      padding: 20px 0; } }
  @media (max-width: 480px) {
    .carousel {
      flex-wrap: wrap; } }
  .carousel__slide {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative; }
  .carousel__slide-img {
    max-width: 550px;
    max-height: 550px;
    width: auto !important; }
    @media (max-width: 1200px) and (min-width: 951px) {
      .carousel__slide-img {
        max-width: 450px;
        max-height: 450px; } }
    @media (max-width: 950px) and (min-width: 600px) {
      .carousel__slide-img {
        max-width: 300px;
        max-height: 300px; } }
    @media (max-width: 599px) {
      .carousel__slide-img {
        max-width: 350px;
        max-height: 350px; } }
  .carousel .image-view {
    width: 100%; }
  .carousel .image-view .product-carousel__item {
    margin: 0; }
  .carousel .image-view .product-carousel__item-img {
    margin: 0 auto;
    max-width: 100%;
    max-height: 200px;
    border: none; }

.desktop-zoomed-image-wrapper[style] {
  left: 103% !important;
  z-index: 9 !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4) !important;
  background: #fff; }

.mp-modal-qty {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 40px;
  white-space: nowrap; }
  .mp-modal-qty > * {
    width: 40px;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #9b9b9b;
    color: #9b9b9b; }
  .mp-modal-qty__value {
    margin: 0 5px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    text-align: center;
    -moz-appearance: textfield; }
    .mp-modal-qty__value:focus {
      outline: none; }
    .mp-modal-qty__value::-webkit-inner-spin-button, .mp-modal-qty__value::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    @media (max-width: 667px) {
      .mp-modal-qty__value {
        font-size: 18px;
        font-weight: 400;
        border: 1px solid #e3e3e3;
        color: #c6c6c6; } }
  .mp-modal-qty__button {
    padding: 0 0 4px; }
    .mp-modal-qty__button:focus {
      outline: none; }
    .mp-modal-qty__button:hover {
      cursor: pointer; }
    @media (max-width: 667px) {
      .mp-modal-qty__button {
        font-size: 20px;
        font-weight: 400;
        color: #c6c6c6;
        border: 1px solid #c6c6c6; } }

.mp-modal-options {
  white-space: nowrap;
  display: inline-block; }
  .mp-modal-options__item {
    display: inline-block;
    height: 40px;
    padding: 11px 15px 12px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 14px;
    cursor: pointer;
    color: #9b9b9b;
    border: solid 2px #9b9b9b;
    background-color: #ffffff; }
    @media (max-width: 667px) {
      .mp-modal-options__item {
        border: solid 1px #979797;
        line-height: 14px; } }
    .mp-modal-options__item:not(:last-child) {
      margin-right: 10px; }
  .mp-modal-options__item.active {
    color: #ffffff;
    background: #da1781;
    border-color: #da1781; }
  .mp-modal-options__item.in-active {
    color: #e2e2e2;
    background: #ffffff;
    border-color: #e2e2e2; }

.info {
  position: relative;
  width: 100%;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  color: #4a4a4a;
  min-height: 100%; }
  @media (max-width: 667px) {
    .info {
      position: static; } }
  .info * {
    box-sizing: border-box; }
  .info__title {
    max-width: 330px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    max-height: 110.4px;
    /* Fallback for non-webkit */
    font-size: 24px;
    line-height: 1.15;
    overflow: hidden;
    text-overflow: ellipsis;
    /* autoprefixer: on */ }
    .info__title {
      /* autoprefixer: off */
      -webkit-box-orient: vertical; }
    @media (max-width: 667px) {
      .info__title {
        display: block;
        max-width: 90%;
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis; } }
  .info__brand {
    max-width: 330px;
    font-size: 20px;
    line-height: 1.35;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: #0baff0; }
    @media (max-width: 667px) {
      .info__brand {
        max-width: 90%;
        font-size: 14px; } }
  .info__tab-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    border-bottom: 2px solid #e2e2e2; }
    @media (max-width: 668px) {
      .info__tab-list {
        margin-top: 12px; } }
  .info__tab {
    position: relative;
    padding: 0 24px 3px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 18px;
    line-height: normal;
    letter-spacing: .5px;
    text-transform: uppercase;
    cursor: pointer;
    color: #c6c6c6; }
    .info__tab:after {
      position: absolute;
      content: "";
      width: 0;
      height: 3px;
      left: 0;
      bottom: -2px;
      transition: .4s;
      background-color: transparent; }
    @media (max-width: 668px) {
      .info__tab {
        font-size: 14px; } }
  .info__tab.is-active {
    color: #da1781; }
    .info__tab.is-active:after {
      width: 100%;
      background-color: #da1781; }
  .info-details {
    margin-top: 12px;
    margin-bottom: 30px;
    overflow-y: auto; }
  .info-details img {
    max-width: 100%;
    margin: 0 auto;
    display: block; }
  .info-details .scrollarea {
    width: auto;
    height: 100%;
    padding-right: 10px;
    max-height: 177px; }
    @media (max-width: 768px) {
      .info-details .scrollarea {
        max-height: 120px; } }
  .info-details__text {
    font-size: 14px;
    line-height: 1.36;
    color: #9b9b9b; }
  .info-buy {
    margin-top: 15px; }
    @media (max-width: 668px) {
      .info-buy {
        margin-top: 10px; } }
  .info-buy__option-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 18px 0 10px; }
    @media (max-width: 668px) {
      .info-buy__option-list {
        padding-top: 10px; } }
  .info-buy__option {
    margin-right: 28px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 14px;
    cursor: pointer;
    color: #9b9b9b; }
    .info-buy__option.is-active {
      color: #4a4a4a; }
    .info-buy__option.is-active--blink {
      -webkit-animation: option 1s infinite;
              animation: option 1s infinite; }
  .info-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    font-size: 14px;
    line-height: normal; }
  .info-row__label {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center; }
  .info-row__label .icon {
    width: 14px;
    height: 14px;
    transform: translateX(5px); }
    @media (max-width: 667px) {
      .info-row__label .icon {
        width: 11px;
        height: 11px;
        transform: translate(5px, 1px); } }
  .info-row.list-price .info-row__value {
    text-decoration: line-through; }
  .info-row.deal .info-row__value,
  .info-row.coins .info-row__value,
  .info-row.gift .info-row__value {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 18px;
    color: #da1781; }
  .info-row.coins .info-row__value,
  .info-row.gift .info-row__value {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center; }
  .info-row__project-icon {
    width: 18px;
    height: 17px;
    margin-right: 4px;
    transform: translateY(-1px); }
  .info-row__project-icon img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }
  .info-row.gift .info-row__label {
    font-family: proxima_nova_bold, Helvetica, sans-serif; }
  .info-row.save .info-row__label {
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .info-row.save .info-row__value {
    color: #da1781; }
  .info-buy__option-values .scrollarea {
    padding-bottom: 12px; }
  .info-buy__option-values .scrollarea .scrollbar-container {
    height: 6px;
    bottom: 2px;
    border-radius: 8px;
    z-index: 8; }
    .info-buy__option-values .scrollarea .scrollbar-container.active, .info-buy__option-values .scrollarea .scrollbar-container:hover {
      background: rgba(128, 128, 128, 0.2);
      opacity: 0.6 !important; }
  .info-buy__option-values .scrollarea .scrollbar-container .scrollbar {
    margin-top: 0;
    height: 6px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.4); }
    .info-buy__option-values .scrollarea .scrollbar-container .scrollbar:hover {
      cursor: pointer; }
  .info-action-box {
    margin-top: 25px; }
  .info-action-wrapper, .info-action-wrapper--cart {
    position: relative;
    height: 49px; }
  .info-action-wrapper--cart {
    margin-top: 12px; }
  .info-action--favorites {
    position: absolute;
    top: 4px;
    transform: translateX(calc(-100% - 30px));
    margin-right: 10px;
    width: 30px;
    height: 25px;
    background-image: url(/static/media/heart-empty-button.edd842a0.svg);
    background-size: contain;
    cursor: pointer; }
    @media (max-width: 667px) {
      .info-action--favorites {
        transform: translateX(0);
        top: 14px;
        right: 0;
        margin-left: 0;
        width: 27px;
        height: 22px;
        background-image: url(/static/media/heart-empty.9f1f33b0.svg); } }
  .info-action--favorites.is-active {
    background-image: url(/static/media/heart-active-button.7b059077.svg); }
  .info-action, .info-action--cart {
    width: 100%;
    height: 100%;
    font-size: 16px; }
    @media (max-width: 667px) {
      .info-action, .info-action--cart {
        font-size: 20px; } }
  @media (max-width: 667px) {
    .info-action__text {
      display: none; } }
  .info-action--cart {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    background-color: #e2e2e2;
    color: #4a4a4a; }
    .info-action--cart:hover {
      box-shadow: none; }
  .info__buy-error {
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 1.92;
    text-align: center;
    white-space: nowrap;
    color: #da1781; }

@-webkit-keyframes option {
  0% {
    color: #9b9b9b;
    border-bottom: 3px solid transparent; }
  50% {
    color: #da1781;
    border-bottom: 3px solid #da1781; }
  100% {
    color: #9b9b9b;
    border-bottom: 3px solid transparent; } }

.auth .reset-your-password__title {
  margin-bottom: 15px;
  text-align: center; }

.mp-sign-in {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 60px 0 100px; }
  @media (max-width: 480px) {
    .mp-sign-in {
      padding: 40px 0 50px; } }
  .mp-sign-in__logo-wrapper {
    width: 190px;
    height: 66px; }
    @media (max-width: 480px) {
      .mp-sign-in__logo-wrapper {
        display: none; } }
  .mp-sign-in__logo {
    width: 100%;
    height: auto;
    max-height: 100%; }
  .mp-sign-in__box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    padding: 42px 20px 0; }
    @media (max-width: 768px) {
      .mp-sign-in__box {
        flex-direction: column;
        align-items: center; } }
    @media (max-width: 480px) {
      .mp-sign-in__box {
        padding: 0 12px; } }
  .mp-sign-in__part {
    flex-grow: 1;
    width: 50%;
    max-width: 480px;
    position: relative; }
    .mp-sign-in__part:not(.single):first-child {
      padding-right: 60px;
      border-right: 1px solid #e2e2e2; }
    .mp-sign-in__part:not(.single):last-child {
      padding-left: 60px;
      border-left: 1px solid #e2e2e2; }
    @media (max-width: 768px) {
      .mp-sign-in__part {
        width: 100%;
        max-width: 70%; }
        .mp-sign-in__part:not(.single):first-child {
          padding-right: 0;
          padding-bottom: 40px;
          border-right: none;
          border-bottom: 2px solid #e2e2e2; }
        .mp-sign-in__part:not(.single):last-child {
          padding-left: 0;
          border-left: none; } }
    @media (max-width: 480px) {
      .mp-sign-in__part {
        max-width: 100%; }
        .mp-sign-in__part:not(.single):first-child {
          padding-bottom: 40px; }
        .mp-sign-in__part:not(.single):last-child {
          padding-top: 15px; } }
    .mp-sign-in__part.single {
      padding: 0;
      border: none; }
  .mp-sign-in__form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
    text-align: center; }
  .mp-sign-in__form-content {
    width: 100%; }
  .mp-sign-in__form-divider {
    display: inline-block;
    padding: 6px;
    margin: 5px auto 0;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 19px;
    text-transform: uppercase; }
  .mp-sign-in__title {
    margin: 8px 0 20px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 30px;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a; }
    @media (max-width: 1024px) and (min-width: 961px) {
      .mp-sign-in__title {
        font-size: 28px; } }
    @media (max-width: 960px) and (min-width: 481px) {
      .mp-sign-in__title {
        font-size: 24px; } }
    @media (max-width: 480px) {
      .mp-sign-in__title {
        margin-bottom: 10px;
        font-size: 18px; } }
  .mp-sign-in__subtitle {
    max-width: 300px;
    margin: 0 auto;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 24px;
    font-style: italic;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #313d4f; }
    @media (max-width: 1024px) and (min-width: 961px) {
      .mp-sign-in__subtitle {
        font-size: 22px; } }
    @media (max-width: 960px) and (min-width: 481px) {
      .mp-sign-in__subtitle {
        max-width: 250px;
        font-size: 18px; } }
    @media (max-width: 480px) {
      .mp-sign-in__subtitle {
        font-size: 14px; } }
  .mp-sign-in__fb {
    margin-top: 25px; }
    @media (max-width: 480px) {
      .mp-sign-in__fb {
        margin-top: 12px;
        margin-bottom: 0;
        font-size: 18px; } }
  .mp-sign-in__bottom-label {
    position: absolute;
    max-width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(calc(-50% - 30px), 34px);
    width: 100%;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.54;
    text-align: center;
    color: #9b9b9b; }
    @media (max-width: 960px) and (min-width: 481px) {
      .mp-sign-in__bottom-label {
        font-size: 18px; } }
    @media (max-width: 768px) and (min-width: 481px) {
      .mp-sign-in__bottom-label {
        font-size: 18px;
        transform: translate(calc(-50%), -6px); } }
    @media (max-width: 480px) {
      .mp-sign-in__bottom-label {
        font-size: 14px;
        transform: translate(calc(-50%), -10px); } }
  .mp-sign-in .sign-up-form .common-input-wraper {
    margin-bottom: 0; }
  .mp-sign-in .sign-in {
    width: 100%; }
  .mp-sign-in .common-input-wraper,
  .mp-sign-in .common-button {
    margin-top: 25px; }
  .mp-sign-in .mp-sign-in__part .common-input-wraper input {
    width: 100%;
    height: auto;
    padding: 18px 16px;
    font-size: 20px; }
    @media (max-width: 960px) {
      .mp-sign-in .mp-sign-in__part .common-input-wraper input {
        font-size: 18px;
        padding: 16px; } }
    @media (max-width: 480px) {
      .mp-sign-in .mp-sign-in__part .common-input-wraper input {
        padding: 13px 16px;
        font-size: 14px; } }
  .mp-sign-in .common-button {
    width: 100%;
    padding: 19px 16px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 26px;
    line-height: 23px; }
    @media (max-width: 960px) {
      .mp-sign-in .common-button {
        padding: 16px;
        font-size: 22px; } }
    @media (max-width: 480px) {
      .mp-sign-in .common-button {
        margin-top: 12px;
        padding: 12px 10px;
        font-size: 17px; } }
  .mp-sign-in .forgot-link {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 34px);
    margin: 0;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.62;
    letter-spacing: normal;
    color: #2ac7fd;
    white-space: nowrap;
    cursor: pointer; }
    @media (max-width: 960px) and (min-width: 481px) {
      .mp-sign-in .forgot-link {
        font-size: 18px; } }
    @media (max-width: 667px) and (min-width: 481px) {
      .mp-sign-in .forgot-link {
        left: 0;
        transform: translateY(34px); } }
    @media (max-width: 480px) {
      .mp-sign-in .forgot-link {
        font-size: 14px; } }
  .mp-sign-in .sign-reset-form, .mp-sign-in .reset-your-password {
    width: 100%; }
  .mp-sign-in .reset-your-password .common-input-wraper {
    margin-top: 16px; }
  .mp-sign-in .sign-reset-form__title, .mp-sign-in .reset-your-password__title {
    margin-top: 8px;
    margin-bottom: 20px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 30px;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a; }
    @media (max-width: 1024px) and (min-width: 961px) {
      .mp-sign-in .sign-reset-form__title, .mp-sign-in .reset-your-password__title {
        font-size: 28px; } }
    @media (max-width: 960px) and (min-width: 481px) {
      .mp-sign-in .sign-reset-form__title, .mp-sign-in .reset-your-password__title {
        font-size: 24px; } }
    @media (max-width: 480px) {
      .mp-sign-in .sign-reset-form__title, .mp-sign-in .reset-your-password__title {
        font-size: 18px; } }
  .mp-sign-in .sign-reset-form__message {
    margin-top: 0;
    margin-bottom: 25px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 24px;
    font-style: italic;
    line-height: 1.17;
    letter-spacing: normal;
    color: #313d4f; }
    @media (max-width: 1024px) and (min-width: 961px) {
      .mp-sign-in .sign-reset-form__message {
        font-size: 22px; } }
    @media (max-width: 960px) and (min-width: 481px) {
      .mp-sign-in .sign-reset-form__message {
        font-size: 18px; } }
    @media (max-width: 480px) {
      .mp-sign-in .sign-reset-form__message {
        max-width: 280px;
        margin: 0 auto;
        font-size: 14px; } }

.logged-in {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 60px 0 140px;
  max-width: 420px;
  width: 50%;
  margin: 0 auto; }
  @media (max-width: 768px) and (min-width: 481px) {
    .logged-in {
      width: 70%;
      padding-bottom: 90px; } }
  @media (max-width: 480px) {
    .logged-in {
      max-width: 100%;
      padding: 48px 12px 50px;
      width: 100%; } }
  .logged-in__logo-wrapper {
    width: 190px;
    height: 66px; }
    @media (max-width: 480px) {
      .logged-in__logo-wrapper {
        display: none; } }
  .logged-in__logo {
    width: 100%;
    height: auto;
    max-height: 100%; }
  .logged-in__heading {
    margin-top: 51px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 30px;
    line-height: 1.38;
    color: #4a4a4a; }
    @media (max-width: 1024px) and (min-width: 961px) {
      .logged-in__heading {
        font-size: 28px; } }
    @media (max-width: 960px) and (min-width: 481px) {
      .logged-in__heading {
        font-size: 24px; } }
    @media (max-width: 480px) {
      .logged-in__heading {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 18px; } }
  .logged-in-form {
    width: 100%;
    margin-top: 20px;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
    @media (max-width: 480px) {
      .logged-in-form {
        max-width: 100%;
        margin-top: 0; } }
  .logged-in-form__item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch; }
  .logged-in-form__label {
    align-self: flex-end;
    font-size: 12px;
    color: #0baff0;
    line-height: 2;
    cursor: pointer; }
  .logged-in-form__field {
    height: 62px;
    padding: 0 16px;
    font-size: 20px;
    line-height: 62px;
    border-radius: 2px;
    background-color: #fafafa;
    border: 1px solid #c7c7c7;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 667px) {
      .logged-in-form__field {
        height: 44px;
        line-height: 44px;
        padding-left: 10px;
        font-size: 14px; } }
  .logged-in-form__submit {
    width: 100%;
    margin-top: 25px;
    padding: 19px 16px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 26px;
    line-height: 23px; }
    @media (max-width: 960px) and (min-width: 668px) {
      .logged-in-form__submit {
        padding: 16px 16px;
        font-size: 22px; } }
    @media (max-width: 667px) {
      .logged-in-form__submit {
        padding: 12px 10px;
        font-size: 17px; } }
    @media (max-width: 480px) {
      .logged-in-form__submit {
        margin-top: 12px; } }

.details {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 460px;
  min-width: 340px;
  min-height: 100%;
  padding: 100px 30px 100px 60px;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  color: #4a4a4a;
  background-color: #ebf1f6;
  overflow: hidden; }
  @media (max-width: 1024px) and (min-width: 769px) {
    .details {
      padding-left: 30px;
      max-width: 330px;
      min-width: 300px; } }
  @media (max-width: 768px) and (min-width: 668px) {
    .details {
      padding-left: 20px;
      padding-right: 20px;
      max-width: 260px;
      min-width: 260px; } }
  @media (max-width: 667px) {
    .details {
      flex-direction: column-reverse;
      max-width: 100%;
      min-width: 0;
      min-height: 0;
      padding: 40px 12px 10px;
      border-bottom: 2px solid #d7d7d7; } }
  .details__title {
    margin-bottom: 15px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 30px;
    line-height: .88;
    letter-spacing: normal; }
    @media (max-width: 768px) and (min-width: 668px) {
      .details__title {
        font-size: 24px; } }
    @media (max-width: 667px) {
      .details__title {
        display: none; } }
  .details-order {
    width: 100%; }
    @media (max-width: 667px) and (min-width: 481px) {
      .details-order {
        margin-top: 6px; } }
    @media (max-width: 480px) {
      .details-order {
        margin-top: 10px; } }
  .details-order__label {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center; }
  .details-order__label .icon {
    margin-left: 5px; }
    @media (max-width: 667px) {
      .details-order__label .icon {
        width: 11px;
        height: 11px;
        transform: translateY(-1px); } }
  .details-order__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    font-size: 22px;
    line-height: 1.36; }
    @media (max-width: 768px) and (min-width: 668px) {
      .details-order__row {
        font-size: 18px; } }
    @media (max-width: 667px) {
      .details-order__row {
        margin: 2px 0;
        font-size: 12px; } }
  .details-order__row--total, .details-order__row--coins-total, .details-order__row--discount {
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .details-order__row--total, .details-order__row--coins-total {
    padding-top: 10px;
    font-size: 24px;
    line-height: normal;
    border-top: 2px solid #979797; }
    @media (max-width: 768px) and (min-width: 668px) {
      .details-order__row--total, .details-order__row--coins-total {
        font-size: 20px; } }
    @media (max-width: 667px) {
      .details-order__row--total, .details-order__row--coins-total {
        padding-top: 4px;
        font-size: 18px;
        border-top-color: #e2e2e2; } }
  .details-order__row--coins-total .details-order__value {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center; }
  @media (max-width: 667px) {
    .details-order__row--subtotal {
      font-family: proxima_nova_semibold, Helvetica, sans-serif; } }
  .details-order__row--coins-earned {
    color: #da1781; }
  .details-order__project-icon {
    width: 21px;
    height: 20px;
    margin-right: 4px;
    transform: translateY(-3px); }
  .details-order__project-icon img {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%; }
  .details-order__row .__react_component_tooltip {
    max-width: 300px;
    font-family: proxima_nova_light, Helvetica, sans-serif; }
  @media (max-width: 667px) {
    .details-order__row .info-icon {
      transform: translateY(0); } }
  .details-product-wrapper {
    padding-top: 30px; }
    @media (max-width: 667px) {
      .details-product-wrapper {
        padding-top: 0; } }
  .details-product {
    display: flex; }
  .details-product__image-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    min-width: 120px;
    margin-right: 25px;
    background: #ffffff;
    border: 1px solid #c6c6c6; }
    @media (max-width: 768px) and (min-width: 668px) {
      .details-product__image-wrapper {
        margin-right: 10px; } }
    @media (max-width: 667px) {
      .details-product__image-wrapper {
        width: 85px;
        height: 85px;
        min-width: 85px;
        margin-right: 10px; } }
  .details-product__image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; }
  .details-product__info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start; }
  .details-product__name {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    max-height: 51.92px;
    /* Fallback for non-webkit */
    font-size: 22px;
    line-height: 1.18;
    overflow: hidden;
    text-overflow: ellipsis;
    /* autoprefixer: on */ }
    .details-product__name {
      /* autoprefixer: off */
      -webkit-box-orient: vertical; }
    @media (max-width: 768px) and (min-width: 668px) {
      .details-product__name {
        display: block;
        /* Fallback for non-webkit */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        max-height: 42.48px;
        /* Fallback for non-webkit */
        font-size: 18px;
        line-height: 1.18;
        overflow: hidden;
        text-overflow: ellipsis;
        /* autoprefixer: on */ }
        .details-product__name {
          /* autoprefixer: off */
          -webkit-box-orient: vertical; } }
    @media (max-width: 667px) {
      .details-product__name {
        display: block;
        /* Fallback for non-webkit */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        max-height: 36.12px;
        /* Fallback for non-webkit */
        font-size: 14px;
        line-height: 1.29;
        overflow: hidden;
        text-overflow: ellipsis;
        /* autoprefixer: on */ }
        .details-product__name {
          /* autoprefixer: off */
          -webkit-box-orient: vertical; } }
  .details-product__brand {
    max-width: 225px;
    margin: 5px 0;
    font-size: 22px;
    line-height: 1.2;
    color: #9b9b9b;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    @media (max-width: 1024px) and (min-width: 769px) {
      .details-product__brand {
        max-width: 130px; } }
    @media (max-width: 768px) and (min-width: 668px) {
      .details-product__brand {
        max-width: 90px;
        font-size: 18px; } }
    @media (max-width: 667px) {
      .details-product__brand {
        margin: 0 0 6px;
        font-size: 12px;
        line-height: 1.17;
        white-space: normal; } }
  .details-product__option {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.33; }
    @media (max-width: 768px) and (min-width: 668px) {
      .details-product__option {
        font-size: 18px; } }
    @media (max-width: 667px) {
      .details-product__option {
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        font-size: 12px; } }
  .details .calculated-on-step {
    color: #9b9b9b;
    font-size: 12px; }

.mp-modal-purchase__shipping {
  flex-grow: 1;
  width: 100%; }
  .mp-modal-purchase__shipping form {
    height: 100%;
    position: relative; }
  .mp-modal-purchase__shipping .market-place-settings-content__actions {
    position: absolute;
    bottom: 0;
    right: 0; }
    @media (max-width: 667px) {
      .mp-modal-purchase__shipping .market-place-settings-content__actions {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 12px;
        background-color: #ffffff; } }
  @media (min-width: 769px) {
    .mp-modal-purchase__shipping .common-input-wraper input {
      padding: 19px 16px;
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 20px; } }
  .mp-modal-purchase__shipping .form-settings-shipping .state,
  .mp-modal-purchase__shipping .form-settings-shipping .zip {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px; }
  .mp-modal-purchase__shipping .StripeElement {
    padding: 19px 16px;
    height: auto;
    min-height: 0;
    font-size: 20px; }

/* First, we designate styles for IE8 and older versions. Here we slightly ennoble the standard checkbox. */
.klickly-checkbox {
  vertical-align: top;
  margin: 0 3px 0 4px;
  width: 16px;
  height: 16px; }

/* This is for all browsers, except for very old ones,
which do not support selectors with a plus. We show that the label is clickable. */
.klickly-checkbox + .klickly-checkbox__label {
  cursor: pointer; }

/* Next comes the design of the checkbox in modern browsers,
as well as IE9 and above. Due to the fact that older browsers do not support selectors: not and: checked,
in them all the following styles will not work. */
/* Hide the original checkbox. */
.klickly-checkbox:not(checked) {
  position: absolute;
  opacity: 0; }

.klickly-checkbox:not(checked) + .klickly-checkbox__label {
  position: relative;
  /* We will position the pseudo-checkbox relative to the label */
  display: inline-block;
  padding-left: 25px;
  /* Leave the place on the left of the label for the pseudo-checkbox */
  line-height: 18px;
  color: #9b9b9b;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 12px;
  text-align: left; }

/* The decor of the checkbox in the off state (background). */
.klickly-checkbox:not(checked) + .klickly-checkbox__label:before {
  content: '';
  position: absolute;
  top: -6px;
  left: 5px;
  width: 16px;
  height: 16px;
  z-index: 1; }

/* The decor of the checkbox is off (switch). */
.klickly-checkbox:not(checked) + .klickly-checkbox__label:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-image: linear-gradient(to top, #dfe0e4, #ffffff);
  border: solid 1px #9b9b9b; }

/* Change the background of the checkbox when it's on. */
.klickly-checkbox:checked + .klickly-checkbox__label:before {
  width: 8px;
  height: 4px;
  top: 5px;
  left: 6px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg); }

.klickly-checkbox:checked + .klickly-checkbox__label:after {
  content: ' ';
  position: absolute;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-image: linear-gradient(to top, #87b576, #87b576);
  border: solid 1px #87b576; }

.klickly-checkbox-disabled:checked + .klickly-checkbox__label:after {
  background-image: linear-gradient(to top, #e7e7e7, #e7e7e7);
  border: solid 1px #e7e7e7; }

.billing-form .is-equal-with-shipping__checkbox {
  padding-bottom: 15px; }
  .billing-form .is-equal-with-shipping__checkbox .klickly-checkbox__label {
    font-family: proxima_nova_light, Helvetica, sans-serif;
    font-size: 14px;
    color: #4a4a4a; }

.billing-form .accordion {
  margin: 0 0 15px 0; }
  .billing-form .accordion .accordion-default-item {
    display: block; }
    .billing-form .accordion .accordion-default-item:hover {
      cursor: auto; }
    .billing-form .accordion .accordion-default-item .card-item-logo .radio-input {
      display: none; }
    .billing-form .accordion .accordion-default-item .card-item--change-address:hover {
      cursor: auto; }
    .billing-form .accordion .accordion-default-item .card-item--change-address .card-item__info {
      width: 100%; }
    .billing-form .accordion .accordion-default-item .card-item--change-address .card-item__address {
      display: none; }

.billing-form .card-element__wrapper {
  font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
  position: relative; }
  .billing-form .card-element__wrapper .error-message {
    min-height: 10px;
    font-size: 10px;
    text-align: right;
    line-height: 1;
    color: #f64316; }

.form-logos {
  text-align: right;
  padding-bottom: 20px; }
  .form-logos__icon {
    width: 50px;
    height: 30px;
    margin-left: 2px; }

.mp-modal-purchase__billing {
  flex-grow: 1;
  width: 100%; }
  .mp-modal-purchase__billing .billing-form {
    height: 100%; }
    @media (max-width: 667px) {
      .mp-modal-purchase__billing .billing-form {
        padding: 0; } }
  .mp-modal-purchase__billing .billing-form form {
    height: 100%;
    position: relative; }
  @media (max-width: 480px) {
    .mp-modal-purchase__billing .billing-form .card-item__address {
      padding-left: 50px; } }
  .mp-modal-purchase__billing .billing-form .market-place-settings-content__actions {
    position: absolute;
    bottom: 0;
    right: 0; }
    @media (max-width: 667px) {
      .mp-modal-purchase__billing .billing-form .market-place-settings-content__actions {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 12px;
        background: #ffffff; } }
  .mp-modal-purchase__billing .billing-form--opened-address-form .market-place-settings-content__actions {
    position: static; }
  @media (min-width: 769px) {
    .mp-modal-purchase__billing .common-input-wraper input {
      padding: 19px 16px;
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 20px; } }
  .mp-modal-purchase__billing .form-settings-shipping .state,
  .mp-modal-purchase__billing .form-settings-shipping .zip {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px; }
  @media (min-width: 768px) {
    .mp-modal-purchase__billing .StripeElement {
      padding: 19px 16px;
      height: auto;
      min-height: 0;
      font-size: 20px; } }
  .mp-modal-purchase__billing .accordion .accordion-default-item {
    pointer-events: none; }
  .mp-modal-purchase__billing .billing-form-logos {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 768px) {
      .mp-modal-purchase__billing .billing-form-logos {
        flex-direction: column;
        align-items: flex-start; } }
  .mp-modal-purchase__billing .form-logos {
    width: auto; }
  .mp-modal-purchase__billing .billing-form-logos__text,
  .mp-modal-purchase__billing .billing-form__label {
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 15px;
    color: #4a4a4a; }
  .mp-modal-purchase__billing .billing-form__label {
    margin-top: 40px; }
    @media (max-width: 480px) {
      .mp-modal-purchase__billing .billing-form__label {
        margin-top: 20px; } }
  .mp-modal-purchase__billing .billing-form__trust-badge-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: auto;
    padding-bottom: 10px; }
    @media (max-width: 1130px) {
      .mp-modal-purchase__billing .billing-form__trust-badge-box {
        padding-bottom: 80px; } }
    @media (max-width: 667px) and (min-width: 481px) {
      .mp-modal-purchase__billing .billing-form__trust-badge-box {
        margin-top: 40px; } }
    @media (max-width: 480px) {
      .mp-modal-purchase__billing .billing-form__trust-badge-box {
        margin-top: 20px; } }
  .mp-modal-purchase__billing .billing-form__trust-badge:not(:first-child) {
    margin-left: 20px; }
  .mp-modal-purchase__billing .billing-form__trust-badge.norton {
    max-width: 90px; }
  .mp-modal-purchase__billing .billing-form__trust-badge.truste {
    max-width: 130px; }
  .mp-modal-purchase__billing .billing-form__trust-badge.mcafee {
    max-width: 110px; }
  @media (max-width: 667px) {
    .mp-modal-purchase__billing .billing-form__trust-badge:not(:first-child) {
      margin-left: 10px; }
    .mp-modal-purchase__billing .billing-form__trust-badge.norton {
      max-width: 80px; }
    .mp-modal-purchase__billing .billing-form__trust-badge.truste {
      max-width: 110px; }
    .mp-modal-purchase__billing .billing-form__trust-badge.mcafee {
      max-width: 90px; } }

.accordion {
  width: 100%;
  max-width: 568px;
  margin-top: 45px; }
  .accordion .accordion-address {
    position: relative;
    padding: 10px 19px;
    font-size: 18px;
    color: #4a4a4a; }
    @media (max-width: 667px) {
      .accordion .accordion-address {
        padding: 12px 14px;
        font-size: 12px; } }
    .accordion .accordion-address:hover {
      cursor: pointer; }
    .accordion .accordion-address .shipping-tab__name {
      margin-bottom: 5px;
      font-family: proxima_nova_bold, Helvetica, sans-serif; }
    .accordion .accordion-address .shipping-tab__address {
      font-family: proxima_nova_regular, Helvetica, sans-serif; }
    .accordion .accordion-address.default:after {
      position: absolute;
      display: block;
      content: '';
      width: 5px;
      height: 8px;
      top: 50%;
      right: 12px;
      border-right: 2px solid #0baff0;
      border-bottom: 2px solid #0baff0;
      border-radius: .5px;
      transform: translateY(-50%) rotate(45deg); }
    .accordion .accordion-address .radio-input {
      display: none; }
    @media (max-width: 480px) {
      .accordion .accordion-address {
        display: flex;
        padding: 20px 14px;
        border-bottom: 1px solid #dfe3e9; }
        .accordion .accordion-address .radio-input {
          display: inline-block; } }
    @media (max-width: 480px) {
      .accordion .accordion-address.empty-state .shipping-tab__name {
        margin-bottom: 0;
        margin-right: 10px; } }
  .accordion .card-head {
    display: flex;
    justify-content: space-between;
    font-family: proxima_nova_light, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.17;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 10px; }
    .accordion .card-head > span:last-of-type {
      display: inline-block;
      width: 30%; }
    @media (max-width: 480px) {
      .accordion .card-head {
        display: none; } }
  .accordion .headline {
    display: flex;
    justify-content: space-between;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 12px; }
    .accordion .headline .edit-button {
      font-size: 14px;
      font-weight: 600;
      color: #0baff0;
      padding-left: 10px; }
      .accordion .headline .edit-button.remove {
        opacity: .5; }
      .accordion .headline .edit-button:hover {
        cursor: pointer; }
    @media (max-width: 480px) {
      .accordion .headline {
        background: #f6f7f8;
        border-top: solid 1px #c7c7c7;
        line-height: 49px;
        padding: 0 15px;
        font-size: 14px;
        margin-bottom: 0; } }
  .accordion .accordion-default-item {
    position: relative;
    background: #fafafc;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    text-align: left;
    color: #4a4a4a;
    border-radius: 2px;
    border: solid 1px #c7c7c7; }
    .accordion .accordion-default-item:hover {
      cursor: pointer; }
    @media (max-width: 480px) {
      .accordion .accordion-default-item {
        display: none; } }
  .accordion .accordion-arrow {
    position: absolute;
    width: 5px;
    height: 5px;
    border-right: 2px solid #a8aab7;
    border-bottom: 2px solid #a8aab7;
    border-radius: .5px;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    transition: .5s; }
    .accordion .accordion-arrow.opened {
      transform: translateY(-50%) rotate(-135deg); }
  .accordion .accordion-items-list {
    position: relative;
    overflow: hidden;
    height: 0; }
    .accordion .accordion-items-list.list-opened {
      height: auto;
      border: solid 1px #c7c7c7;
      border-top: none; }
      @media (max-width: 480px) {
        .accordion .accordion-items-list.list-opened {
          border: none; } }
    .accordion .accordion-items-list > span {
      display: inline-block;
      font-size: 14px;
      color: #0baff0;
      padding: 10px 14px 14px;
      font-family: proxima_nova_semibold, Helvetica, sans-serif; }
      .accordion .accordion-items-list > span:hover {
        cursor: pointer; }
    @media (max-width: 480px) {
      .accordion .accordion-items-list {
        height: auto; }
        .accordion .accordion-items-list > div:after {
          position: absolute;
          display: block;
          content: '';
          width: 12px !important;
          height: 12px !important;
          top: 50%;
          right: 12px;
          border-right: 2px solid #c7c7c7 !important;
          border-bottom: 2px solid #c7c7c7 !important;
          border-radius: .5px;
          transform: translateY(-50%) rotate(-45deg) !important; } }
  @media (max-width: 480px) {
    .accordion {
      margin-top: 0; } }

.with-loader:after {
  position: absolute;
  content: '';
  right: 50%;
  top: 50%;
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
          animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0; }

.with-loader:before {
  position: absolute;
  content: '';
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #ffffff;
  opacity: .5; }

.empty-state {
  background: #fafafc;
  border-radius: 2px;
  border: solid 1px #c7c7c7; }

.card-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 1px;
  padding: 12px 14px;
  font-size: 14px;
  font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  @media (max-width: 480px) {
    .card-item {
      flex-direction: column; } }
  .card-item:hover {
    cursor: pointer; }
  .card-item.default:after {
    position: absolute;
    display: block;
    content: '';
    width: 5px;
    height: 8px;
    top: 50%;
    right: 12px;
    border-right: 2px solid #0baff0;
    border-bottom: 2px solid #0baff0;
    border-radius: .5px;
    transform: translateY(-50%) rotate(45deg); }
    @media (max-width: 480px) {
      .card-item.default:after {
        display: none; } }
  .card-item-logo {
    width: 60%;
    display: flex;
    align-items: center; }
    .card-item-logo img {
      display: inline-block;
      margin-right: 12px;
      width: 37px;
      height: 22px; }
    .card-item-logo .radio-input {
      display: none; }
      @media (max-width: 480px) {
        .card-item-logo .radio-input {
          display: inline-block;
          margin-right: 5px; } }
  .card-item .expires {
    width: 40%;
    text-align: center; }
    @media (max-width: 480px) {
      .card-item .expires {
        height: 14px; } }
  .card-item__info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 50%; }
    @media (max-width: 480px) {
      .card-item__info {
        width: 100%; } }
  .card-item__address {
    position: relative;
    left: -13px;
    width: calc(50% - 13px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    @media (max-width: 480px) {
      .card-item__address {
        left: 5px;
        top: 5px;
        width: calc(100% - 44px); } }

.card-form-wrapper form {
  display: flex;
  flex-direction: column; }

.card-form-wrapper input::-webkit-input-placeholder {
  opacity: 1;
  font-weight: 100;
  color: #bbbbc2; }

.card-form-wrapper input::-ms-input-placeholder {
  opacity: 1;
  font-weight: 100;
  color: #bbbbc2; }

.card-form-wrapper input::placeholder {
  opacity: 1;
  font-weight: 100;
  color: #bbbbc2; }

.card-form-wrapper .card-element__wrapper {
  min-height: 58px;
  padding-bottom: 15px; }

.card-form-wrapper #stripe-iframe-wrapper.StripeElement--invalid {
  background-color: #fbe8ea;
  border: solid 1px #d0011b;
  color: #d0011b; }

.card-form-wrapper #stripe-iframe-wrapper.StripeElement--complete + .block-errors .block-errors__text {
  visibility: hidden; }

.card-form-wrapper .block-errors__text {
  bottom: -8px; }

@media (max-width: 480px) {
  .card-form-wrapper {
    padding: 15px; }
    .card-form-wrapper .market-place-settings-content__actions {
      margin-top: 15px; }
      .card-form-wrapper .market-place-settings-content__actions .common-button {
        margin: 5px; } }

.mp-modal-purchase__confirmation {
  width: 100%; }
  .mp-modal-purchase__confirmation .common-button.dark-pink {
    width: 100%;
    margin: 40px 0 8px;
    padding: 19px 16px;
    font-family: proxima_nova_light, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 23px; }
    @media (max-width: 667px) {
      .mp-modal-purchase__confirmation .common-button.dark-pink {
        margin: 0;
        padding: 12px 0;
        font-size: 17px; } }
  @media (max-width: 667px) {
    .mp-modal-purchase__confirmation .wrapper-for-button {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 12px;
      background: #ffffff;
      z-index: 1; } }
  .mp-modal-purchase__confirmation.not-authenticated .accordion .accordion-default-item {
    pointer-events: none; }
  .mp-modal-purchase__confirmation.not-authenticated .accordion .accordion-default-item .accordion-arrow {
    display: none; }

.purchase-complete {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0 130px;
  width: 100%; }
  @media (max-width: 768px) {
    .purchase-complete {
      flex-direction: column; } }
  @media (max-width: 768px) and (min-width: 481px) {
    .purchase-complete {
      width: 80%;
      margin: 0 auto;
      padding-top: 80px;
      padding-bottom: 90px; } }
  @media (max-width: 667px) {
    .purchase-complete {
      padding-top: 40px;
      padding-bottom: 70px; } }
  .purchase-complete__message {
    width: 50%;
    max-width: 420px; }
  .purchase-complete__message--widget {
    display: flex;
    width: 100%;
    max-width: 470px; }
  .purchase-complete__message--widget .purchase-complete__title {
    font-size: 18px;
    line-height: 1;
    text-align: left; }
  .purchase-complete__message--widget .purchase-complete__img {
    width: 86px;
    height: 86px; }
  .purchase-complete__message--widget > div {
    margin-left: 14px; }
  .purchase-complete__text {
    margin-top: 4px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 15px;
    line-height: normal;
    color: #9b9b9b; }
  .purchase-complete__button {
    margin-top: 30px;
    width: 100%;
    max-width: 310px;
    padding: 10px 0;
    font-size: 16px;
    line-height: 20px; }
  .purchase-complete__img {
    max-width: 142px;
    max-height: 142px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block; }
    @media (max-width: 960px) {
      .purchase-complete__img {
        max-width: 120px;
        max-height: 120px; } }
    @media (max-width: 667px) {
      .purchase-complete__img {
        max-width: 75px;
        max-height: 75px;
        margin-bottom: 15px; } }
  .purchase-complete__title {
    margin-top: 20px;
    text-align: center;
    color: #4a4a4a;
    font-size: 30px;
    line-height: 1.38;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    @media (max-width: 960px) and (min-width: 668px) {
      .purchase-complete__title {
        font-size: 24px; } }
    @media (max-width: 667px) {
      .purchase-complete__title {
        font-size: 18px; } }
  .purchase-complete__divider {
    height: 100%;
    width: 100%;
    max-width: 510px;
    background-color: #e2e2e2;
    margin: 30px 0;
    margin-right: auto;
    min-height: 2px; }
    @media (max-width: 768px) {
      .purchase-complete__divider {
        min-height: 2px;
        width: 100%;
        max-width: 60%;
        margin-top: 25px;
        margin-bottom: 35px; } }
    @media (max-width: 480px) {
      .purchase-complete__divider {
        max-width: 100%; } }
  .purchase-complete-form {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 510px;
    margin-top: 0;
    text-align: left; }
    @media (max-width: 768px) {
      .purchase-complete-form {
        width: 100%; } }
  .purchase-complete-form__title {
    margin-top: 8px;
    margin-bottom: 20px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a; }
  .purchase-complete-form__link {
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.62;
    letter-spacing: normal;
    cursor: pointer;
    color: #0baff0; }
  .purchase-complete .purchase-complete-form .common-input-wraper input {
    padding: 9px 12px;
    font-size: 16px; }
    @media (max-width: 667px) {
      .purchase-complete .purchase-complete-form .common-input-wraper input {
        font-size: 14px; } }
  .purchase-complete-form .common-button {
    width: 100%;
    padding: 9px 0;
    margin: 25px 0 8px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 23px; }
    .purchase-complete-form .common-button:disabled {
      background: #e2e2e2;
      color: #4a4a4a; }
    @media (max-width: 667px) {
      .purchase-complete-form .common-button {
        font-size: 14px; } }

.purchase-complete__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: auto; }

.purchase-complete__item {
  padding: 17px 0 20px;
  border-top: 1px solid #c3c3c3; }
  .purchase-complete__item--summary .purchase-information__text {
    font-style: italic; }
  @media (min-width: 668px) {
    .purchase-complete__item {
      padding: 0;
      border-top: none; } }

.purchase-complete__heading {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 13px;
  letter-spacing: 1.08px;
  line-height: 1;
  text-transform: uppercase;
  color: #4a4a4a; }
  @media (min-width: 668px) {
    .purchase-complete__heading {
      display: none; } }

.purchase-thanks {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 510px;
  margin: 0 auto;
  padding-bottom: 14px; }
  @media (max-width: 667px) {
    .purchase-thanks {
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;
      text-align: center; } }
  .purchase-thanks__content {
    padding-top: 24px;
    padding-left: 17px; }
    @media (max-width: 667px) {
      .purchase-thanks__content {
        padding-top: 15px;
        padding-left: 0; } }
  .purchase-thanks__heading {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1;
    color: #4a4a4a; }
  .purchase-thanks__text {
    padding: 5px 0 24px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.38;
    color: #9b9b9b; }
    @media (max-width: 667px) {
      .purchase-thanks__text {
        padding-left: 25px;
        padding-right: 25px; } }
  .purchase-thanks__link {
    color: #0baff0;
    text-decoration: none; }
  .purchase-thanks__action {
    height: 44px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 17px;
    width: 100%;
    max-width: 344px;
    color: #ffffff;
    border-radius: 2px;
    background-color: #da1781; }
    @media (max-width: 667px) {
      .purchase-thanks__action {
        max-width: 276px; } }

.purchase-info {
  padding-top: 20px;
  width: 100%; }
  .purchase-info__heading {
    padding-bottom: 7px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.29;
    color: black; }
  .purchase-info__text {
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    color: #4a4a4a; }
    .purchase-info__text.bold {
      font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    @media (max-width: 667px) {
      .purchase-info__text {
        font-size: 12px; } }
  .purchase-info__item {
    padding-top: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 300px;
    flex-grow: 1; }
    .purchase-info__item--large {
      max-width: 100%; }
    @media (max-width: 667px) {
      .purchase-info__item--large .purchase-information__text {
        display: inline;
        font-size: 14px; } }
    @media (max-width: 667px) {
      .purchase-info__item {
        max-width: 100%; } }
  .purchase-info__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%; }
  .purchase-info__link {
    color: #0baff0;
    text-decoration: none; }
  .purchase-info__list {
    padding-left: 8px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 20px; }

.purchase-summary__row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center; }
  .purchase-summary__row.total {
    margin-top: 5px;
    padding: 7px 0 12px;
    border-top: 1px solid #dfe2e5; }
  .purchase-summary__row.total .purchase-summary__text {
    font-size: 16px;
    line-height: 1.13; }
  .purchase-summary__row.total .purchase-summary__text:first-child {
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }

.purchase-summary__text {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: #4a4a4a; }

.purchase-product {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 18px 0 12px; }
  .purchase-product__img-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    border: 1px solid #c6c6c6; }
  .purchase-product__img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover; }
  .purchase-product__info {
    padding-left: 10px;
    flex-grow: 1; }
  .purchase-product__title {
    max-width: 113px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1;
    color: #4a4a4a; }
  .purchase-product__price {
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1;
    color: #4a4a4a; }
  .purchase-product__brand {
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 2px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 14px;
    color: #9b9b9b; }
  .purchase-product__option-box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 7px; }
  .purchase-product__option {
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 10px;
    line-height: 1.2;
    color: #4a4a4a; }

.mp-modal {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 1320px;
  width: 100%;
  margin: 50px auto;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
  .mp-modal.full-sized {
    width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    height: auto;
    margin: 0;
    border-radius: 0; }
    @media (min-width: 668px) {
      .mp-modal.full-sized {
        justify-content: center;
        height: 100vh;
        max-height: 100vh; } }
  .mp-modal.full-sized .mp-modal-product {
    align-items: center;
    min-height: 0; }
  .mp-modal.full-sized .mp-modal-product__carousel {
    min-height: 0; }
  .mp-modal.full-sized .mp-modal-purchase {
    min-height: inherit; }
  .mp-modal.full-sized .mp-modal__back {
    display: none; }
  @media (max-width: 960px) {
    .mp-modal {
      height: auto;
      min-height: 0; } }
  @media (max-width: 768px) and (min-width: 668px) {
    .mp-modal {
      width: 96%; } }
  @media (max-width: 667px) {
    .mp-modal {
      display: flex;
      width: 100%;
      top: 0;
      margin: 0;
      z-index: 8;
      overflow-y: auto;
      border-radius: 0;
      box-shadow: none; } }
  .mp-modal__back {
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    top: 30px;
    left: 30px;
    background: url(/static/media/back.64173da3.svg) 0 0 no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: 1; }
    @media (max-width: 768px) and (min-width: 668px) {
      .mp-modal__back {
        left: 20px;
        top: 20px; } }
    @media (max-width: 667px) {
      .mp-modal__back {
        top: 12px;
        left: 12px;
        height: 16px;
        width: 16px; } }
  .mp-modal__close {
    position: absolute;
    top: 27px;
    right: 27px;
    width: 26px;
    height: 26px;
    background: url(/static/media/cancel.138bf265.svg) 0 0 no-repeat;
    cursor: pointer;
    background-size: cover; }
    @media (max-width: 768px) and (min-width: 668px) {
      .mp-modal__close {
        top: 20px; } }
    @media (max-width: 667px) {
      .mp-modal__close {
        display: none; } }
  .mp-modal-product {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    min-height: 534px;
    padding: 60px 50px 40px; }
    @media (max-width: 768px) and (min-width: 668px) {
      .mp-modal-product {
        padding-left: 20px;
        min-height: 430px; } }
    @media (max-width: 667px) {
      .mp-modal-product {
        flex-direction: column;
        min-height: 0; } }
    @media (max-width: 667px) and (min-width: 481px) {
      .mp-modal-product {
        padding: 12px 24px 40px; } }
    @media (max-width: 480px) {
      .mp-modal-product {
        padding-top: 10px;
        padding-left: 12px;
        padding-right: 12px; } }
  .mp-modal-product__carousel {
    width: 66.3%;
    min-height: 100%; }
    @media (max-width: 667px) {
      .mp-modal-product__carousel {
        width: 100%;
        min-height: 0; } }
  .mp-modal-product__info {
    width: 33.7%;
    min-height: inherit; }
    @media (max-width: 667px) {
      .mp-modal-product__info {
        width: 100%; } }
  .mp-modal__footer {
    width: 100%; }
  .mp-modal__more-wrapper {
    display: block;
    padding: 0 12px 12px;
    text-align: right;
    background-color: #ebf1f6; }
    @media (min-width: 668px) {
      .mp-modal__more-wrapper {
        display: none; } }
  .mp-modal__more {
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 14px;
    line-height: normal;
    color: #0baff0;
    cursor: pointer; }
  .mp-modal-purchase {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%; }
    @media (max-width: 667px) {
      .mp-modal-purchase {
        flex-direction: column; } }
  .mp-modal-purchase__steps {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 100px 60px 40px 30px;
    overflow-x: hidden; }
    @media (max-width: 1024px) and (min-width: 769px) {
      .mp-modal-purchase__steps {
        padding-right: 30px; } }
    @media (max-width: 768px) and (min-width: 668px) {
      .mp-modal-purchase__steps {
        padding-left: 20px;
        padding-right: 20px; } }
    @media (max-width: 667px) {
      .mp-modal-purchase__steps {
        width: 100%;
        padding: 20px 10px 80px; } }
  .mp-modal-purchase__step-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px; }
    @media (max-width: 667px) {
      .mp-modal-purchase__step-list {
        justify-content: center; } }
  .mp-modal-purchase__step {
    position: relative;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 18px;
    line-height: normal;
    letter-spacing: .7px;
    color: #c6c6c6;
    text-transform: uppercase;
    cursor: pointer; }
    @media (max-width: 667px) {
      .mp-modal-purchase__step:first-of-type {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        width: 35px;
        white-space: nowrap;
        overflow: hidden; } }
    .mp-modal-purchase__step:not(:first-child) {
      padding-left: 23px; }
      @media (max-width: 768px) and (min-width: 668px) {
        .mp-modal-purchase__step:not(:first-child) {
          padding-left: 16px; } }
    .mp-modal-purchase__step:not(:first-child):after {
      position: absolute;
      content: '';
      top: 50%;
      left: 6px;
      width: 8px;
      height: 8px;
      border-right: 2px solid #c6c6c6;
      border-bottom: 2px solid #c6c6c6;
      transform: translateY(calc(-50% - 1px)) rotate(-45deg); }
      @media (max-width: 768px) and (min-width: 668px) {
        .mp-modal-purchase__step:not(:first-child):after {
          left: 2px; } }
    @media (max-width: 850px) {
      .mp-modal-purchase__step {
        font-size: 14px; } }
    @media (max-width: 768px) and (min-width: 668px) {
      .mp-modal-purchase__step {
        letter-spacing: 0; } }
  .mp-modal-purchase__step.is-active {
    color: #da1781; }
  .mp-modal__special-link-text {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #9b9b9b;
    font-family: proxima_nova_light, Helvetica, sans-serif; }
    @media (max-width: 667px) {
      .mp-modal__special-link-text {
        font-size: 10px;
        max-width: 60%;
        text-align: center;
        margin: 10px auto; } }
  .mp-modal__special-link {
    color: #0baff0;
    text-decoration: none; }
  @media (max-width: 768px) and (min-width: 668px) {
    .mp-modal-purchase__billing .accordion .card-item,
    .mp-modal-purchase__billing .user-email > div,
    .mp-modal-purchase__confirmation .accordion .card-item,
    .mp-modal-purchase__confirmation .user-email > div,
    .mp-modal .shipping-tab__label {
      font-size: 16px; } }
  @media (max-width: 480px) {
    .mp-modal-purchase__billing .accordion .card-item,
    .mp-modal-purchase__billing .user-email > div,
    .mp-modal-purchase__confirmation .accordion .card-item,
    .mp-modal-purchase__confirmation .user-email > div,
    .mp-modal .shipping-tab__label {
      font-size: 14px; } }
  .mp-modal-purchase__confirmation .user-email,
  .mp-modal-purchase__billing .user-email {
    text-align: right;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .mp-modal-purchase__confirmation .user-email > div,
  .mp-modal-purchase__billing .user-email > div {
    height: 62px;
    line-height: 62px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 20px;
    text-align: left;
    border-radius: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid #c7c7c7;
    background-color: #fafafa; }
    @media (max-width: 667px) {
      .mp-modal-purchase__confirmation .user-email > div,
      .mp-modal-purchase__billing .user-email > div {
        height: 44px;
        line-height: 44px;
        padding-left: 10px;
        font-size: 14px; } }
  .mp-modal-purchase__confirmation .user-email > span,
  .mp-modal-purchase__billing .user-email > span {
    font-size: 12px;
    color: #0baff0;
    line-height: 2;
    cursor: pointer; }
  .mp-modal-purchase__confirmation .card-item,
  .mp-modal-purchase__billing .card-item {
    padding-top: 19px;
    padding-bottom: 19px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 20px;
    color: #4a4a4a;
    letter-spacing: 0; }
  @media (max-width: 1185px) {
    .mp-modal .card-item {
      flex-direction: column; } }
  @media (max-width: 768px) {
    .mp-modal .card-item {
      padding: 12px 14px; } }
  @media (max-width: 667px) and (min-width: 481px) {
    .mp-modal .card-item {
      flex-direction: row; } }
  @media (max-width: 667px) {
    .mp-modal .card-item {
      font-size: 14px; } }
  @media (max-width: 1185px) {
    .mp-modal .card-item__info {
      justify-content: flex-start; } }
  @media (max-width: 1185px) {
    .mp-modal .card-item__info,
    .mp-modal .card-item__address {
      width: 100%; } }
  @media (max-width: 1185px) {
    .mp-modal .card-item-logo {
      width: auto; } }
  @media (max-width: 1185px) {
    .mp-modal .card-item .expires {
      width: auto;
      margin-left: 51px; } }
  @media (max-width: 667px) and (min-width: 481px) {
    .mp-modal .card-item .expires {
      margin-left: 22px; } }
  @media (max-width: 1185px) {
    .mp-modal .card-item__address {
      margin-top: 8px;
      position: static;
      padding-left: 50px; } }
  @media (max-width: 667px) and (min-width: 481px) {
    .mp-modal .card-item__address {
      margin-top: 0;
      padding-left: 15px; } }
  @media (max-width: 480px) {
    .mp-modal .card-item__address {
      padding-left: 75px;
      margin-top: 6px; } }
  .mp-modal .accordion {
    max-width: 100%;
    margin-top: 10px; }
  .mp-modal .accordion .headline {
    margin-bottom: 5px; }
    @media (max-width: 480px) {
      .mp-modal .accordion .headline {
        line-height: normal; } }
  .mp-modal .accordion .headline__title {
    display: none; }
  .mp-modal .accordion-default-item {
    display: block; }
  .mp-modal .accordion .headline {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    background: #fff;
    border: none; }
  .mp-modal .accordion .edit-button {
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
    @media (max-width: 667px) {
      .mp-modal .accordion .edit-button {
        font-size: 12px; } }
  .mp-modal .accordion-items-list {
    height: 0; }
  @media (max-width: 667px) {
    .mp-modal .accordion-address {
      padding: 10px 25px;
      font-size: 12px; } }
  .mp-modal .market-place-settings-content__actions .common-button {
    width: 100%;
    padding: 19px 55px;
    height: auto;
    font-size: 22px;
    line-height: 23px; }
    @media (max-width: 667px) {
      .mp-modal .market-place-settings-content__actions .common-button {
        margin: 0;
        padding: 13px 55px;
        font-size: 14px; } }
  .mp-modal .common-input-wraper input {
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
    @media (max-width: 667px) {
      .mp-modal .common-input-wraper input {
        padding: 13px 10px;
        font-size: 14px; } }
  .mp-modal .security-logos {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 5px; }
  .mp-modal .security-logos__icon {
    max-width: 50px;
    max-height: 30px;
    width: auto;
    height: auto;
    margin-left: 5px; }

@media (max-width: 667px) {
  .open-modal {
    overflow: hidden; } }

@media (max-width: 667px) {
  .overlay.overlay-y {
    z-index: 8;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .overlay.overlay-y.step-productInfo, .overlay.overlay-y.step-loggedInAs, .overlay.overlay-y.step-customerInfo, .overlay.overlay-y.step-shipping, .overlay.overlay-y.step-billing, .overlay.overlay-y.step-confirmation {
      padding: 0;
      background: #ffffff; }
    .overlay.overlay-y.overlay-auth {
      z-index: 7; } }

#favorites-discount-tooltip {
  border: 1px solid #da1781;
  background: #ffffff;
  color: #da1781;
  padding: 15px;
  opacity: 1;
  max-width: 235px;
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  font-size: 16px; }
  #favorites-discount-tooltip.show {
    margin-top: 10px !important; }
  #favorites-discount-tooltip:after, #favorites-discount-tooltip:before {
    border-color: transparent; }

.marketplace-link {
  display: flex;
  align-items: center;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 11px;
  font-style: italic;
  letter-spacing: -.2px;
  line-height: 1;
  color: #313d4f;
  text-decoration: none; }
  .marketplace-link-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0 18px 2px;
    z-index: 9; }
  .marketplace-link__logo {
    margin-left: 3px;
    width: 39px;
    height: 15px; }

.mp-profile {
  display: flex;
  flex-direction: column;
  max-width: 568px;
  width: 100%; }
  .mp-profile-form {
    max-width: 100%;
    margin: 0;
    margin-top: 40px; }
    @media (max-width: 480px) {
      .mp-profile-form {
        padding: 0 15px;
        margin-top: 0; }
        .mp-profile-form .header-btn {
          position: absolute;
          top: 20px;
          margin-top: 0;
          padding: 0 !important;
          background: transparent;
          right: 12px;
          color: #9b9b9b;
          font-size: 17px;
          font-family: proxima_nova_regular, Helvetica, sans-serif; }
        .mp-profile-form .common-button.transparent {
          display: none; } }
    .mp-profile-form__title {
      font-family: proxima_nova_semibold, Helvetica, sans-serif;
      font-size: 17px;
      color: #4a4a4a;
      margin-bottom: 20px; }
      @media (max-width: 480px) {
        .mp-profile-form__title {
          display: none; } }
    .mp-profile-form form {
      width: 100%; }
    .mp-profile-form .common-input-wraper, .mp-profile-form .form-control {
      margin-top: 25px; }
      .mp-profile-form .common-input-wraper label, .mp-profile-form .form-control label {
        display: inline-block;
        font-family: proxima_nova_light, Helvetica, sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.17;
        color: #9b9b9b;
        margin-bottom: 14px;
        text-transform: uppercase; }
  .mp-profile-coins {
    margin-left: 20px; }
  .mp-profile-coins__value-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center; }
  .mp-profile-coins__value {
    margin-left: 10px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 34px;
    line-height: normal;
    color: #da1781; }
  .mp-profile-coins__icon {
    width: 32px;
    height: 31px; }
  .mp-profile-coins__text {
    margin-top: 8px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 22px;
    line-height: 1;
    color: #4a4a4a; }

.profile-photo {
  position: relative;
  display: inline-block;
  height: 122px;
  width: 122px; }
  .profile-photo-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center; }
  .profile-photo input[name=file] {
    display: none; }
  .profile-photo:hover {
    cursor: pointer; }
  .profile-photo.no-photo:hover:before {
    position: absolute;
    content: '+';
    cursor: pointer;
    left: 2px;
    top: 2px;
    font-size: 40px;
    line-height: 116px;
    height: 116px;
    width: 116px;
    border-radius: 999rem;
    background: #9b9b9b;
    color: #ffffff; }
  @media (max-width: 480px) {
    .profile-photo {
      margin-left: 20px; } }

.cropper-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0; }
  .cropper-modal-overlay .cropper-block {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 780px;
    width: 100%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 5px; }
    .cropper-modal-overlay .cropper-block .title {
      line-height: 44px;
      padding-left: 24px;
      font-size: 17px;
      text-align: left;
      color: #4a4a4a;
      font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    .cropper-modal-overlay .cropper-block .cropper-no-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 320px;
      height: 320px;
      border-radius: 50%;
      color: #da1781;
      font-size: 24px;
      font-family: proxima_nova_semibold, Helvetica, sans-serif;
      text-align: center;
      cursor: pointer;
      background: #e9e9e9;
      margin: 0 auto; }
  .cropper-modal-overlay .cropper-content {
    position: relative;
    padding: 28px 0 12px;
    background: #d9d9d9; }
  .cropper-modal-overlay .cropper-controls {
    position: relative;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .cropper-modal-overlay .cropper-controls .cropper-action-btns {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%); }
      .cropper-modal-overlay .cropper-controls .cropper-action-btns .common-button {
        width: 93px;
        height: 32px;
        padding: 0; }
        .cropper-modal-overlay .cropper-controls .cropper-action-btns .common-button.transparent {
          border: none; }
    @media (max-width: 480px) {
      .cropper-modal-overlay .cropper-controls {
        display: block; }
        .cropper-modal-overlay .cropper-controls .cropper-action-btns {
          position: relative;
          top: 0;
          right: 0;
          transform: translateY(0);
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: center; } }
  .cropper-modal-overlay .zoom-input {
    display: flex;
    justify-content: center;
    align-items: center; }
    .cropper-modal-overlay .zoom-input .zoom-icon {
      display: inline-block;
      background: url(/static/media/img-landscape.00494f91.svg) 0 0 no-repeat;
      background-size: cover; }
      .cropper-modal-overlay .zoom-input .zoom-icon.mini {
        width: 32px;
        height: 14px; }
      .cropper-modal-overlay .zoom-input .zoom-icon.big {
        width: 44px;
        height: 21px; }
    .cropper-modal-overlay .zoom-input input[type=range] {
      -webkit-appearance: none;
      width: 100%;
      margin: 7.65px 20px; }
    .cropper-modal-overlay .zoom-input input[type=range]:focus {
      outline: none; }
    .cropper-modal-overlay .zoom-input input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 1.7px;
      cursor: pointer;
      box-shadow: 0px 0px 0.5px #201c16, 0px 0px 0px #2f2920;
      background: rgba(0, 16, 87, 0.5);
      border-radius: 0.4px;
      border: 0.4px solid rgba(1, 1, 1, 0.68); }
    .cropper-modal-overlay .zoom-input input[type=range]::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px rgba(52, 100, 165, 0.7), 0px 0px 0px rgba(58, 112, 184, 0.7);
      border: 0.9px solid rgba(94, 60, 180, 0.6);
      height: 17px;
      width: 17px;
      border-radius: 47px;
      background: #ffffff;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8.05px; }
    .cropper-modal-overlay .zoom-input input[type=range]:focus::-webkit-slider-runnable-track {
      background: rgba(0, 21, 113, 0.5); }
    .cropper-modal-overlay .zoom-input input[type=range]::-moz-range-track {
      width: 100%;
      height: 1.7px;
      cursor: pointer;
      box-shadow: 0px 0px 0.5px #201c16, 0px 0px 0px #2f2920;
      background: rgba(0, 16, 87, 0.5);
      border-radius: 0.4px;
      border: 0.4px solid rgba(1, 1, 1, 0.68); }
    .cropper-modal-overlay .zoom-input input[type=range]::-moz-range-thumb {
      box-shadow: 0px 0px 0px rgba(52, 100, 165, 0.7), 0px 0px 0px rgba(58, 112, 184, 0.7);
      border: 0.9px solid rgba(94, 60, 180, 0.6);
      height: 17px;
      width: 17px;
      border-radius: 47px;
      background: #ffffff;
      cursor: pointer; }
    .cropper-modal-overlay .zoom-input input[type=range]::-ms-track {
      width: 100%;
      height: 1.7px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent; }
    .cropper-modal-overlay .zoom-input input[type=range]::-ms-fill-lower {
      background: rgba(0, 11, 62, 0.5);
      border: 0.4px solid rgba(1, 1, 1, 0.68);
      border-radius: 0.8px;
      box-shadow: 0px 0px 0.5px #201c16, 0px 0px 0px #2f2920; }
    .cropper-modal-overlay .zoom-input input[type=range]::-ms-fill-upper {
      background: rgba(0, 16, 87, 0.5);
      border: 0.4px solid rgba(1, 1, 1, 0.68);
      border-radius: 0.8px;
      box-shadow: 0px 0px 0.5px #201c16, 0px 0px 0px #2f2920; }
    .cropper-modal-overlay .zoom-input input[type=range]::-ms-thumb {
      box-shadow: 0px 0px 0px rgba(52, 100, 165, 0.7), 0px 0px 0px rgba(58, 112, 184, 0.7);
      border: 0.9px solid rgba(94, 60, 180, 0.6);
      height: 17px;
      width: 17px;
      border-radius: 47px;
      background: #ffffff;
      cursor: pointer;
      height: 1.7px; }
    .cropper-modal-overlay .zoom-input input[type=range]:focus::-ms-fill-lower {
      background: rgba(0, 16, 87, 0.5); }
    .cropper-modal-overlay .zoom-input input[type=range]:focus::-ms-fill-upper {
      background: rgba(0, 21, 113, 0.5); }
    @media (max-width: 480px) {
      .cropper-modal-overlay .zoom-input {
        width: 80%;
        margin: 10px auto; } }

.cropper-crop-box,
.cropper-view-box,
.cropper-container,
.cropper-wrap-box,
.cropper-drag-box {
  border-radius: 50%; }

.cropper-view-box {
  outline: 0; }

.cropper-face {
  background-color: #d9d9d9; }

.cropper-line, .cropper-point {
  display: none; }

.close-cropper {
  position: absolute;
  top: 18px;
  right: 22px;
  height: 18px;
  width: 18px;
  background: url(/static/media/close-mini__icon.c8a63cd4.svg) 0 0 no-repeat;
  cursor: pointer;
  fill: red;
  background-size: cover; }

@media (max-width: 480px) {
  .order-history {
    width: 90%;
    margin: 0 auto; } }

.order-history-top {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .order-history-top.no-orders {
    justify-content: center; }

.order-history__title {
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  font-size: 17px;
  color: #4a4a4a; }

.order-history__filter {
  position: relative;
  width: 186px;
  font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .order-history__filter-icon {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(/static/media/calendar.e9b1a010.svg);
    background-size: cover; }

.order-history .order-history-select__control {
  padding-left: 26px; }
  .order-history .order-history-select__control--is-focused, .order-history .order-history-select__control:hover {
    box-shadow: none;
    border-color: #cccccc; }

.order-history .order-history-select__indicator-separator {
  display: none; }

.order-history .order-history-select__indicator {
  padding: 5px;
  cursor: pointer; }

.order-history .order-history-select__option--is-selected {
  background-color: rgba(0, 126, 255, 0.04);
  color: #000000; }

.order-history .order-history-select__option:hover {
  background-color: rgba(0, 126, 255, 0.08); }

.order-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #dfe2e5;
  box-sizing: border-box;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 14px; }
  .order-table__row {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 40px;
    padding: 5px 0;
    border-bottom: 1px solid #dfe2e5; }
    .order-table__row:last-child {
      border-bottom: 1px solid transparent; }
  .order-table__header {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: #9b9b9b;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    .order-table__header .order-table__row {
      border-bottom: 1px solid #dfe2e5; }
  .order-table__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #4A4A4A; }
  .order-table__column {
    padding: 0 15px;
    box-sizing: border-box; }
  .order-table .column-date {
    max-width: 175px;
    width: 100%;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .order-table .column-product {
    max-width: 220px;
    width: 100%; }
  .order-table .column-type {
    max-width: 160px;
    width: 100%; }
  .order-table .column-status {
    max-width: 110px;
    width: 100%; }
  .order-table .column-amount {
    width: 100%;
    max-width: 105px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .order-table .column-coins {
    color: #da1781; }
  .order-table .no-orders {
    padding: 45px;
    background: #f8f8f8; }
    .order-table .no-orders__img {
      max-width: 70px;
      max-height: 70px; }
    .order-table .no-orders__title {
      margin-left: 40px;
      color: #4A4A4A;
      font-size: 20px;
      font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .order-table .spinner-img {
    margin: 0 auto; }

@media (max-width: 667px) {
  .order-history__title {
    margin-top: 10px; }
  .order-history-top {
    align-items: flex-start; }
  .order-history-actions {
    flex-direction: column; }
  .order-table {
    flex-direction: row;
    overflow-x: auto; }
    .order-table__row {
      flex-direction: column;
      align-items: flex-start;
      width: auto; }
    .order-table__header {
      width: 20%; }
      .order-table__header .order-table__row {
        justify-content: space-around;
        border-bottom: none;
        border-right: 1px solid #dfe2e5;
        width: 100%; }
    .order-table__body {
      width: 80%;
      flex-direction: row; }
      .order-table__body .order-table__row {
        border-bottom: none;
        border-right: 1px solid #dfe2e5; }
        .order-table__body .order-table__row:last-of-type {
          border-right: 1px solid transparent; }
    .order-table__column {
      white-space: nowrap;
      padding-top: 8px;
      padding-bottom: 8px; }
    .order-table .column-datem,
    .order-table .column-product,
    .order-table .column-type,
    .order-table .column-status,
    .order-table .column-amount {
      max-width: 100%; }
    .order-table .no-orders {
      width: 100%;
      align-items: center; }
      .order-table .no-orders .no-orders__title {
        margin: 0; } }

.account-settings {
  display: flex;
  flex-direction: column;
  max-width: 568px;
  width: 100%; }
  .account-settings-form {
    display: flex;
    flex-direction: column; }
    .account-settings-form form {
      width: 100%; }
    .account-settings-form .block-errors__text {
      bottom: -10px; }
  .account-settings .form-settings-shipping .state .province-field .province-field__hidden-input {
    height: 42px;
    font-size: 14px; }

@media (max-width: 480px) {
  .shipping-and-payment .market-place-settings-content__actions {
    padding-right: 15px; } }

.offer-landing__subtitle, .offer-landing-list__item {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 1.36;
  color: #4a4a4a; }
  @media (max-width: 1060px) and (min-width: 668px) {
    .offer-landing__subtitle, .offer-landing-list__item {
      font-size: 20px; } }
  @media (max-width: 667px) {
    .offer-landing__subtitle, .offer-landing-list__item {
      font-size: 18px;
      letter-spacing: normal;
      line-height: 1.33; } }

.offer-landing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  padding: 80px 0;
  z-index: 6;
  background-color: #ffffff; }
  @media (max-width: 667px) {
    .offer-landing {
      padding: 56px 0; } }
  .offer-landing__title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 44px;
    line-height: .95;
    color: #333333; }
    @media (max-width: 667px) {
      .offer-landing__title {
        font-size: 25px; } }
  .offer-landing__title-image {
    margin-left: 12px;
    height: 60px; }
    @media (max-width: 667px) {
      .offer-landing__title-image {
        height: 34px; } }
  .offer-landing__content {
    max-width: 1070px;
    margin: 0 auto;
    padding: 50px 13px 0;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 41.66667% 1fr;
    grid-column-gap: 40px;
    grid-template-areas: "image first-subtitle" ". list" ". last-subtitle" ". actions"; }
    @media (max-width: 667px) {
      .offer-landing__content {
        max-width: 400px;
        padding-top: 0;
        grid-column-gap: 14px;
        grid-template-columns: 44% 1fr; } }
  .offer-landing__image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: image;
    grid-row: 1/5;
    grid-column: 1/2;
    border: 1px solid #c6c6c6; }
    @media (max-width: 1060px) and (min-width: 668px) {
      .offer-landing__image-container {
        grid-column: 1/2; } }
    @media (max-width: 667px) {
      .offer-landing__image-container {
        grid-row: 2; } }
  .offer-landing__image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }
  .offer-landing__subtitle-container {
    grid-area: first-subtitle;
    padding-bottom: 9px; }
    @media (max-width: 1060px) and (min-width: 668px) {
      .offer-landing__subtitle-container {
        grid-column: 2/3; } }
    @media (max-width: 667px) {
      .offer-landing__subtitle-container {
        grid-column: 1/3;
        padding: 30px 0 36px;
        text-align: center; }
        .offer-landing__subtitle-container > .offer-landing__subtitle {
          max-width: 256px; } }
    .offer-landing__subtitle-container--second {
      grid-area: last-subtitle;
      padding-top: 15px; }
      @media (max-width: 1060px) and (min-width: 668px) {
        .offer-landing__subtitle-container--second {
          grid-column: 2/3; } }
      @media (max-width: 667px) {
        .offer-landing__subtitle-container--second {
          grid-column: 1/3;
          grid-row: 3;
          padding-top: 40px;
          text-align: center; } }
  .offer-landing__subtitle {
    display: inline-block; }
  .offer-landing__list-container {
    grid-area: list; }
    @media (max-width: 1060px) and (min-width: 668px) {
      .offer-landing__list-container {
        grid-column: 2/3; } }
  .offer-landing-list {
    list-style-type: decimal;
    list-style-position: inside; }
  .offer-landing-list__item {
    padding: 9px 0; }
    @media (max-width: 667px) {
      .offer-landing-list__item:first-child {
        padding-top: 0; }
      .offer-landing-list__item:last-child {
        padding-bottom: 0; } }
  .offer-landing__action-wrapper {
    grid-area: actions;
    display: flex;
    padding-top: 30px; }
    @media (max-width: 1060px) and (min-width: 668px) {
      .offer-landing__action-wrapper {
        grid-column: 2/3; } }
    @media (max-width: 667px) {
      .offer-landing__action-wrapper {
        grid-row: 5;
        grid-column: 1/3;
        justify-content: center;
        flex-direction: column; } }
  .offer-landing__action {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 61px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 26px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: none;
    border-radius: 2px;
    color: #ffffff;
    background-color: #da1781; }
    .offer-landing__action:last-child {
      margin-left: 20px; }
      @media (max-width: 667px) {
        .offer-landing__action:last-child {
          margin-left: 0;
          margin-top: 15px; } }
    @media (max-width: 1060px) {
      .offer-landing__action {
        font-size: 20px;
        height: 47px;
        width: 150px; } }
    @media (max-width: 667px) {
      .offer-landing__action {
        width: 100%; } }
  .offer-landing__action--secondary {
    font-style: italic;
    background-color: #e2e2e2; }

.brands-carousel.slick-slider {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center; }

.brands-carousel .slick-list {
  width: 100%; }

.brands-carousel__item img {
  max-height: 40px;
  width: auto;
  margin: 0 auto; }
  @media (max-width: 960px) and (min-width: 481px) {
    .brands-carousel__item img {
      max-height: 34px; } }
  @media (max-width: 480px) and (min-width: 376px) {
    .brands-carousel__item img {
      max-height: 28px; } }
  @media (max-width: 375px) {
    .brands-carousel__item img {
      max-height: 22px; } }

.brands-carousel__prev, .brands-carousel__next {
  cursor: pointer; }
  @media (max-width: 480px) {
    .brands-carousel__prev img, .brands-carousel__next img {
      max-width: 36px; } }

.qt-banner {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  min-height: calc(100vh - 88px); }
  .qt-banner--cut-bg .qt-banner-main {
    background-image: url(/static/media/quickto-banner-bg-cut.b18a9ee1.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 370px;
    background-color: #f5f5f5; }
    @media (max-width: 480px) {
      .qt-banner--cut-bg .qt-banner-main {
        background-size: 440px;
        background-position: -250px 0; } }
  .qt-banner--cut-bg .qt-banner-main__inner {
    background-color: transparent; }
  .qt-banner-main {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    flex-grow: 1;
    min-height: 268px;
    background-image: url(/static/media/quickto-banner-bg.4d138337.jpg);
    background-position: center;
    background-size: cover; }
    @media (max-width: 768px) {
      .qt-banner-main {
        min-height: 418px; } }
  .qt-banner-main__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.5); }
  .qt-banner-main__title {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 1;
    text-align: center;
    color: #4a4a4a; }
    @media (max-width: 480px) {
      .qt-banner-main__title {
        font-size: 22px; } }
    @media (max-width: 360px) {
      .qt-banner-main__title {
        max-width: 280px;
        margin-top: 20px; } }
  .qt-banner-main__subtitle {
    margin-top: 8px;
    font-family: proxima_nova_bold, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1.47;
    text-align: center;
    color: #0baff0; }
    @media (max-width: 480px) {
      .qt-banner-main__subtitle {
        font-size: 18px; } }
  .qt-banner__button {
    margin-top: 30px;
    min-width: 160px;
    height: 40px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 16px; }
  .qt-banner-footer {
    padding: 34px 0 50px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #f8f8f8; }
  .qt-banner-footer__title {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    color: #4a4a4a; }
  .qt-banner-footer__divider {
    height: 1px;
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
    background-color: #9b9b9b; }
    @media (max-width: 480px) {
      .qt-banner-footer__divider {
        width: 80%; } }
  .qt-banner-item-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 768px) {
      .qt-banner-item-list {
        flex-wrap: wrap;
        justify-content: center; } }
  .qt-banner-item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px; }
    @media (max-width: 1350px) {
      .qt-banner-item {
        align-items: flex-start;
        flex-grow: 1; } }
    @media (max-width: 768px) {
      .qt-banner-item {
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        max-width: 450px;
        padding: 16px; } }
    @media (max-width: 480px) {
      .qt-banner-item {
        max-width: 380px; } }
  .qt-banner-item__icon {
    align-self: center;
    max-height: 56px;
    max-width: 75px; }
    @media (max-width: 1350px) {
      .qt-banner-item__icon {
        height: 40px;
        width: 55px; } }
  .qt-banner-item__text {
    margin-top: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 345px; }
    @media (max-width: 768px) {
      .qt-banner-item__text {
        margin-left: 10px; } }
  .qt-banner-item__title {
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1;
    color: #0baff0;
    white-space: nowrap; }
    @media (max-width: 768px) {
      .qt-banner-item__title {
        font-size: 16px; } }
  .qt-banner-item__subtitle {
    margin-top: 8px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1;
    color: #4a4a4a; }
    @media (max-width: 768px) {
      .qt-banner-item__subtitle {
        font-size: 14px; } }
  .qt-banner__brands {
    margin-top: 20px;
    max-width: 1000px;
    width: 100%; }
    @media (max-width: 768px) and (min-width: 361px) {
      .qt-banner__brands {
        max-width: 90%; } }
    @media (max-width: 360px) {
      .qt-banner__brands {
        max-width: 100%; } }
  .qt-banner__brands .qt-banner-footer__title {
    margin-bottom: 30px;
    padding: 0 10px;
    font-size: 24px; }

.landing * {
  box-sizing: border-box; }

.landing__contaimer {
  max-width: 1024px;
  margin: 0 auto; }

.landing__action {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px; }

.landing__action a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: inherit; }

.landing__title {
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #000000; }

.landing__subtitle {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000; }
  .landing__subtitle:first-letter {
    text-transform: uppercase; }

.landing-header {
  position: relative; }

.landing-header__link {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

.landing-header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
  padding: 6px 20px;
  z-index: 2;
  color: #000000;
  background-color: #ffffff; }

.landing-header__logo {
  font-family: 'Prompt', sans-serif;
  font-size: 30px;
  text-transform: capitalize; }

.landing-header__menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 16px;
  line-height: normal; }

.landing-header__action {
  min-width: 78px;
  font-size: 16px;
  padding: 1px 0; }

@media (max-width: 480px) {
  .landing-header__nav {
    display: none; } }

.landing-header__nav--mobile {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  transition: top .3s, transform .3s;
  z-index: 1;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 16px;
  line-height: normal;
  background-color: #ffffff; }
  .landing-header__nav--mobile.opened {
    top: 100%;
    transform: translateY(0); }
  @media (min-width: 481px) {
    .landing-header__nav--mobile {
      display: none; } }

.landing-header__nav ul {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: flex-start; }
  @media (min-width: 481px) {
    .landing-header__nav ul {
      flex-direction: row; } }

.landing-header__nav-item {
  padding: 10px 15px;
  width: 100%;
  text-transform: capitalize;
  box-shadow: inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.05); }
  @media (min-width: 480px) {
    .landing-header__nav-item {
      padding: 0 15px;
      width: auto;
      box-shadow: none;
      cursor: pointer; } }

.landing-header__nav-item a {
  text-decoration: none;
  color: inherit; }

.landing-header__nav-icon-wrapper {
  padding: 0 10px; }

.landing-header__nav-icon {
  width: 15px;
  height: 12px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }
  @media (min-width: 481px) {
    .landing-header__nav-icon {
      display: none; } }

.landing-header__nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000000;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

.landing-header__nav-icon span:nth-child(1) {
  top: 0; }

.landing-header__nav-icon span:nth-child(2),
.landing-header__nav-icon span:nth-child(3) {
  top: 5px; }

.landing-header__nav-icon span:nth-child(4) {
  top: 10px; }

.landing-header__nav-icon.opened span:nth-child(1) {
  top: 5px;
  width: 0;
  left: 50%; }

.landing-header__nav-icon.opened span:nth-child(2) {
  transform: rotate(45deg); }

.landing-header__nav-icon.opened span:nth-child(3) {
  transform: rotate(-45deg); }

.landing-header__nav-icon.opened span:nth-child(4) {
  top: 5px;
  width: 0;
  left: 50%; }

.landing-banner {
  background-image: url(/static/media/quickto-landing-banner-bg.fa108d1b.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.landing-banner__link {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

.landing-banner__container {
  max-width: 1024px;
  margin: 0 auto;
  min-height: 130px;
  padding: 23px 15px; }
  @media (min-width: 481px) and (max-width: 767px) {
    .landing-banner__container {
      padding: 40px 30px; } }
  @media (min-width: 768px) {
    .landing-banner__container {
      padding: 80px 50px 0;
      min-height: 450px; } }
  @media (min-width: 1680px) {
    .landing-banner__container {
      max-width: 1366px; } }

.landing-banner__title {
  max-width: 64px;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 12px;
  line-height: normal;
  color: #ffffff; }
  @media (min-width: 481px) and (max-width: 767px) {
    .landing-banner__title {
      max-width: 120px;
      font-size: 20px; } }
  @media (min-width: 768px) {
    .landing-banner__title {
      max-width: 220px;
      font-size: 40px; } }

.landing-banner__action {
  margin-top: 14px;
  max-width: 52px;
  padding: 1px 0 0;
  font-size: 8px;
  line-height: 10px;
  text-transform: capitalize;
  color: #ffffff;
  border-color: #ffffff; }
  @media (min-width: 481px) and (max-width: 767px) {
    .landing-banner__action {
      max-width: 90px;
      font-size: 14px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .landing-banner__action {
      margin-top: 40px;
      max-width: 177px;
      padding: 5px 0 2px;
      font-size: 30px;
      line-height: 36px; } }

.landing-differences {
  padding: 40px 0 30px; }

.landing-differences__container {
  max-width: 860px;
  margin: 0 auto; }

.landing-differences__box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center; }
  @media (min-width: 768px) {
    .landing-differences__box {
      flex-direction: row;
      align-items: flex-end; } }

.landing-differences__item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 0 20px; }

.landing-differences__item:nth-child(2) .landing-differences__icon-wrapper:before {
  position: absolute;
  content: 'Q';
  top: -3px;
  right: 6px;
  font-family: 'Prompt', sans-serif;
  font-size: 30px; }

.landing-differences__icon-wrapper {
  position: relative; }

.landing-differences__icon {
  max-width: 75px;
  max-height: 68px; }

.landing-differences__text {
  margin-top: 30px;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #000000; }

.landing-bestsellers {
  padding: 30px 5px; }

.landing-bestsellers .landing__subtitle {
  margin-top: 5px; }

.landing-bestsellers__container {
  max-width: 580px;
  margin: 0 auto; }
  @media (min-width: 890px) {
    .landing-bestsellers__container {
      max-width: 880px; } }

.landing-bestsellers__box {
  padding-top: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center; }

.landing-bestsellers__item {
  max-width: 50%;
  margin-top: 30px;
  padding: 0 5px; }
  @media (min-width: 768px) {
    .landing-bestsellers__item {
      max-width: 220px;
      padding: 0 10px; } }

.landing-bestsellers__item a {
  display: block; }

.landing-bestsellers__name {
  max-width: 120px;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-style: italic; }
  @media (min-width: 768px) {
    .landing-bestsellers__name {
      max-width: 100%; } }

.landing-bestsellers__image {
  height: auto;
  width: 100%;
  transition: .2s;
  cursor: pointer; }

.landing-bestsellers__info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #000000; }
  @media (min-width: 768px) {
    .landing-bestsellers__info {
      margin-top: 10px; } }

.landing-bestsellers__price {
  font-family: proxima_nova_semibold, Helvetica, sans-serif; }

.landing-brands {
  padding: 30px 0; }

.landing-brands__box {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  margin: 25px auto 0;
  max-width: 280px; }
  @media (min-width: 481px) and (max-width: 767px) {
    .landing-brands__box {
      max-width: 590px; } }
  @media (min-width: 768px) {
    .landing-brands__box {
      max-width: 740px; } }

.landing-brands__item-wrapper {
  padding: 10px; }
  @media (min-width: 481px) and (max-width: 767px) {
    .landing-brands__item-wrapper {
      padding: 15px; } }
  @media (min-width: 768px) {
    .landing-brands__item-wrapper {
      padding: 15px 25px; } }

.landing-brands__item {
  width: auto;
  max-width: 100%;
  max-height: 20px;
  cursor: pointer; }
  @media (min-width: 481px) and (max-width: 767px) {
    .landing-brands__item {
      max-height: 26px; } }
  @media (min-width: 768px) {
    .landing-brands__item {
      max-height: 30px; } }

.landing-footer {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  background-color: #f8f8f8; }

.landing-footer__container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px 20px; }
  @media (min-width: 481px) {
    .landing-footer__container {
      flex-direction: row; } }

.landing-footer__info {
  margin-top: 40px; }
  @media (min-width: 481px) and (max-width: 767px) {
    .landing-footer__info {
      margin-top: 0;
      margin-left: auto; } }
  @media (min-width: 768px) {
    .landing-footer__info {
      margin-top: 0;
      margin-left: 100px; } }

.landing-footer__info .landing-footer__list-item {
  cursor: pointer; }

.landing-footer__list {
  margin-top: 15px; }

.landing-footer__list-item a {
  color: #000000;
  text-decoration: none; }

.landing-footer__payment-methods {
  margin-top: 30px;
  margin-left: auto;
  align-self: flex-end; }

.quickto-modal-wrapper {
  width: 550px;
  height: 350px;
  position: fixed;
  left: calc(50% - 275px);
  bottom: 100px;
  z-index: 5003;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: -1px 0px 68px 16px #c4c4c4;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  visibility: hidden; }
  @media (max-width: 480px) {
    .quickto-modal-wrapper {
      width: 320px;
      height: 300px;
      left: calc(50% - 160px); } }

.quickto-modal-content {
  padding: 20px 30px;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  position: relative;
  height: 100%; }

.quickto-modal-header {
  display: flex;
  justify-content: space-between; }
  .quickto-modal-header__text {
    font-size: 40px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    padding: 10px 0 30px; }
    @media (max-width: 480px) {
      .quickto-modal-header__text {
        font-size: 25px; } }
  .quickto-modal-header__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 28px;
    height: 28px;
    opacity: 0.3;
    cursor: pointer; }
    .quickto-modal-header__close:hover {
      opacity: 0.7; }
    .quickto-modal-header__close:after, .quickto-modal-header__close:before {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 28px;
      width: 3px;
      background-color: #333; }
    .quickto-modal-header__close:before {
      transform: rotate(45deg); }
    .quickto-modal-header__close:after {
      transform: rotate(-45deg); }
  .quickto-modal-header__logo {
    font-size: 60px;
    text-transform: capitalize;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
    @media (max-width: 480px) {
      .quickto-modal-header__logo {
        font-size: 45px; } }

.quickto-modal-body {
  display: flex;
  justify-content: space-between; }
  .quickto-modal-body__row {
    padding: 3px 0;
    font-size: 18px;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
    .quickto-modal-body__row a {
      color: #000000;
      text-decoration: none; }
    @media (max-width: 480px) {
      .quickto-modal-body__row {
        font-size: 12px; } }
    .quickto-modal-body__row_one {
      line-height: 24px; }
      @media (max-width: 480px) {
        .quickto-modal-body__row_one {
          line-height: 16px;
          font-size: 14px; } }
  .quickto-modal-body__logo {
    text-transform: capitalize; }

.quickto-modal-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 15px; }
  .quickto-modal-footer__ok-button {
    width: 120px;
    height: 40px;
    border-radius: 8px;
    background-color: #e9e9e9;
    color: #4a4a4a;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
    .quickto-modal-footer__ok-button:hover {
      background-color: #c9c9c9; }

.visible {
  visibility: visible !important; }

.klickly-iframe-unit-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 5;
  visibility: hidden; }
  .klickly-iframe-unit-wrapper_mobile {
    bottom: 10px;
    left: calc(50% - 160px); }

.klickly-iframe-animation-appears {
  visibility: visible;
  -webkit-animation: klicklyIframeAnimationAppears 1s ease-in;
  animation: klicklyIframeAnimationAppears 1s ease-in; }

@-webkit-keyframes klicklyIframeAnimationAppears {
  0% {
    -webkit-transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0); } }

@keyframes klicklyIframeAnimationAppears {
  0% {
    -webkit-transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0); } }

.product-landing__line {
  height: 6px;
  background-color: #da1781; }

.product-landing__content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  max-width: 1148px;
  padding: 72px 20px 20px;
  margin: 0 auto; }
  @media (max-width: 700px) {
    .product-landing__content {
      flex-direction: column;
      align-items: center; } }
  @media (max-width: 480px) {
    .product-landing__content {
      padding: 20px; } }

.product-landing__data {
  width: 400px; }
  @media (max-width: 700px) {
    .product-landing__data {
      width: auto;
      max-width: 80%; } }
  @media (max-width: 480px) {
    .product-landing__data {
      max-width: 100%; } }

.product-landing__info {
  position: relative;
  width: 500px; }

.product-landing__image {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  margin-bottom: 10px; }
  @media (max-width: 1000px) {
    .product-landing__image {
      height: 350px; } }
  @media (max-width: 700px) {
    .product-landing__image {
      height: auto;
      margin-bottom: 20px; } }

.product-landing__image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%; }

.product-landing__row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 700px) {
    .product-landing__row {
      align-items: flex-start; } }

.product-landing__name {
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0;
  color: #4a4a4a; }

.product-landing__brand {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.45;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #4a4a4a; }

.product-landing__price {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 30px;
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  color: #4a4a4a; }

.product-landing__price--new {
  color: #da1781;
  line-height: 1.43; }

.product-landing__price-label {
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.43; }

.product-landing__price-value {
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase; }

.product-landing__info {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-left: 40px; }
  @media (max-width: 700px) {
    .product-landing__info {
      margin-left: 0;
      margin-top: 20px;
      width: auto;
      max-width: 80%; } }
  @media (max-width: 480px) {
    .product-landing__info {
      max-width: 100%; } }

.product-landing__paragraph {
  width: 100%;
  padding: 10px 0;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 40px;
  color: #4a4a4a; }
  @media (max-width: 1000px) and (min-width: 699px) {
    .product-landing__paragraph {
      font-size: 24px;
      line-height: 26px; } }
  @media (max-width: 700px) {
    .product-landing__paragraph {
      font-size: 20px;
      line-height: 22px; } }

.product-landing__paragraph b,
.product-landing__paragraph strong {
  font-family: proxima_nova_semibold, Helvetica, sans-serif; }

.product-landing__paragraph strong {
  color: #da1781; }

.product-landing__action-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%; }
  @media (max-width: 700px) {
    .product-landing__action-box {
      position: static;
      margin-top: 20px; } }

.product-landing__action-divider {
  display: none;
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 1.54;
  color: #4a4a4a; }

.product-landing__action {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 210px;
  font-family: proxima_nova_semibold, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 1;
  text-decoration: none;
  border-radius: 2px;
  border: 1px solid #da1781;
  color: #da1781; }
  .product-landing__action:last-child {
    margin-left: 20px; }
  @media (max-width: 1000px) {
    .product-landing__action {
      height: 40px;
      width: 160px;
      font-size: 20px; } }

.product-landing__action--filled {
  background-color: #da1781;
  color: #ffffff; }

.product-landing__action--large {
  width: 100%; }
  @media (max-width: 700px) {
    .product-landing__action--large {
      margin-bottom: 10px; } }
  @media (min-width: 700px) {
    .product-landing__action--large {
      display: none; } }

.product-landing:not(.product-landing--free) .product-landing__action--large {
  display: none; }

.product-landing:not(.product-landing--free) .product-landing__row {
  align-items: flex-start; }

.product-landing:not(.product-landing--free) .product-landing__price--new {
  display: none; }

.product-landing:not(.product-landing--free) .product-landing__price-label {
  display: none; }

.product-landing:not(.product-landing--free) .product-landing__price-value {
  line-height: 1.43; }

.product-landing--secondary .product-landing__line {
  display: none; }

.product-landing--secondary .product-landing__data {
  width: 450px;
  padding: 20px;
  border: 1px solid #979797; }
  @media (max-width: 700px) {
    .product-landing--secondary .product-landing__data {
      width: auto; } }

.product-landing--secondary .product-landing__name {
  font-size: 22px; }

.product-landing--secondary .product-landing__brand {
  font-size: 20px;
  color: #9b9b9b; }

.product-landing--secondary .product-landing__info {
  align-items: flex-end;
  justify-content: flex-start; }
  @media (max-width: 700px) {
    .product-landing--secondary .product-landing__info {
      justify-content: center; } }

.product-landing--secondary .product-landing__action-box {
  position: static;
  width: 400px;
  flex-direction: column-reverse; }
  @media (max-width: 1000px) {
    .product-landing--secondary .product-landing__action-box {
      width: 300px; } }
  @media (max-width: 700px) {
    .product-landing--secondary .product-landing__action-box {
      align-items: center; } }

.product-landing--secondary .product-landing__action {
  width: 100%;
  height: 60px;
  font-size: 26px; }
  .product-landing--secondary .product-landing__action:last-child {
    margin-left: 0; }

.product-landing--secondary .product-landing__action-divider {
  display: block; }

.product-landing--free .product-landing__info {
  align-items: flex-end; }

.product-landing--free .product-landing__action-box {
  position: static; }

.product-landing--free .product-landing__action-box .product-landing__action {
  width: 50%; }

.product-landing--free .product-landing__price--original .product-landing__price-value {
  text-decoration: line-through; }

.notification-block {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 300px;
  min-height: 80px;
  margin-bottom: 10px;
  padding: 15px 15px 15px 56px;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.29); }
  .notification-block.error {
    background-color: #F6A623; }
  .notification-block.success {
    background-color: #87B576; }
  .notification-block .close-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 8px;
    right: 8px;
    background-image: none;
    cursor: pointer; }
  .notification-block__img {
    position: absolute;
    top: 16px;
    left: 20px; }
  .notification-block__title {
    margin-bottom: 4px;
    text-align: left;
    font-family: proxima_nova_bold, Helvetica, sans-serif;
    font-size: 14px;
    color: #ffffff; }
  .notification-block__info {
    text-align: left;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 12px;
    color: #ffffff;
    line-height: 16px; }
  .notification-block__list {
    list-style: disc;
    padding-left: 10px; }

.block-errors {
  position: fixed;
  width: 300px;
  top: 25px;
  right: 20px;
  z-index: 11; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input:active, input:hover, input:focus,
button:active, button:hover, button:focus,
textarea:active, textarea:hover, textarea:focus,
select:active, select:hover, select:focus {
  outline: none; }

*, *::after, *::before {
  box-sizing: border-box; }

button {
  border: none;
  background: none;
  cursor: pointer; }

.hide {
  display: none !important; }

.show {
  display: block; }

/* Change the white to any color ;) */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 40px white inset; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

@font-face {
  font-family: "proxima_nova_light";
  src: url(/static/media/proxima-nova-light-webfont.62a91ead.woff2) format("woff2"), url(/static/media/proxima-nova-light-webfont.d3040970.woff) format("woff"), url(/static/media/proxima-nova-light-webfont.11c94d07.svg#proxima_nova_light) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "proxima_nova_bold";
  src: url(/static/media/proxima-nova-bold-webfont.f22fbd7f.woff2) format("woff2"), url(/static/media/proxima-nova-bold-webfont.38d67210.woff) format("woff"), url(/static/media/proxima-nova-bold-webfont.45e4707e.svg#proxima_nova_bold) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "proxima_nova_regular";
  src: url(/static/media/proxima-nova-regular-webfont.0d79df03.woff2) format("woff2"), url(/static/media/proxima-nova-regular-webfont.2a28cf2f.woff) format("woff"), url(/static/media/proxima-nova-regular-webfont.60bf06a1.svg#proxima_nova_regular) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "proxima_nova_semibold";
  src: url(/static/media/proxima-nova-semibold-webfont.8f278738.woff2) format("woff2"), url(/static/media/proxima-nova-semibold-webfont.b0d5b7ee.woff) format("woff"), url(/static/media/proxima-nova-semibold-webfont.139f406c.svg#proxima_nova_semibold) format("svg");
  font-weight: bold;
  font-style: normal; }

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8; }
  @media all and (max-width: 480px) {
    .overlay {
      height: calc(100vh - 50px); } }
  .overlay-y {
    overflow-y: auto; }

@media (max-width: 480px) {
  .overlay {
    width: 100%;
    height: 100%;
    box-sizing: border-box; }
    .overlay-y {
      overflow-y: auto; }
  .overlay.image-view-in-mobile .slick-track {
    display: flex !important; }
  .overlay.image-view-in-mobile .product-carousel {
    width: 100%;
    height: 140px; }
  .overlay.image-view-in-mobile .product-carousel__item:focus {
    outline: none; } }

.open-modal {
  overflow: hidden; }

.icon {
  cursor: pointer; }

.relative {
  position: relative; }

.line-through {
  text-decoration: line-through; }

.market-place {
  transition: .4s;
  position: relative; }
  .market-place-search {
    padding: 40px 0;
    background-color: #f6f7f8; }
    .market-place-search__title {
      font-family: proxima_nova_semibold, Helvetica, sans-serif;
      font-size: 19px;
      text-align: center;
      color: #000000;
      margin-bottom: 22px; }
    .market-place-search-block {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      max-width: 70%;
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      z-index: 5;
      top: 0; }
    .market-place-search-form {
      display: flex;
      max-width: 90%;
      width: 100%;
      margin: 0 auto;
      border: 1px solid #da1781;
      border-radius: 2px; }
      .market-place-search-form__input-wrapper {
        display: flex;
        width: 100%; }
        .market-place-search-form__input-wrapper input {
          padding: 11px 14px;
          width: 100%;
          background-color: #ffffff;
          border: none;
          font-family: proxima_nova_semibold, Helvetica, sans-serif;
          font-size: 17px;
          line-height: 17px;
          color: #da1781;
          outline: none; }
          .market-place-search-form__input-wrapper input::-webkit-input-placeholder {
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            color: #da1781;
            font-size: 17px;
            line-height: 17px; }
          .market-place-search-form__input-wrapper input::-moz-placeholder {
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            color: #da1781;
            font-size: 17px;
            line-height: 17px; }
          .market-place-search-form__input-wrapper input:-ms-input-placeholder {
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            color: #da1781;
            font-size: 17px;
            line-height: 17px; }
          .market-place-search-form__input-wrapper input:-moz-placeholder {
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            color: #da1781;
            font-size: 17px;
            line-height: 17px; }
      .market-place-search-form__submit {
        position: relative;
        padding: 11px 14px;
        min-width: 54px;
        border: none;
        background-color: #da1781;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        cursor: pointer; }
        .market-place-search-form__submit img {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 20px;
          width: 20px;
          transform: translate(-50%, -50%); }
  .market-place .mp-placeholder-logo {
    min-width: 170px;
    height: 44px; }
  .market-place .mp-placeholder-user-menu {
    min-width: 170px;
    height: 44px; }
  .market-place-content {
    padding: 0 22px 22px;
    margin: 0 auto;
    box-sizing: border-box; }
    .market-place-content-top {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      padding: 24px 30px 22px;
      background: #fff;
      z-index: 2; }
      .market-place-content-top.scrollUp.fixed {
        position: fixed;
        transform: translateY(88px);
        transition: transform .4s; }
        @media (min-width: 481px) and (max-width: 768px) {
          .market-place-content-top.scrollUp.fixed {
            transform: translateY(108px); } }
        @media (max-width: 480px) {
          .market-place-content-top.scrollUp.fixed {
            transform: translateY(82px); } }
      .market-place-content-top.scrollDown.fixed {
        position: fixed;
        transform: translateY(0);
        transition: transform .4s; }
      .market-place-content-top.scrollDown {
        transform: translateY(0);
        transition: transform .4s; }
    .market-place-content-bottom {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 20px;
      padding-bottom: 60px;
      min-height: 1px;
      text-align: center; }
    .market-place-content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 15px;
      overflow: hidden; }
    .market-place-content-products {
      width: 100%; }
      .market-place-content-products__noresults {
        width: 140px;
        margin: 20px auto;
        padding: 145px 0 0 7px;
        font-size: 14px;
        color: #9b9b9b;
        background-image: url(/static/media/package-empty.a0887e02.svg);
        background-repeat: no-repeat;
        font-family: proxima_nova_regular, Helvetica, sans-serif; }
      .market-place-content-products .product-card {
        width: 220px;
        margin: 10px;
        min-height: 100px; }
        .market-place-content-products .product-card__img {
          min-width: 100%;
          min-height: 100px; }
        .market-place-content-products .product-card__title {
          font-size: 12px; }
          @media (max-width: 667px) {
            .market-place-content-products .product-card__title {
              font-size: 10px; } }
        .market-place-content-products .product-card__brand {
          font-size: 12px; }
          @media (max-width: 667px) {
            .market-place-content-products .product-card__brand {
              font-size: 10px; } }
        .market-place-content-products .product-card__favorites {
          bottom: 10px;
          width: 20px;
          height: 16px; }
        .market-place-content-products .product-card:hover, .market-place-content-products .product-card:active {
          transition: .3s ease-out;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.4); }
        .market-place-content-products .product-card__price-current {
          font-size: 13px; }
          @media (max-width: 667px) {
            .market-place-content-products .product-card__price-current {
              font-size: 11px; } }
        .market-place-content-products .product-card .product-placeholder {
          height: 200px; }
  .market-place.sale {
    transform: translateY(128px); }
    @media (min-width: 481px) and (max-width: 768px) {
      .market-place.sale {
        transform: translateY(148px); } }
    @media (max-width: 480px) {
      .market-place.sale {
        transform: translateY(130px); } }
  .market-place button {
    outline: none; }
  .market-place .masonry {
    margin: 0 auto; }

#olark-wrapper .olark-launch-button.olark-size-sm {
  bottom: 48px !important; }

@media (max-width: 1024px) {
  .market-place-content-products {
    justify-content: space-evenly; } }

@media (max-width: 768px) {
  .market-place-search {
    padding: 0 0 20px;
    background: #fafafa; }
    .market-place-search__title {
      display: none; }
    .market-place-search-form {
      max-width: 390px;
      margin-top: 54px; }
      .market-place-search-form__input-wrapper input {
        padding: 9px 12px; }
  .market-place-content {
    padding: 26px 0 0; }
    .market-place-content-top {
      margin: 0; }
    .market-place-content-products .product-card {
      width: 165px;
      margin: 5px; } }

.with-loader {
  position: relative; }
  .with-loader:after {
    position: absolute;
    content: '';
    right: 50%;
    top: 50%;
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
            animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    z-index: 3;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0; }
  .with-loader:before {
    position: absolute;
    content: '';
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #ffffff;
    opacity: .5; }

@media (max-width: 667px) {
  .market-place-content {
    padding-top: 0; }
    .market-place-content-top {
      margin: 0;
      padding: 0;
      background: #ffffff; } }

@media (max-width: 480px) {
  .market-place-search__title {
    display: none; }
  .market-place-search-form {
    position: relative;
    max-width: 300px;
    height: 32px;
    margin-top: 40px; }
    .market-place-search-form__input-wrapper {
      font-size: 14px; }
    .market-place-search-form__submit {
      min-width: 40px; }
      .market-place-search-form__submit img {
        width: 17px;
        height: 17px; }
  .market-place-search-block {
    position: absolute;
    width: 100%;
    min-width: 80%;
    height: 100%;
    bottom: 0;
    transform: translateX(-50%);
    left: 50%; }
  .market-place-content-wrapper {
    margin: 0; }
  .market-place-content-products {
    margin-top: 10px; }
    .market-place-content-products .product-card {
      width: 145px;
      margin: 5px; } }

@media (max-width: 360px) {
  .market-place-content-products .product-card {
    width: 135px; } }

.overlay-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px; }

.overlay-auth.full-sized {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #ffffff; }
  @media (max-width: 480px) {
    .overlay-auth.full-sized {
      padding-top: 30px;
      justify-content: flex-start; } }

.overlay-auth.full-sized .auth-logo__img {
  max-width: 212px; }

.overlay-auth.full-sized > .auth {
  box-shadow: none;
  border: none;
  min-height: 0;
  padding: 15px 40px 15px; }
  @media (max-width: 480px) {
    .overlay-auth.full-sized > .auth {
      padding: 0; } }

.auth {
  position: relative;
  margin: auto;
  padding: 25px 58px 90px;
  max-width: 460px;
  min-height: 744px;
  width: 100%;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  border: solid 1px #dfe3e9; }
  .auth-logo {
    margin-top: 12px;
    text-align: center; }
    .auth-logo__img {
      max-width: 200px;
      width: 100%;
      height: 70px; }
  .auth__top {
    display: flex;
    flex-direction: column;
    padding: 0 14px 22px 10px; }
  .auth__form {
    position: relative;
    padding: 0 10px; }
  .auth .form__title {
    max-width: 260px;
    margin: 10px auto 20px;
    font-size: 28px;
    letter-spacing: 0.1px;
    text-align: center;
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    font-weight: normal; }
  .auth .form__title.small {
    font-size: 20px;
    max-width: 300px; }
  .auth .form__text {
    margin-top: 20px;
    font-size: 16px;
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    color: #4a4a4a;
    text-align: center; }
  .auth .form__link {
    font-size: 16px;
    color: #2ac7fd;
    cursor: pointer; }
  .auth .form-row {
    position: relative; }
  .auth__bottom .form__link {
    margin-top: 10px;
    align-self: flex-end; }
  .auth__bottom .block-errors__text {
    bottom: -10px; }
  .auth .common-input-wraper {
    padding-bottom: 18px;
    margin-bottom: 0; }
    .auth .common-input-wraper input {
      padding: 17px 13px;
      font-size: 16px; }
  .auth__close {
    display: block;
    width: 26px;
    height: 26px;
    margin-left: auto;
    margin-right: -25px;
    background-image: url(/static/media/cancel.138bf265.svg);
    background-size: cover;
    cursor: pointer; }
  .auth__close.disabled {
    pointer-events: none; }
  .auth__button {
    width: 100%;
    padding: 17px 0;
    background-color: #da1781;
    border-radius: 2px;
    font-family: proxima_nova_light, Helvetica, sans-serif;
    font-size: 16px;
    color: #fff;
    border: none;
    cursor: pointer; }
  .auth__button_fb {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: #3b5998; }
  .auth__button_fb img {
    height: 20px;
    margin-right: 10px; }
  .auth__button_fb span {
    transform: translateY(2px); }
  .auth-devidor {
    position: relative;
    display: flex; }
    .auth-devidor__text {
      padding: 10px;
      margin-left: 50%;
      transform: translateX(-50%);
      font-family: proxima_nova_semibold, Helvetica, sans-serif;
      font-size: 14px;
      background-color: #fff;
      color: #4a4a4a;
      text-transform: uppercase;
      z-index: 1; }
    .auth-devidor__line {
      position: absolute;
      top: 50%;
      width: 50%;
      height: 1px;
      background-color: #dfe2e5;
      transform: translateY(-50%);
      display: none; }
      .auth-devidor__line_left {
        left: 0; }
      .auth-devidor__line_right {
        right: 0; }
  .auth .form {
    max-width: 100%; }
    .auth .form-row {
      position: relative; }
      .auth .form-row:first-of-type {
        margin-top: 0; }
      .auth .form-row:not(:first-of-type) {
        margin-top: 17px; }
  .auth.sign-up .common-input-wraper {
    margin-bottom: 0; }
  .auth.sign-in .forgot-link {
    width: 100%;
    margin: 15px auto 0;
    text-align: center;
    font-size: 16px;
    color: #2ac7fd;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .auth .common-button {
    width: 100%;
    padding: 17px 0;
    font-size: 16px; }
  .auth .sign-in-form,
  .auth .sign-up-form {
    margin-top: 0;
    text-align: center; }
    .auth .sign-in-form .block-errors__text,
    .auth .sign-up-form .block-errors__text {
      bottom: 5px; }
  .auth .sign-up-form .form__text {
    color: #313d4f;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
    .auth .sign-up-form .form__text.sign-in {
      padding-top: 15px;
      padding-bottom: 15px; }
  .auth .sign-up-form .privacy-info {
    max-width: 245px;
    margin: 0 auto;
    font-size: 14px; }
    .auth .sign-up-form .privacy-info .form__link {
      color: #313d4f;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .auth .sign-reset-form__title {
    font-size: 16px;
    text-align: center; }
  .auth .sign-reset-form__message {
    font-size: 14px;
    text-align: center; }
  .auth .sign-reset-form input {
    height: auto;
    padding: 17px 13px;
    font-size: 16px; }
  .auth .sign-reset-form .block-errors {
    top: 0;
    right: 0; }
  .auth .sign-reset-form .btn-forgot {
    margin-top: 15px;
    padding: 17px 13px;
    height: auto;
    font-size: 16px; }
  .auth .reset-your-password__title {
    margin-bottom: 5px;
    font-size: 16px;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .auth .reset-your-password .input-wrapper {
    margin-bottom: 10px; }
  .auth .reset-your-password input,
  .auth .reset-your-password button {
    padding: 17px 13px;
    height: auto; }
  .auth-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 25px;
    text-align: center;
    font-size: 20px;
    line-height: 21px;
    color: #4a4a4a;
    border-top: 2px solid #dfe2e5;
    cursor: pointer;
    text-decoration: none;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .auth input::-webkit-input-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    color: #c3c3c3; }
  .auth input::-moz-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    color: #c3c3c3; }
  .auth input:-ms-input-placeholder {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
    color: #c3c3c3; }

@media (max-width: 1440px) and (min-width: 481px) {
  .auth {
    min-height: 634px; } }

@media (min-width: 769px) and (max-width: 1440px) {
  .auth {
    padding: 15px 40px 70px;
    max-width: 440px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
    border: solid 1px #dfe3e9; }
    .auth__close {
      width: 22px;
      height: 22px; }
    .auth-logo {
      margin-top: 10px; }
      .auth-logo__img {
        max-width: 180px;
        height: 58px; }
    .auth .form__title {
      margin: 8px auto 16px;
      font-size: 24px;
      max-width: 230px; }
    .auth__button {
      padding: 14px 0; }
    .auth .common-input-wraper {
      padding-bottom: 14px; }
      .auth .common-input-wraper input {
        padding: 14px 13px;
        font-size: 16px; }
    .auth .common-button {
      padding: 14px 0; }
    .auth .sign-up-form .form__text.sign-in {
      padding-top: 12px;
      padding-bottom: 12px; }
    .auth-footer {
      padding: 15px 25px; } }

@media (max-width: 768px) {
  .auth {
    margin: 0 auto;
    padding: 0 34px 60px;
    background-color: #ffffff; }
    .auth-logo__img {
      max-width: 126px;
      height: 44px; }
    .auth__form {
      padding: 0; }
    .auth .form__title {
      margin-top: 8px;
      margin-bottom: 18px;
      font-size: 18px; }
    .auth .form__link {
      font-size: 12px; }
    .auth__button {
      padding: 12px 0;
      line-height: 19px;
      font-size: 14px; }
    .auth .common-input-wraper {
      padding-bottom: 10px; }
      .auth .common-input-wraper input {
        padding: 12px 13px;
        font-size: 14px;
        line-height: 14px; }
    .auth .common-button {
      padding: 12px 13px;
      font-size: 14px;
      line-height: 19px; }
    .auth .sign-in-form .form__text,
    .auth .sign-up-form .form__text {
      font-size: 12px;
      line-height: 14px; }
    .auth .sign-in-form .privacy-info,
    .auth .sign-up-form .privacy-info {
      max-width: 220px; }
      .auth .sign-in-form .privacy-info .form__link,
      .auth .sign-up-form .privacy-info .form__link {
        font-size: 12px;
        line-height: 14px; }
    .auth__close {
      width: 18px;
      height: 18px;
      margin-top: 18px;
      margin-right: -15px; }
    .auth .sign-in .forgot-link,
    .auth .sign-in .form__text {
      font-size: 12px;
      line-height: 10px; }
    .auth .sign-in .forgot-link {
      margin: 10px auto; }
    .auth__button_fb span {
      transform: translateY(1px); }
    .auth-footer {
      padding: 15px;
      font-size: 16px;
      line-height: 15px; }
    .auth .sign-reset-form__title, .auth .sign-reset-form__text,
    .auth .reset-your-password__title,
    .auth .reset-your-password__text {
      font-size: 14px; }
    .auth .sign-reset-form .btn,
    .auth .sign-reset-form .input,
    .auth .reset-your-password .btn,
    .auth .reset-your-password .input {
      margin-bottom: 0;
      padding: 12px 13px;
      font-size: 14px; }
    .auth .sign-reset-form .btn-forgot,
    .auth .reset-your-password .btn-forgot {
      line-height: 19px; }
    .auth .sign-reset-form .block-errors,
    .auth .reset-your-password .block-errors {
      top: 5px; }
  .overlay-auth {
    display: block;
    padding: 28px 10px 50px; } }

@media (max-width: 480px) {
  .overlay {
    width: 100%;
    height: 100%;
    box-sizing: border-box; }
    .overlay-y {
      overflow-y: auto; }
  .auth {
    margin: 0 auto;
    padding: 0 34px 60px;
    box-shadow: none;
    min-height: 565px;
    border: none;
    border-top: 1px solid #dfe3e9;
    box-sizing: border-box; }
    .auth__close {
      margin-right: -15px; }
    .auth__top {
      flex-direction: row;
      align-items: center;
      padding: 20px 0; } }

.sign {
  position: relative;
  padding: 10px; }

.sign-delimiter {
  display: flex;
  margin: 6px 0 8px 0;
  align-items: center; }
  .sign-delimiter__line {
    width: 115px;
    height: 1px;
    background-color: #c7c7c7; }
  .sign-delimiter__or {
    text-align: center;
    flex-basis: 20%;
    color: #9b9b9b;
    font-size: 14px;
    text-transform: uppercase;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }

.sign-social {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .sign-social__button {
    width: 130px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 20%;
    border-radius: 1px;
    cursor: pointer;
    text-align: center;
    color: #ffffff; }
  .sign-social__button-facebook {
    background-color: #3b579d; }
  .sign-social__button-twitter {
    background-color: #2aa3ef; }
  .sign-social__icon {
    line-height: 36px;
    font-size: 30px; }

.sign-title {
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  color: #4a4a4a;
  margin: 15px 0 9px 0;
  font-family: proxima_nova_semibold, Helvetica, sans-serif; }

.sign-message {
  font-weight: 300;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: #9b9b9b;
  margin-bottom: 15px;
  font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .sign-message-bold {
    font-family: proxima_nova_bold, Helvetica, sans-serif; }

.forgot-link {
  display: inline-block;
  color: #4a90e2;
  margin: 0 auto 24px auto;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-family: proxima_nova_semibold, Helvetica, sans-serif; }

.sign-in-form {
  margin-top: 12px; }
  .sign-in-form .input-wrapper {
    margin-bottom: 10px;
    padding-bottom: 8px; }
    .sign-in-form .input-wrapper .input {
      margin-bottom: 0; }
  .sign-in-form .input-wrapper:last-child {
    margin-bottom: 500px; }

.sign-reset-form__title {
  margin-top: 8px;
  font-size: 14px;
  color: #4a4a4a;
  font-family: proxima_nova_semibold, Helvetica, sans-serif; }

.sign-reset-form__message {
  margin: 7px 0 19px 0;
  font-size: 11px;
  color: #9b9b9b;
  font-family: proxima_nova_regular, Helvetica, sans-serif; }

.sign-up-form {
  margin-top: 10px; }
  .sign-up-form .input:first-of-type {
    margin-bottom: 18px; }
  .sign-up-form .block-errors__text {
    bottom: 8px; }
  .sign-up-form button {
    margin-top: 3px; }

.sign-out__btn {
  width: 125px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #9b9b9b;
  cursor: pointer;
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: silver;
  border-image: none;
  border-image: initial;
  margin: auto;
  font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .sign-out__btn--primary {
    background-color: #f6a623;
    color: white;
    border-color: #f6a623; }

.sign-out__message {
  width: 236px;
  height: 24px;
  font-size: 20px;
  letter-spacing: -0.5px;
  text-align: center;
  font-weight: 500;
  color: #4a4a4a;
  margin: 67px auto 70px;
  font-family: proxima_nova_semibold, Helvetica, sans-serif; }

.widget250 .sign {
  padding: 0; }

.widget600 .sign {
  text-align: center; }
  .widget600 .sign-message {
    margin-top: 10px;
    color: #000;
    font-size: 14px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .widget600 .sign-delimiter {
    margin-top: 30px;
    margin-bottom: 30px; }
  .widget600 .sign .form-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .widget600 .sign .form-header__label {
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 11px;
      color: #9b9b9b;
      text-transform: uppercase; }
    .widget600 .sign .form-header__logo {
      width: 40px;
      height: 13px; }

.widget600 .sign-up-form .input[name='password']:nth-child(2) {
  margin: 0;
  margin-bottom: 5px; }

.widget600 .sign-up-form .input:first-of-type {
  margin-bottom: 18px; }

.widget600 .sign-up-form .input:last-of-type {
  margin-bottom: 50px; }

.widget600 .sign__logo {
  display: inline-block;
  text-align: left; }
  .widget600 .sign__logo__text {
    letter-spacing: 1.7px;
    font-size: 6px;
    text-transform: uppercase;
    color: #4a4a4a;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .widget600 .sign__logo__img {
    margin-top: 6px;
    width: 100px;
    height: 33px; }

.widget600 .sign-title {
  color: #4a4a4a;
  font-size: 14px;
  line-height: 1.43;
  text-align: center; }

.widget600 .forgot-link {
  margin-top: 23px; }

.widget600 .input-wrapper {
  margin-bottom: 0;
  padding-bottom: 15px; }

.circle-box {
  position: absolute;
  width: 100%;
  max-width: 325px;
  height: 100%;
  max-height: 325px;
  bottom: 0;
  right: -85%;
  border-radius: 50%;
  background: #da1781; }
  .circle-box__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 28px; }
  .circle-box__title {
    margin-bottom: 12px;
    font-size: 26px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: 0.1px;
    text-align: center;
    color: #ffffff;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }
  .circle-box__text {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 200;
    line-height: 1.25;
    letter-spacing: 0.1px;
    text-align: center;
    color: #ffffff;
    font-family: proxima_nova_light, Helvetica, sans-serif; }
  .circle-box__link {
    padding: 18px 28px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 2px;
    background: #2ac7fd;
    font-family: proxima_nova_regular, Helvetica, sans-serif; }

@media (max-width: 1366px) {
  .circle-box {
    max-width: 250px;
    max-height: 250px;
    right: -65%; }
    .circle-box__title {
      margin-bottom: 10px;
      font-size: 20px; }
    .circle-box__text {
      margin-bottom: 18px;
      font-size: 18px; }
    .circle-box__link {
      padding: 10px 18px;
      font-size: 18px; } }

@media (max-width: 1024px) {
  .circle-box {
    max-width: 220px;
    max-height: 220px;
    right: -55%; }
    .circle-box__title {
      margin-bottom: 8px;
      font-size: 18px; }
    .circle-box__text {
      margin-bottom: 16px;
      font-size: 16px; }
    .circle-box__link {
      padding: 8px 16px;
      font-size: 16px; } }

@media (max-width: 960px) {
  .circle-box {
    display: none; } }

.form {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .form__title {
    display: block;
    margin-bottom: 20px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    font-size: 17px; }
  .form__link {
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 12px;
    color: #0baff0; }
  .form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 26px;
    align-items: flex-end; }
    .form-row__part {
      width: 49%; }
      .form-row__part_small {
        max-width: 110px;
        width: 100%; }
        .form-row__part_small .Select-arrow-zone {
          padding-left: 8px;
          border-left: 1px solid #c7c7c7; }
      .form-row__part.card {
        max-width: 174px;
        width: 100%; }
      .form-row__part.card .form-control__input, .form-row__part.cvv .form-control__input {
        padding-left: 15px; }
        .form-row__part.card .form-control__input::-webkit-input-placeholder, .form-row__part.cvv .form-control__input::-webkit-input-placeholder {
          font-size: 16px; }
        .form-row__part.card .form-control__input::-moz-placeholder, .form-row__part.cvv .form-control__input::-moz-placeholder {
          font-size: 16px; }
        .form-row__part.card .form-control__input:-ms-input-placeholder, .form-row__part.cvv .form-control__input:-ms-input-placeholder {
          font-size: 16px; }
        .form-row__part.card .form-control__input:-moz-placeholder, .form-row__part.cvv .form-control__input:-moz-placeholder {
          font-size: 16px; }
    .form-row__one-third {
      width: 32%; }
    .form-row__two-third {
      width: 66%; }
  .form-edit-btn {
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 14px;
    color: #0baff0;
    line-height: 14px;
    cursor: pointer; }
  .form-control {
    width: 100%; }
    .form-control__top {
      display: flex;
      justify-content: space-between; }
      .form-control__top > .form__title {
        margin: 0; }
    .form-control__label {
      display: inline-block;
      margin-bottom: 13px;
      text-indent: 3px;
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      font-size: 12px;
      color: #9b9b9b;
      text-transform: uppercase; }
    .form-control-tab {
      width: 100%;
      margin-top: 12px; }
      .form-control-tab__top {
        display: flex;
        justify-content: space-between;
        padding-right: 105px;
        margin-bottom: 10px; }
        .form-control-tab__top span {
          font-family: proxima_nova_light, Helvetica, sans-serif;
          font-size: 12px;
          color: #4a4a4a; }
      .form-control-tab .menu-shipping-address, .form-control-tab .menu-payment {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 50px;
        background-color: #fafafc;
        border: solid 1px #c7c7c7;
        border-radius: 2px; }
        .form-control-tab .menu-shipping-address:after, .form-control-tab .menu-payment:after {
          position: absolute;
          content: '';
          width: 5px;
          height: 5px;
          border-right: 2px solid #a8aab7;
          border-bottom: 2px solid #a8aab7;
          border-radius: .5px;
          top: 50%;
          right: 12px;
          transform: translateY(-50%) rotate(45deg); }
      .form-control-tab .menu-payment {
        height: 42px; }
      .form-control-tab .shipping-tab, .form-control-tab .payment-tab {
        position: absolute;
        width: 100%;
        top: 100%;
        left: -1px;
        height: 0;
        visibility: hidden;
        overflow: hidden;
        background: #ffffff;
        border: solid 1px #c7c7c7; }
        .form-control-tab .shipping-tab.show, .form-control-tab .payment-tab.show {
          height: auto;
          visibility: visible;
          transition: .4s;
          overflow-y: 100%;
          z-index: 201; }
        .form-control-tab .shipping-tab__add, .form-control-tab .payment-tab__add {
          padding: 2px 0;
          font-size: 14px;
          color: #0baff0;
          margin-bottom: 5px; }
        .form-control-tab .shipping-tab__block, .form-control-tab .payment-tab__block {
          position: relative;
          height: 50px;
          background-color: #fafafc;
          border: solid 1px #c7c7c7;
          border-radius: 2px; }
        .form-control-tab .shipping-tab__box, .form-control-tab .payment-tab__box {
          position: relative;
          height: 50px;
          box-sizing: border-box; }
          .form-control-tab .shipping-tab__box:hover, .form-control-tab .shipping-tab__box:active, .form-control-tab .payment-tab__box:hover, .form-control-tab .payment-tab__box:active {
            background: #fafafc; }
        .form-control-tab .shipping-tab__label, .form-control-tab .payment-tab__label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px 18px;
          cursor: pointer; }
        .form-control-tab .shipping-tab__input, .form-control-tab .payment-tab__input {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          opacity: 0; }
        .form-control-tab .shipping-tab__name, .form-control-tab .payment-tab__name {
          font-family: proxima_nova_semibold, Helvetica, sans-serif;
          font-size: 14px; }
        .form-control-tab .shipping-tab__address, .form-control-tab .payment-tab__address {
          margin-top: 10px;
          font-family: proxima_nova_regular, Helvetica, sans-serif;
          font-size: 12px; }
      .form-control-tab .shipping-tab__input:checked + .shipping-tab__label:after {
        position: absolute;
        display: block;
        content: '';
        width: 5px;
        height: 8px;
        top: 50%;
        right: 8px;
        border-right: 2px solid #0baff0;
        border-bottom: 2px solid #0baff0;
        border-radius: .5px;
        transform: translateY(-50%) rotate(45deg); }
      .form-control-tab .payment-tab {
        background-color: #fafafc;
        border: solid 1px #c7c7c7;
        border-radius: 2px; }
        .form-control-tab .payment-tab__left {
          display: flex;
          align-items: center; }
        .form-control-tab .payment-tab__right {
          font-size: 14px; }
        .form-control-tab .payment-tab__box {
          height: 42px; }
        .form-control-tab .payment-tab__label {
          padding: 10px 105px 10px 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          box-sizing: border-box; }
        .form-control-tab .payment-tab__card-icon {
          width: 37px;
          height: 22px; }
        .form-control-tab .payment-tab__card-ending {
          font-size: 14px;
          margin-left: 12px; }
    .form-control__select {
      width: 100%;
      background-color: #fafafc;
      border-radius: 2px; }
      .form-control__select .Select-placeholder {
        line-height: 44px;
        background-image: linear-gradient(to top, #f2f4f7, #ffffff); }
      .form-control__select .Select-control {
        height: 44px;
        max-width: 110px;
        width: 100%; }
        .form-control__select .Select-control .Select-value {
          line-height: 44px;
          font-family: proxima_nova_regular, Helvetica, sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: left;
          color: #354052;
          background-image: linear-gradient(to top, #f2f4f7, #ffffff); }
      .form-control__select .Select.is-focused:not(.is-open) > .Select-control {
        box-shadow: none;
        border-color: #d9d9d9 #ccc #b3b3b3; }
      .form-control__select .Select-arrow {
        border-color: transparent #a8aab7 #a8aab7 transparent;
        border-style: solid;
        border-width: 0 2px 2px 0;
        display: inline-block;
        height: 5px;
        width: 5px;
        position: relative;
        transform: rotate(45deg);
        border-radius: .5px; }
        .form-control__select .Select-arrow-zone {
          padding-right: 0;
          padding-left: 0; }
      .form-control__select .Select.is-open .Select-arrow {
        border-color: #a8aab7 transparent transparent #a8aab7;
        border-style: solid;
        border-width: 2px 0 0 2px;
        display: inline-block;
        height: 5px;
        width: 5px;
        position: relative;
        transform: rotate(45deg);
        border-radius: .5px; }
      .form-control__select .Select-menu {
        max-height: 160px;
        overflow-y: auto; }
    .form-control__input {
      width: 100%;
      padding: 13px;
      border: 1px solid #c7c7c7;
      border-radius: 2px;
      font-family: proxima_nova_regular, Helvetica, sans-serif;
      color: #4a4a4a;
      font-size: 14px;
      box-sizing: border-box; }
      .form-control__input:focus {
        outline: none; }
      .form-control__input::-webkit-input-placeholder {
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        color: #c6c6c6;
        font-size: 14px; }
      .form-control__input::-moz-placeholder {
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        color: #c6c6c6;
        font-size: 14px; }
      .form-control__input:-ms-input-placeholder {
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        color: #c6c6c6;
        font-size: 14px; }
      .form-control__input:-moz-placeholder {
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        color: #c6c6c6;
        font-size: 14px; }
  .form-logos {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 50%;
    bottom: -10px;
    margin-left: auto; }
    .form-logos__icon {
      margin-left: 7px;
      width: 50px;
      height: 30px; }

.sign-up-form .common-input-wraper {
  margin-bottom: 9px; }

.flex-start {
  align-items: flex-start; }

.form-settings-shipping .state {
  width: 100%; }

.form-settings-shipping .shipping-wrapper input::-webkit-input-placeholder {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 14px;
  text-align: left;
  color: #9b9b9b; }

.form-settings-shipping .shipping-wrapper input::-ms-input-placeholder {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 14px;
  text-align: left;
  color: #9b9b9b; }

.form-settings-shipping .shipping-wrapper input::placeholder {
  font-family: proxima_nova_regular, Helvetica, sans-serif;
  font-size: 14px;
  text-align: left;
  color: #9b9b9b; }

@media all and (max-width: 480px) {
  .form-settings-shipping .state, .form-settings-shipping .zip {
    width: 48%;
    padding-bottom: 0;
    margin-top: 5px; } }

.settings-container {
  position: relative;
  display: flex;
  padding: 52px 42px;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 0; }
  .settings-container--quick-to {
    max-width: 1180px; }

@media (max-width: 480px) {
  .settings-container {
    display: block;
    padding: 0; }
  .settings-header {
    display: none; }
  .settings-wrapper .mp-footer {
    display: none; } }

.market-place-settings {
  position: relative;
  display: flex;
  padding: 52px 42px;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 0; }
  .market-place-settings-menu {
    display: flex;
    flex-direction: column;
    width: 15%; }
    .market-place-settings-menu__item {
      position: relative;
      margin-top: 25px;
      padding: 5px 8px;
      font-family: proxima_nova_light, Helvetica, sans-serif;
      color: #9b9b9b;
      text-decoration: none; }
      .market-place-settings-menu__item:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 0;
        width: 3px;
        background-color: #da1781;
        transition: .3s; }
      .market-place-settings-menu__item.is-active {
        color: #da1781; }
        .market-place-settings-menu__item.is-active:before {
          height: 100%; }
  .market-place-settings-content {
    width: 80%;
    padding-bottom: 50px; }
    .market-place-settings-content__actions {
      align-self: flex-end;
      margin-top: 46px; }
      .market-place-settings-content__actions .button {
        width: 160px;
        padding: 11px 50px;
        border: 1px solid;
        border-radius: 2px;
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        font-size: 18px;
        outline: none;
        cursor: pointer; }
        .market-place-settings-content__actions .button_cancel {
          background-color: transparent;
          color: #c6c6c6;
          border-color: #c6c6c6; }
        .market-place-settings-content__actions .button_save {
          margin-left: 21px;
          background-color: #da1781;
          color: #ffffff; }
      .market-place-settings-content__actions .disabled {
        pointer-events: none;
        background-color: gray; }
    @media (max-width: 480px) {
      .market-place-settings-content {
        width: 100%; } }
    .market-place-settings-content .shipping-tab__name,
    .market-place-settings-content .shipping-tab__address {
      font-size: 14px; }
  .market-place-settings .form-settings-payment .row-card-mobile-view .form-row-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 242px;
    width: 100%; }
    .market-place-settings .form-settings-payment .row-card-mobile-view .form-row-block:first-of-type {
      max-width: 305px; }

.user-menu-settings {
  position: relative;
  text-align: center; }
  .user-menu-settings__title {
    padding-top: 22px;
    padding-bottom: 26px;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  .user-menu-settings .user-menu-top__back {
    position: absolute;
    top: 22px;
    left: 12px; }

.market-place-settings-content .market-place-settings-content__actions .common-button {
  padding: 12px 50px; }
  .market-place-settings-content .market-place-settings-content__actions .common-button:last-child {
    margin-left: 20px; }

@media (max-width: 480px) {
  .form-settings-shipping .two-inputs-row:first-child {
    flex-direction: column; }
    .form-settings-shipping .two-inputs-row:first-child .common-input-wraper {
      margin: 0; }
  .form-settings-shipping .market-place-settings-content__actions {
    margin-top: 15px; }
    .form-settings-shipping .market-place-settings-content__actions .common-button {
      margin: 5px; } }

@media (max-width: 768px) {
  .market-place-settings .form-settings-payment .row-card-mobile-view {
    flex-direction: column;
    align-items: flex-start; }
    .market-place-settings .form-settings-payment .row-card-mobile-view .form-row-block:first-of-type {
      margin-bottom: 20px; } }

@media (max-width: 480px) {
  .tabs-container {
    position: fixed;
    height: 100%;
    width: 100%;
    left: -100%;
    top: 0;
    transition: .5s;
    background: #ffffff;
    margin-top: 61px;
    z-index: 2; }
    .tabs-container.menu-opened {
      left: 0; } }

.settings-tab {
  position: relative;
  margin-top: 25px;
  padding: 5px 8px;
  font-family: proxima_nova_light, Helvetica, sans-serif;
  color: #9b9b9b;
  font-size: 14px; }
  .settings-tab:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 0;
    width: 3px;
    background-color: #da1781;
    transition: .3s; }
  .settings-tab__link {
    display: inline-block;
    cursor: pointer; }
  .settings-tab.tab_active {
    color: #da1781;
    font-family: proxima_nova_bold, Helvetica, sans-serif; }
    .settings-tab.tab_active:before {
      height: 100%; }
  @media (max-width: 480px) {
    .settings-tab {
      margin-top: 0;
      padding: 22px 12px;
      border-top: 1px solid #c7c7c7;
      color: #354052;
      font-family: proxima_nova_semibold, Helvetica, sans-serif; }
      .settings-tab:before {
        display: none; }
      .settings-tab.tab_active {
        color: #354052;
        font-family: proxima_nova_semibold, Helvetica, sans-serif; }
        .settings-tab.tab_active:before {
          display: none; }
      .settings-tab:after {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        right: 12px;
        border-right: 1px solid #c7c7c7;
        border-bottom: 1px solid #c7c7c7;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        display: block; } }

.aside-mobile-header {
  position: relative;
  text-align: center; }
  .aside-mobile-header.menu-opened {
    position: fixed;
    top: 0;
    background: #ffffff;
    width: 100%;
    z-index: 2; }
  .aside-mobile-header .header-arrow {
    position: absolute;
    top: 20px;
    left: 12px;
    width: 16px;
    height: 16px;
    background-image: url(/static/media/back.64173da3.svg);
    background-size: cover;
    cursor: pointer; }
  .aside-mobile-header:before {
    position: fixed;
    content: '';
    left: 0;
    top: 0;
    height: 5px;
    width: 100%;
    background-color: #da1781;
    z-index: 1; }
  .aside-mobile-header .header-title {
    display: inline-block;
    padding: 22px 0;
    font-size: 17px;
    color: #4a4a4a;
    font-family: proxima_nova_semibold, Helvetica, sans-serif; }
  @media (min-width: 481px) {
    .aside-mobile-header {
      display: none; } }

.aside {
  margin-top: 50px;
  max-width: 150px; }
  @media (max-width: 480px) {
    .aside {
      margin-top: 0;
      width: 100%;
      max-width: 100%; } }

.no-avatar-logo {
  position: relative;
  height: 122px;
  width: 122px;
  padding: 27px 0;
  border: 1px solid #c7c7c7;
  border-radius: 999rem;
  text-align: center;
  box-sizing: border-box; }
  .no-avatar-logo__icon {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url(/static/media/plus.8bc4624a.svg);
    background-size: cover; }
  .no-avatar-logo__text {
    display: block;
    margin-top: 18px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 17px;
    color: #9b9b9b; }
  @media (max-width: 480px) {
    .no-avatar-logo {
      margin: 0 auto; } }

.fb-avatar {
  height: 122px;
  width: 122px;
  border: 1px solid #c7c7c7;
  border-radius: 999rem;
  box-sizing: border-box;
  padding: 0; }
  .fb-avatar img {
    height: 120px;
    border-radius: 999rem; }

