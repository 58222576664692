@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.billing-form {
    .is-equal-with-shipping__checkbox {
        padding-bottom: 15px;

        .klickly-checkbox__label {
            font-family: $font-light;
            font-size: 14px;
            color: $charcoal-grey;
        }
    }

    .accordion {
        margin: 0 0 15px 0;

        .accordion-default-item {
            display: block;

            &:hover {
                cursor: auto;
            }

            .card-item {
                &-logo .radio-input {
                    display: none;
                }

                &--change-address {
                    &:hover {
                        cursor: auto;
                    }

                    .card-item__info {
                        width: 100%;
                    }

                    .card-item__address {
                        display: none;
                    }
                }
            }
        }
    }

    .card-element__wrapper {
        font-family: 'proxima_nova_semibold', Helvetica, sans-serif;
        position: relative;

        .error-message {
            min-height: 10px;
            font-size: 10px;
            text-align: right;
            line-height: 1;
            color: #f64316;
        }
    }
}

.form-logos {
    text-align: right;
    padding-bottom: 20px;

    &__icon {
        width: 50px;
        height: 30px;
        margin-left: 2px;
    }
}
