@import "../../../../styles/variables";
@import "../../../../styles/colors";
@import "../../../../styles/mixins";

.purchase-complete {
    @include flex(column, center, flex-start);
    padding: 20px 0 130px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    @media (max-width: 768px) and (min-width: 481px) {
        width: 80%;
        margin: 0 auto;
        padding-top: 80px;
        padding-bottom: 90px;
    }

    @media (max-width: 667px) {
        padding-top: 40px;
        padding-bottom: 70px;
    }

    &__message {
        width: 50%;
        max-width: 420px;
    }

    &__message--widget {
        display: flex;
        width: 100%;
        max-width: 470px;
    }

    &__message--widget .purchase-complete__title {
        font-size: 18px;
        line-height: 1;
        text-align: left;
    }

    &__message--widget .purchase-complete__img {
        width: 86px;
        height: 86px;
    }

    &__message--widget > div {
        margin-left: 14px;
    }

    &__text {
        margin-top: 4px;
        font-family: $font-regular;
        font-size: 15px;
        line-height: normal;
        color: $purpley-grey;
    }

    &__button {
        margin-top: 30px;
        width: 100%;
        max-width: 310px;
        padding: 10px 0;
        font-size: 16px;
        line-height: 20px;
    }

    &__img {
        max-width: 142px;
        max-height: 142px;
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;
        @media (max-width: 960px) {
            max-width: 120px;
            max-height: 120px;
        }

        @media (max-width: 667px) {
            max-width: 75px;
            max-height: 75px;
            margin-bottom: 15px;
        }
    }

    &__title {
        margin-top: 20px;
        text-align: center;
        color: $charcoal-grey;
        font-size: 30px;
        line-height: 1.38;
        font-family: $font-semibold;

        @media (max-width: 960px) and (min-width: 668px) {
            font-size: 24px;
        }

        @media (max-width: 667px) {
            font-size: 18px;
        }
    }

    &__divider {
        height: 100%;
        width: 100%;
        max-width: 510px;
        background-color: $gray-in-active;
        margin: 30px 0;
        margin-right: auto;
        min-height: 2px;

        @media (max-width: 768px) {
            min-height: 2px;
            width: 100%;
            max-width: 60%;
            margin-top: 25px;
            margin-bottom: 35px;
        }

        @media (max-width: 480px) {
            max-width: 100%;
        }
    }

    &-form {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 510px;
        margin-top: 0;
        text-align: left;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &-form__title {
        margin-top: 8px;
        margin-bottom: 20px;
        font-family: $font-semibold;
        font-size: 20px;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #4a4a4a;
    }

    &-form__link {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.62;
        letter-spacing: normal;
        cursor: pointer;
        color: $blue;
    }

    .purchase-complete-form .common-input-wraper input {
        padding: 9px 12px;
        font-size: 16px;

        @media (max-width: 667px) {
            font-size: 14px;
        }
    }

    &-form .common-button {
        width: 100%;
        padding: 9px 0;
        margin: 25px 0 8px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 23px;

        &:disabled {
            background: $gray-in-active;
            color: $charcoal-grey;
        }


        @media (max-width: 667px) {
            font-size: 14px;
        }
    }
}

// new styles for quickTo purchase completed step.

.purchase-complete {

    &__content {
        @include flex(column);
        margin-right: auto;
    }

    &__item {
        padding: 17px 0 20px;
        border-top: 1px solid $silver-three;

        &--summary .purchase-information__text {
            font-style: italic;
        }

        @media (min-width: 668px) {
            padding: 0;
            border-top: none;
        }
    }

    &__heading {
        font-family: $font-regular;
        font-size: 13px;
        letter-spacing: 1.08px;
        line-height: 1;
        text-transform: uppercase;
        color: $charcoal-grey;

        @media (min-width: 668px) {
            display: none;
        }
    }
}

.purchase-thanks {
    @include flex;
    max-width: 510px;
    margin: 0 auto;
    padding-bottom: 14px;

    @media (max-width: 667px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
        text-align: center;
    }

    &__content {
        padding-top: 24px;
        padding-left: 17px;
        @media (max-width: 667px) {
            padding-top: 15px;
            padding-left: 0;
        }
    }

    &__heading {
        font-family: $font-semibold;
        font-size: 20px;
        line-height: 1;
        color: $charcoal-grey;
    }

    &__text {
        padding: 5px 0 24px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.38;
        color: $purpley-grey;
        @media (max-width: 667px) {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &__link {
        color: $blue;
        text-decoration: none;
    }

    &__action {
        height: 44px;
        font-family: $font-regular;
        font-size: 17px;
        width: 100%;
        max-width: 344px;
        color: $white;
        border-radius: 2px;
        background-color: $cerise;
        @media (max-width: 667px) {
            max-width: 276px;
        }
    }
}

.purchase-info {
    padding-top: 20px;
    width: 100%;

    &__heading {
        padding-bottom: 7px;
        font-family: $font-semibold;
        font-size: 14px;
        line-height: 1.29;
        color: black;
    }

    &__text {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.43;
        color: $charcoal-grey;

        &.bold {
            font-family: $font-semibold;
        }

        @media (max-width: 667px) {
            font-size: 12px;
        }
    }

    &__item {
        padding-top: 20px;
        @include flex(column);
        max-width: 300px;
        flex-grow: 1;

        &--large {
            max-width: 100%;
        }

        &--large .purchase-information__text {
            @media (max-width: 667px) {
                display: inline;
                font-size: 14px;
            }
        }

        @media (max-width: 667px) {
            max-width: 100%;
        }
    }

    &__row {
        @include flex(row, space-between, flex-start);
        width: 100%;
    }

    &__link {
        color: $blue;
        text-decoration: none;
    }

    &__list {
        padding-left: 8px;
        font-family: $font-regular;
        font-size: 12px;
        line-height: 20px;
    }
}

.purchase-summary {

    &__row {
        @include flex(row, space-between, center);

        &.total {
            margin-top: 5px;
            padding: 7px 0 12px;
            border-top: 1px solid $silver-one;
        }

        &.total .purchase-summary__text {
            font-size: 16px;
            line-height: 1.13;
        }

        &.total .purchase-summary__text:first-child {
            font-family: $font-semibold;
        }

    }

    &__text {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.43;
        color: $charcoal-grey;
    }
}

.purchase-product {
    @include flex;
    padding: 18px 0 12px;

    &__img-wrapper {
        @include flex(row, center, center);
        width: 85px;
        height: 85px;
        border: 1px solid $pinkish-grey-dk;
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: cover;
    }

    &__info {
        padding-left: 10px;
        flex-grow: 1;
    }

    &__title {
        max-width: 113px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $font-semibold;
        font-size: 14px;
        line-height: 1;
        color: $charcoal-grey;
    }

    &__price {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1;
        color: $charcoal-grey;
    }

    &__brand {
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-top: 2px;
        font-family: $font-regular;
        font-size: 14px;
        color: $purpley-grey;
    }

    &__option-box {
        @include flex(column);
        padding-top: 7px;
    }

    &__option {
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-family: $font-regular;
        font-size: 10px;
        line-height: 1.2;
        color: $charcoal-grey;
    }
}
