@import '../../../styles/colors.scss';
@import '../../../styles/generals';

.common-button {
    font-family: proxima_nova_light, Helvetica, sans-serif;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 2px;
    padding: 12px;
    color: $white;
    letter-spacing: .5px;

    &.transparent {
        background-color: transparent;
        color: $pinkish-grey-dk;
        border: 1px solid $pinkish-grey-dk;
    }

    &.dark-pink {
        background-color: $cerise;
    }

    &.gray {
        background-color: #c6c6c6;
        color: $black;
    }

    &[disabled] {
        pointer-events: none;
        background-color: #e2e2e2;
    }
    // test
    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
}

.btn {
    position: relative;
    border: none;
    outline: none;
    text-align: center;
    cursor: pointer;
    background: transparent;

    &-orange {
        width: 100%;
        height: 42px;
        min-height: 42px;
        border-radius: 1px;
        background: #ffaf06;
        font-family: proxima_nova_bold, Helvetica, sans-serif;
        font-size: 17px;
        color: #ffffff;
    }

    &-arrow {
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 14px;
            width: 8px;
            height: 8px;
            border-top: 3px solid #fff;
            border-right: 3px solid #fff;
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &-continue {
        position: absolute;
        bottom: 0;
        right: 0;
        padding-right: 14px;
        font-size: 18px;
        color: #f5a623;
        text-transform: uppercase;
        cursor: pointer;
        display: inline-block;
        font-family: proxima_nova_bold, Helvetica, sans-serif;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 10px;
            height: 10px;
            margin-top: -2px;
            border-top: 3px solid #f5a623;
            border-right: 3px solid #f5a623;
            transform: translateY(-50%) rotate(45deg);
            box-sizing: border-box;
        }
    }

    &-edit-number {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        font-size: 13px;
        text-align: left;
        color: #4990e2;
        outline: none;
        border: none;
        background: transparent;
    }
    &-arrow-back {
        width: 17px;
        height: 17px;
        margin-right: auto;
        z-index: 1;
        background: url('../../../images/arrow-left.png') 0 0 no-repeat;
    }

    &-fixed-bottom {
        position: absolute;
        top: 12px;
        max-width: 278px;
        width: 100%;
        z-index: 4;
        //margin-top: 40px;
    }
}
