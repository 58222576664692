@import "../../../../styles/colors";
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

$logo-width: 190px;
$logo-height: 66px;

.mp-sign-in {
    @include flex(column, flex-start, center);
    width: 100%;
    padding: 60px 0 100px;

    @media (max-width: 480px) {
        padding: 40px 0 50px;
    }

    &__logo-wrapper {
        width: $logo-width;
        height: $logo-height;

        @media (max-width: 480px) {
            display: none;
        }
    }

    &__logo {
        width: 100%;
        height: auto;
        max-height: 100%;
    }

    &__box {
        @include flex(row, center, stretch);
        width: 100%;
        padding: 42px 20px 0;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        @media (max-width: 480px) {
            padding: 0 12px;
        }
    }

    &__part {
        flex-grow: 1;
        width: 50%;
        max-width: 480px;
        position: relative;

        &:not(.single):first-child {
            padding-right: 60px;
            border-right: 1px solid $gray-in-active;
        }

        &:not(.single):last-child {
            padding-left: 60px;
            border-left: 1px solid $gray-in-active;
        }

        @media (max-width: 768px) {
            width: 100%;
            max-width: 70%;

            &:not(.single):first-child {
                padding-right: 0;
                padding-bottom: 40px;
                border-right: none;
                border-bottom: 2px solid $gray-in-active;
            }

            &:not(.single):last-child {
                padding-left: 0;
                border-left: none;
            }
        }

        @media (max-width: 480px) {
            max-width: 100%;

            &:not(.single):first-child {
                padding-bottom: 40px;
            }

            &:not(.single):last-child {
                padding-top: 15px;
            }
        }

        &.single {
            padding: 0;
            border: none;
        }
    }

    &__form {
        @include flex(column, space-between, center);
        position: relative;
        height: 100%;
        text-align: center;
    }

    &__form-content {
        width: 100%;
    }

    &__form-divider {
        display: inline-block;
        padding: 6px;
        margin: 5px auto 0;
        font-family: $font-semibold;
        font-size: 19px;
        text-transform: uppercase;
    }

    &__title {
        margin: 8px 0 20px;
        font-family: $font-semibold;
        font-size: 30px;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: $charcoal-grey;

        @media (max-width: 1024px) and (min-width: 961px) {
            font-size: 28px;
        }

        @media (max-width: 960px) and (min-width: 481px) {
            font-size: 24px;
        }
        
        @media (max-width: 480px) {
            margin-bottom: 10px;
            font-size: 18px;
        }
    }

    &__subtitle {
        max-width: 300px;
        margin: 0 auto;
        font-family: $font-regular;
        font-size: 24px;
        font-style: italic;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: $biscay;

        @media (max-width: 1024px) and (min-width: 961px) {
            font-size: 22px;
        }

        @media (max-width: 960px) and (min-width: 481px) {
            max-width: 250px;
            font-size: 18px;
        }

        @media (max-width: 480px) {
            font-size: 14px;
        }
    }

    &__fb {
        margin-top: 25px;

        @media (max-width: 480px) {
            margin-top: 12px;
            margin-bottom: 0;
            font-size: 18px;
        }
    }

    &__bottom-label {
        position: absolute;
        max-width: 100%;
        bottom: 0;
        left: 50%;
        transform: translate(calc(-50% - 30px), 34px);
        width: 100%;
        font-family: $font-regular;
        font-size: 20px;
        line-height: 1.54;
        text-align: center;
        color: $purpley-grey;

        @media (max-width: 960px) and (min-width: 481px) {
            font-size: 18px;
        }

        @media (max-width: 768px) and (min-width: 481px) {
            font-size: 18px;
            transform: translate(calc(-50%), -6px);
        }

        @media (max-width: 480px) {
            font-size: 14px;
            transform: translate(calc(-50%), -10px);
        }
    }


    // ---------- other elements overrides

    .sign-up-form .common-input-wraper {
        margin-bottom: 0;
    }

    .sign-in {
        width: 100%;
    }

    .common-input-wraper,
    .common-button {
        margin-top: 25px;
    }

    .mp-sign-in__part .common-input-wraper input {
        width: 100%;
        height: auto;
        padding: 18px 16px;
        font-size: 20px;

        @media (max-width: 960px) {
            font-size: 18px;
            padding: 16px;
        }

        @media (max-width: 480px) {
            padding: 13px 16px;
            font-size: 14px;
        }
    }

    .common-button {
        width: 100%;
        padding: 19px 16px;
        font-family: $font-regular;
        font-size: 26px;
        line-height: 23px;

        @media (max-width: 960px) {
            padding: 16px;
            font-size: 22px;
        }

        @media (max-width: 480px) {
            margin-top: 12px;
            padding: 12px 10px;
            font-size: 17px;
        }
    }

    .forgot-link {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 34px);
        margin: 0;
        font-family: $font-semibold;
        font-size: 20px;
        line-height: 1.62;
        letter-spacing: normal;
        color: $bright-sky-blue;
        white-space: nowrap;
        cursor: pointer;

        @media (max-width: 960px) and (min-width: 481px) {
            font-size: 18px;
        }

        @media (max-width: 667px) and (min-width: 481px) {
            left: 0;
            transform: translateY(34px);
        }

        @media (max-width: 480px) {
            font-size: 14px;
        }
    }

    .sign-reset-form, .reset-your-password {
        width: 100%;
    }

    .reset-your-password .common-input-wraper {
        margin-top: 16px;
    }

    .sign-reset-form__title, .reset-your-password__title {
        margin-top: 8px;
        margin-bottom: 20px;
        font-family: $font-semibold;
        font-size: 30px;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: $charcoal-grey;

        @media (max-width: 1024px) and (min-width: 961px) {
            font-size: 28px;
        }

        @media (max-width: 960px) and (min-width: 481px) {
            font-size: 24px;
        }

        @media (max-width: 480px) {
            font-size: 18px;
        }
    }

    .sign-reset-form__message {
        margin-top: 0;
        margin-bottom: 25px;
        font-family: $font-regular;
        font-size: 24px;
        font-style: italic;
        line-height: 1.17;
        letter-spacing: normal;
        color: $biscay;

        @media (max-width: 1024px) and (min-width: 961px) {
            font-size: 22px;
        }

        @media (max-width: 960px) and (min-width: 481px) {
            font-size: 18px;
        }

        @media (max-width: 480px) {
            max-width: 280px;
            margin: 0 auto;
            font-size: 14px;
        }
    }
}
