@import "/../../../styles/colors";
@import "/../../../styles/mixins";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.carousel {
    @include flex(row, space-between, stretch);
    width: 100%;
    height: 100%;

    @media(max-width: 667px) and (min-width: 600px) {
        padding: 20px 0;
    }

    @media (max-width: 480px) {
        flex-wrap: wrap;
    }

    &__slide {
        @include flex(row, center, center);
        width: 100%;
        position: relative;
    }

    &__slide-img {
        max-width: 550px;
        max-height: 550px;
        width: auto !important;

        @media (max-width: 1200px) and (min-width: 951px) {
            max-width: 450px;
            max-height: 450px;
        }

        @media (max-width: 950px) and (min-width: 600px) {
            max-width: 300px;
            max-height: 300px;
        }

        @media (max-width: 599px) {
            max-width: 350px;
            max-height: 350px;
        }
    }

    .image-view {
        width: 100%;
    }

    .image-view .product-carousel__item {
        margin: 0;
    }

    .image-view .product-carousel__item-img {
        margin: 0 auto;
        max-width: 100%;
        max-height: 200px;
        border: none;
    }
}

.desktop-zoomed-image-wrapper[style] {
    left: 103% !important;
    z-index: 9 !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4) !important;
    background: #fff;
}
