@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.circle-progress {
    position: relative;

    &__value-container {
        @include flex;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__value {
        margin-left: 4px;
        font-family: $font-bold;
        font-size: 28px;
        letter-spacing: 0;
        color: $charcoal-grey;
        transform: translateY(-3px);
    }

    &__logo {
        max-width: 22px;
        transform: translateY(3px);
    }

    &__container {
        transform: skew(-20deg) rotate(-90deg);
    }

    &__bar,
    &__fill {
        fill: none;
    }

    &__bar {
        stroke: $white-one;
    }

    &__fill {
        stroke: $cerise;
        stroke-linecap: round;
    }
}
