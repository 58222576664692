@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.info {
    position: relative;
    width: 100%;
    font-family: $font-regular;
    color: $charcoal-grey;
    min-height: 100%;

    @media (max-width: 667px) {
        position: static;
    }

    & * {
        box-sizing: border-box;
    }

    &__title {
        max-width: 330px;
        font-family: $font-semibold;
        @include multiline-text-ellipsis(24px, 1.15, 4);

        @media (max-width: 667px) {
            display: block;
            max-width: 90%;
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__brand {
        max-width: 330px;
        font-size: 20px;
        line-height: 1.35;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        color: $blue;

        @media (max-width: 667px) {
            max-width: 90%;
            font-size: 14px;
        }
    }

    &__tab-list {
        @include flex;
        margin-top: 20px;
        border-bottom: 2px solid $gray-in-active;

        @media (max-width: 668px) {
            margin-top: 12px;
        }
    }

    &__tab {
        position: relative;
        padding: 0 24px 3px;
        font-family: $font-semibold;
        font-size: 18px;
        line-height: normal;
        letter-spacing: .5px;
        text-transform: uppercase;
        cursor: pointer;
        color: $pinkish-grey-dk;

        &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 3px;
            left: 0;
            bottom: -2px;
            transition: .4s;
            background-color: transparent;
        }

        @media (max-width: 668px) {
            font-size: 14px;
        }
    }

    &__tab.is-active {
        color: $cerise;

        &:after {
            width: 100%;
            background-color: $cerise;
        }
    }


    // --------- product details


    &-details {
        margin-top: 12px;
        margin-bottom: 30px;
        overflow-y: auto;
    }

    &-details img {
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }

    &-details .scrollarea {
        width: auto;
        height: 100%;
        padding-right: 10px;
        max-height: 177px;

        @media (max-width: 768px) {
            max-height: 120px;
        }
    }

    &-details__text {
        font-size: 14px;
        line-height: 1.36;
        color: $purpley-grey;
    }


    // ---------- product buy


    &-buy {
        margin-top: 15px;
        @media (max-width: 668px) {
            margin-top: 10px;
        }
    }

    &-buy__option-list {
        @include flex;
        padding: 18px 0 10px;
        @media (max-width: 668px) {
            padding-top: 10px;
        }
    }

    &-buy__option {
        margin-right: 28px;
        font-family: $font-semibold;
        font-size: 14px;
        cursor: pointer;
        color: $purpley-grey;

        &.is-active {
            color: $charcoal-grey;
        }

        &.is-active--blink {
            animation: option 1s infinite;
        }
    }

    &-row {
        @include flex(row, space-between, center);
        padding: 5px 0;
        font-size: 14px;
        line-height: normal;
    }

    &-row__label {
        @include flex(row, flex-start, center);
    }

    &-row__label .icon {
        width: 14px;
        height: 14px;
        transform: translateX(5px);

        @media (max-width: 667px) {
            width: 11px;
            height: 11px;
            transform: translate(5px, 1px);
        }
    }

    &-row.list-price .info-row__value {
        text-decoration: line-through;
    }

    &-row.deal .info-row__value,
    &-row.coins .info-row__value,
    &-row.gift .info-row__value {
        font-family: $font-semibold;
        font-size: 18px;
        color: $cerise;
    }

    &-row.coins .info-row__value,
    &-row.gift .info-row__value {
        @include flex(row, center, center);
    }

    &-row__project-icon {
        width: 18px;
        height: 17px;
        margin-right: 4px;
        transform: translateY(-1px);
    }

    &-row__project-icon img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &-row.gift .info-row__label {
        font-family: $font-bold;
    }

    &-row.save .info-row__label {
        font-family: $font-semibold;
    }

    &-row.save .info-row__value {
        color: $cerise;
    }


    // ---------- product options scrollbar


    &-buy__option-values .scrollarea {
        padding-bottom: 12px;
    }

    &-buy__option-values .scrollarea .scrollbar-container {
        height: 6px;
        bottom: 2px;
        border-radius: 8px;
        z-index: 8;

        &.active, &:hover {
            background: rgba(128, 128, 128, 0.2);
            opacity: .6!important;
        }
    }

    &-buy__option-values .scrollarea .scrollbar-container .scrollbar {
        margin-top: 0;
        height: 6px;
        border-radius: 8px;
        background: rgba(0,0,0,.4);

        &:hover {
            cursor: pointer;
        }
    }


    // ---------- product actions


    &-action-box {
        margin-top: 25px;
    }

    &-action-wrapper {
        position: relative;
        height: 49px;
    }

    &-action-wrapper--cart {
        @extend .info-action-wrapper;
        margin-top: 12px;
    }

    &-action--favorites {
        position: absolute;
        top: 4px;
        transform: translateX(calc(-100% - 30px));
        margin-right: 10px;
        width: 30px;
        height: 25px;
        background-image: url(../../../../../images/heart-empty-button.svg);
        background-size: contain;
        cursor: pointer;

        @media (max-width: 667px) {
            transform: translateX(0);
            top: 14px;
            right: 0;
            margin-left: 0;
            width: 27px;
            height: 22px;
            background-image: url(../../../../../images/heart-empty.svg);
        }
    }

    &-action--favorites.is-active {
        background-image: url(../../../../../images/heart-active-button.svg);
    }

    &-action {
        width: 100%;
        height: 100%;
        font-size: 16px;

        @media (max-width: 667px) {
            font-size: 20px;
        }
    }

    &-action__text {
        @media (max-width: 667px) {
            display: none;
        }
    }

    &-action--cart {
        @extend .info-action;
        @include flex(row, center, center);
        font-family: $font-regular;
        background-color: $gray-in-active;
        color: $charcoal-grey;

        &:hover {
            box-shadow: none;
        }
    }

    &__buy-error {
        position: absolute;
        top: -22px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        line-height: 1.92;
        text-align: center;
        white-space: nowrap;
        color: $cerise;
    }
}


@-webkit-keyframes option {
    0% {
        color: $purpley-grey;
        border-bottom: 3px solid transparent;
    }
    50% {
        color: $cerise;
        border-bottom: 3px solid $cerise;
    }
    100% {
        color: $purpley-grey;
        border-bottom: 3px solid transparent;
    }
}
