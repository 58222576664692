@import '../../../styles/colors';
@import '../../../styles/variables';

.flash-sale {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    width: 100%;
    background: $cerise;

    &-wrapper {
        position: relative;
        width: 100%;
        margin-top: -40px;
        z-index: 7;
        height: 100%;
        min-height: 40px;
        transition: .4s;

        &.display {
            margin-top: 0;
            transition: .4s;
        }
    }

    &__text {
        position: relative;
        top: 2px;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        font-family: $font-semibold;
    }

    &__link {
        margin-left: 20px;
        text-decoration: underline;
        color: $white;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .flash-sale {
        padding-top: 8px;
        padding-bottom: 8px;

        &-wrapper {
            min-height: 26px;
        }

        &__text {
            top: 4px;
            line-height: 10px;
            font-size: 13px;
        }

        &__link {
            margin-left: 12px;
        }
    }
}

@media (max-width: 375px) {
    .flash-sale {
        padding-top: 8px;
        padding-bottom: 8px;

        &__text {
            top: 2px;
            font-size: 11px;
        }

        &__link {
            margin-left: 10px;
        }
    }
}

@media (max-width: 350px) {
    .flash-sale {
        &__text {
            top: 2px;
            font-size: 9px;
        }
    }
}
