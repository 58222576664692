@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.quick-coin {
    width: 220px;
    padding: 12px 12px 20px;
    background-color: $gray-porcelain;
    @include animate-right-panel;

    @media (max-width: 480px) {
        width: auto;
        min-width: 100%;
        padding: 10px 6px 14px;
    }

    &-heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 4px 0;
    }

    &-heading__title {
        display: flex;
        align-items: center;
    }

    &-heading__icon {
        width: 21px;
        height: 19px;
    }

    &-heading__text {
        margin-left: 8px;
        font-family: $font-semibold;
        font-size: 17px;
        line-height: 1;
        transform: translateY(1px);
        color: $charcoal-grey;

        @media (max-width: 480px) and (min-width: 361px) {
            font-size: 15px;
        }

        @media (max-width: 360px) {
            font-size: 14px;
        }
    }

    &-heading__checkbox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__checkbox-label {
        font-family: $font-regular;
        font-size: 12px;
        line-height: 20px;
        color: $pinkish-grey-dk;
    }

    &__content {
        padding-top: 20px;
        border-top: 1px solid $gray-in-active;

        @media (max-width: 480px) {
            padding-top: 5px;
        }
    }

    &__progress {
        display: flex;
        justify-content: center;
    }

    &__title {
        margin-top: 20px;
        font-family: $font-semibold;
        font-size: 18px;
        line-height: 1;
        text-align: center;
        color: $charcoal-grey;

        @media (max-width: 480px) {
            margin-top: 8px;
        }
    }

    &-list {
        margin-top: 15px;
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.43;
        color: $charcoal-grey;

        @media (max-width: 480px) {
            margin-top: 6px;
            line-height: 1.3;
        }
    }

    &-list__item {
        margin-top: 20px;

        @media (max-width: 480px) {
            margin-top: 10px;
        }
    }
}
