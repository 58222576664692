@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins";

#___reactour > div:first-child {
    opacity: .5;
}

.tutorial {

    &-wrapper.hidden {
        display: none;
    }

    &.reactour__helper {
        @include flex(column, center);
        padding: 30px 40px 30px 25px;
        min-height: 140px;
        width: 100%;
        max-width: 300px;
        font-family: $font-semibold;
        font-size: 16px;
        line-height: 1.25;
        border-radius: 4px;
        color: $charcoal-grey;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        &:focus {
            outline: none;
        }

        @media(max-width: 480px) {
            min-width: 200px;
            max-width: 250px;
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    & [data-tour-elem="badge"] {
        font-family: inherit;
    }

    & .reactour__dot {
        width: 10px;
        height: 10px;
        border-width: 2px;
    }

    & .reactour__dot--is-active {
        transform: none;
    }

    &__mask {
        border-radius: 4px;
    }

    & .reactour__dot {
        border-color: $purpley-grey;
    }

    & .reactour__dot--is-active {
        background-color: $purpley-grey;
    }

    &__text-container {
        @include flex(column);
    }

    &__text {
        padding: 4px 0;
    }

    &__finish {
        align-self: flex-end;
        height: 30px;
        width: 60px;
        font-family: $font-regular;
        font-size: 12px;
        text-transform: capitalize;
        background-color: $cerise;
        color: $white;
        border-radius: 4px;
    }

    &__arrow {
        width: 17px;
        height: 17px;
        background: transparent url('../../../images/tutorial-arrow.svg') no-repeat;
        background-size: 17px;
        background-position: center center;
        border: none;
        cursor: pointer;

        &:disabled {
            opacity: .5;
            cursor: not-allowed;
            background: transparent url('../../../images/tutorial-arrow.svg') no-repeat;
            background-size: 15px;
            background-position: center center;
        }
    }

    & [data-tour-elem="left-arrow"] {
        @media(max-width: 480px) {
            margin-right: 10px;
        }
    }

    & [data-tour-elem="right-arrow"] {
        @media(max-width: 480px) {
            margin-left: 10px;
        }
    }

    &__arrow--next {
        transform: rotate(180deg);
    }

    &-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 580px;
        padding: 25px 20px;
        border-radius: 4px;
        background-color: $white;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);

        @media(max-width: 667px) {
            max-width: 336px;
        }
    }

    &-popup__content {
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
        @media(max-width: 667px) {
            max-width: 300px;
        }
    }

    &-popup__text {
        padding: 15px 0;
        font-family: $font-semibold;
        font-size: 20px;
        line-height: 1.2;
        color: $biscay;
    }

    &-popup__action-box {
        padding: 15px 0;
        @include flex;

        @media(max-width: 667px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &-popup__action {
        margin: 15px 7px;
        padding: 10px;
        max-width: 194px;
        font-family: $font-semibold;
        font-size: 18px;
        border-radius: 2px;
        border: 1px solid $cerise;
        background-color: $white;
        color: $cerise;

        @media(max-width: 667px) {
            max-width: 100%;
            width: 100%;
        }
    }

    &-popup__action--secondary {
        background-color: $cerise;
        color: $white;
    }

    &-popup__badge {
        position: absolute;
        font-family: $font-semibold;
        background-color: #4a4a4a;
        height: 1.875em;
        line-height: 2;
        padding-left: 0.8125em;
        padding-right: 0.8125em;
        font-size: 1em;
        border-radius: 1.625em;
        color: white;
        text-align: center;
        box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.3);
        top: -0.8125em;
        left: -0.8125em;
    }

    &__icon {
        margin: 0 2px;
        width: 20px;
        height: 18px;
        vertical-align: middle;
    }
}
