@import "../../../../../styles/variables";
@import "../../../../../styles/colors";

.mp-modal-options {
    white-space: nowrap;
    display: inline-block;

    &__item {
        display: inline-block;
        height: 40px;
        padding: 11px 15px 12px;
        font-family: $font-semibold;
        font-size: 14px;
        cursor: pointer;
        color: $purpley-grey;
        border: solid 2px $purpley-grey;
        background-color: $white;

        @media (max-width: 667px) {
            border: solid 1px $warm-grey;
            line-height: 14px;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &__item.active {
        color: $white;
        background: $cerise;
        border-color: $cerise;
    }

    &__item.in-active {
        color: $gray-in-active;
        background: $white;
        border-color: $gray-in-active;
    }
}
