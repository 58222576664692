@mixin flex($direction: row, $justify-content: flex-start, $align-items: flex-start, $wrap: nowrap, $align-content: stretch){
    display: flex;
    flex-flow: $direction $wrap;
    justify-content: $justify-content;
    align-items: $align-items;

    @if $wrap == wrap or $wrap == wrap-reverse{
        align-content: $align-content;
    }
}

@mixin multiline-text-ellipsis($font-size, $line-height, $line-count) {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: $line-count;
    max-height: $font-size*$line-height*$line-count; /* Fallback for non-webkit */
    font-size: $font-size;
    line-height: $line-height;
    overflow: hidden;
    text-overflow: ellipsis;

    & {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
    }
    /* autoprefixer: on */
}

@mixin disable-focus-outline {
    &:focus {
        outline: none;
    }
}

@mixin animate-right-panel {
    animation-name: right-panel-appear;
    animation-iteration-count: 1;
    animation-duration: .5s;
}
