@import "../../../../styles/variables";
@import "../../../../styles/colors";

.mp-modal-purchase__shipping {
    flex-grow: 1;
    width: 100%;

    form {
        height: 100%;
        position: relative;
    }

    .market-place-settings-content__actions {
        position: absolute;
        bottom: 0;
        right: 0;

        @media (max-width: 667px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding: 12px;
            background-color: $white;
        }
    }

    .common-input-wraper input {
        @media (min-width: 769px) {
            padding: 19px 16px;
            font-family: $font-regular;
            font-size: 20px;
        }
    }

    .form-settings-shipping .state,
    .form-settings-shipping .zip {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
    }

    .StripeElement {
        padding: 19px 16px;
        height: auto;
        min-height: 0;
        font-size: 20px;
    }
}
