@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.products-recommend {
    width: 100%;
    padding: 22px 42px;
    background: $alice-blue-two;

    &:last-of-type {
        margin-top: 10px;
    }

    @media (max-width: 667px) {
        padding: 12px 12px 0;
    }

    &__overflow {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        width: 100%;
        min-height: 182px;
    }

    &__title {
        margin: 0 0 10px 42px;
        font-size: 22px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $charcoal-grey;
        font-family: $font-semibold;

        @media (max-width: 667px) {
            margin: 0;
            padding-left: 12px;
            font-size: 14px;
        }
    }

    .product-card {
        height: 170px;
        margin-right: 10px;
        max-height: 170px;
        min-width: 170px;
        margin-bottom: 12px;

        @media (max-width: 667px) {
            width: 130px;
            min-width: 130px;
            height: 130px;
            max-height: 130px;
            margin-right: 10px;
        }

        &__body {
            max-height: 130px;
            height: 100%;
            width: 100%;
            @media (max-width: 667px) {
                max-height: 100px;
            }
        }
    }

    .product-main-info {
        @media (max-width: 667px) {
            padding: 6px 5px;
        }

        &__title {
            @media (max-width: 667px) {
                font-size: 8px;
                line-height: 10px;
            }
        }

        &__brand {
            @media (max-width: 667px) {
                font-size: 8px;
                line-height: 10px;
            }
        }

        &__price-current {
            @media (max-width: 667px) {
                font-size: 9px;
                line-height: 10px;
            }
        }

        &__price-old {
            @media (max-width: 667px) {
                font-size: 7px;
                line-height: 7px;
            }
        }
    }

    .content-placeholder {
        @media (max-width: 667px) {
            height: 28%;
        }
    }

    .line {
        @media (max-width: 667px) {
            height: 9px;
        }
    }
}
