@import '../../../styles/colors';

$checkbox-height: 22px;
$checkbox-width: 41px;

.switch {
    width: $checkbox-width;
    height: $checkbox-height;
    overflow: hidden;

    &__input {
        display: none;
    }

    &__input:checked + .switch__label:before {
        border: 1px solid $green-cucumber;
        background-color: $green-cucumber;
    }

    &__input:checked + .switch__label:after{
        left: calc(#{$checkbox-width} - #{$checkbox-height} + 1px);
    }

    &__label {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            right: auto;
            top: 0;
            width: 100%;
            height: 100%;
            transition: .3s;
            border-radius: 40px;
            border: 1px solid $greyish;
            background-color: $greyish;
        }

        &:after {
            display: block;
            position: absolute;
            left: 1px;
            top: 1px;
            content: '';
            width: calc(#{$checkbox-height} - 2px);
            height: calc(#{$checkbox-height} - 2px);
            border-radius: 50%;
            background-color: $white;
            transition: .3s;
        }
    }
}
