@import '../../../styles/colors';

.how-works {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    overflow-y: auto;
    overflow-x:  hidden;

    &--wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $white;
        overflow: hidden;
        z-index: 8;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 26px;
        display: block;
        width: 28px;
        height: 28px;
        background-image: url('../../../images/cancel.svg');
        background-size: cover;
        cursor: pointer;
        z-index: 0;
    }

    &--top {
        min-height: 500px;
        padding-top: 105px;
        text-align: center;
        background: $white url("../../../images/about/bkgrnd.svg") 0 0 no-repeat ;
        background-size: cover;
    }

    &__title {
        margin-bottom: 30px;
        font-size: 40px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $biscay;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }

    &__button {
        max-width: 350px;
        width: 100%;
        max-height: 60px;
        margin-top: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 22px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        background: $blue;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }

    &--gallery {
        display: flex;
        justify-content: space-around;

        &__img {
            width: 160px;
            height: 130px;
        }

        &__lockmart {
            background: url("../../../images/about/lockmart.svg") center center no-repeat;
        }

        &__shorts {
            background: url("../../../images/about/shorts.svg") center center no-repeat;
        }

        &__spirits {
            background: url("../../../images/about/spirits.svg") center center no-repeat;
        }

        &__box {
            background: url("../../../images/about/box.svg") center center no-repeat;
        }

        &__glasses {
            background: url("../../../images/about/glasses.svg") center center no-repeat;
        }

        &__paper {
            background: url("../../../images/about/paper.svg") center center no-repeat;
        }

        &__picture {
            background: url("../../../images/about/picture.svg") center center no-repeat;
        }

        &__bag {
            background: url("../../../images/about/bag.svg") center center no-repeat;
        }

        &__notebook {
            background: url("../../../images/about/notebook.svg") center center no-repeat;
        }
    }

    &--info-box {
        display: flex;
        justify-content: space-around;
        margin-top: 50px;

        &__col {
            width: 25%;
            padding: 10px 20px 40px;
            text-align: center;
        }

        &__top {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 125px;
        }

        &__icon {
            margin-bottom: 20px;
        }

        &__name {
            font-size: 30px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $cerise;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
        }

        &__text {
            margin-top: 10px;
            font-size: 26px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.23;
            letter-spacing: normal;
            text-align: center;
            color: $charcoal-grey;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
        }
    }

    .line-rectangle {
        position: fixed;
        width: 100%;
        height: 6px;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(262deg, #ffffff, #fff1a3 0, #ffd700 29%, #ff41f2 80%, #df33ff 100%);

        &__top {
            top: 0;
        }

        &__bottom {
            bottom: 0;
        }
    }
}


@media (max-width: 1280px) {
    .how-works {
        &--gallery {
            &__bag,
            &__notebook {
                display: none;
            }
        }
    }
}

@media (max-width: 1024px) {
    .how-works {
        &--gallery {
            &__paper,
            &__picture {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
    .how-works {
        &--gallery {
            &__img {
                width: 120px;
                height: 90px;
            }

            &__glasses {
                display: none;
            }

            &__shorts {
                background: url("../../../images/about/shortsMob.svg") center center no-repeat;
            }

            &__lockmart {
                background: url("../../../images/about/lockmartMob.svg") center center no-repeat;
            }

            &__glasses {
                background: url("../../../images/about/glassesMob.svg") center center no-repeat;
            }

            &__spirits {
                background: url("../../../images/about/spiritsMob.svg") center center no-repeat;
            }
        }

        &--info-box {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            &__top {
                position: absolute;
                left: 20px;
            }

            &__icon {
                width: 80%;
                margin-top: 0;
                margin-bottom: auto;
            }

            &__col {
                width: 100%;
                padding-left: 130px;
                text-align: left;
            }

            &__name,
            &__text {
                text-align: left;
            }
        }
    }
}

@media (max-width: 480px) {
    .how-works {
        &__title {
            max-width: 80%;
            margin: 0 auto 15px;
            font-size: 18px;
        }

        .close {
            top: 18px;
            right: 20px;
            width: 18px;
            height: 18px;
        }

        &--top {
            min-height: 230px;
            padding-top: 60px;
            background: $white url("../../../images/about/bkgrndMob.svg") 0 0 no-repeat ;
            background-size: cover;
        }

        &__button {
            max-width: 80%;
            max-height: 44px;
            padding: 10px 0;
            font-size: 18px;
        }

        &--gallery {
            margin-top: 30px;

            &__img {
                width: 80px;
                height: 60px;
            }
        }

        &--info-box {
            &__top {
                left: 10px;
            }

            &__icon {
                width: 50%;
            }

            &__col {
                width: 100%;
                padding-left: 100px;
            }

            &__name {
                font-size: 18px;
            }

            &__text {
                font-size: 14px;
            }
        }
    }
}
