@import '../../../styles/colors.scss';
@import '../../../styles/generals';

.mp-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 6;
    transition: .4s;

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 40px;
        box-sizing: border-box;

        @media (max-width: 768px) {
            padding: 15px 20px;
        }
    }

    &__link {
        position: relative;
        margin: 0 7px;
        padding-left: 3px;
        padding-right: 3px;
        text-decoration: none;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: right;
        color: #9b9b9b;
        white-space: nowrap;

        &:after {
            position: absolute;
            content: '';
            width: 0;
            height: 2px;
            bottom: 0;
            left: 0;
            transition: .4s;
            background: #ffffff;
        }

        &:hover {
            &:after {
                transition: .4s;
                width: 100%;
            }
        }
    }

    &__copy {
        &:after {
            display: none;
        }
    }

    &.is-active {
        transition: .4s;
        background: rgba(0, 0, 0, 0.5);
    }

    &.is-active &__link {
        transition: .4s;
        color: #ffffff;
    }
}
