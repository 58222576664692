.mp-aside-panel {
    position: relative;
    width: 270px;
    min-width: 270px;
    padding: 10px;

    @media (max-width: 768px) and (min-width: 481px) {
        padding: 5px 10px;
    }

    @media (max-width: 480px) {
        min-width: 190px;
        width: 190px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    @media (max-width: 360px) {
        min-width: 172px;
        width: 172px;
        padding-left: 5px;
        padding-right: 5px;
    }

    &--fixed {
        position: fixed;
        right: 22px;
        transition: top .4s;

        @media (max-width: 768px) {
            right: 0;
        }
    }

    &--full-width {
        position: fixed;
        min-width: 100%;
        width: 100%;
        height: 100%;
        top: 83px;
        padding: 0;
        z-index: 2;
    }
}
