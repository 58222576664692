.notification-block {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 300px;
    min-height: 80px;
    margin-bottom: 10px;
    padding: 15px 15px 15px 56px;
    border-radius: 2px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.29);

    &.error {
        background-color: #F6A623;
    }

    &.success {
        background-color: #87B576;
    }

    .close-icon {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 8px;
        right: 8px;
        background-image: none;
        cursor: pointer;
    }

    &__img {
        position: absolute;
        top: 16px;
        left: 20px;
    }

    &__title {
        margin-bottom: 4px;
        text-align: left;
        font-family: proxima_nova_bold, Helvetica, sans-serif;
        font-size: 14px;
        color: #ffffff;
    }

    &__info {
        text-align: left;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        font-size: 12px;
        color: #ffffff;
        line-height: 16px;
    }

    &__list {
        list-style: disc;
        padding-left: 10px;
    }
}

// POPUP-ERROR
.block-errors {
    position: fixed;
    width: 300px;
    top: 25px;
    right: 20px;
    z-index: 11;
}
