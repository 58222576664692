@import "../../../styles/mixins";
@import "../../../styles/colors";
@import "../../../styles/variables";

.banner {
    width: 100%;
    padding: 60px 90px;
    border-bottom: 1px solid #0000002b;

    @media (max-width: 667px) {
        padding: 24px;
    }

    &__body {
        @include flex(row, space-beetwen, flex-start);
        width: 100%;

        @media (max-width: 1200px) {
            @include flex(column, flex-start, center);
        }
    }

    &__info,
    &__cards {
        width: 50%;

        @media (max-width: 1200px) {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 34px;
        font-size: 40px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $cerise;
        font-family: $font-semibold;

        @media (max-width: 667px) {
            font-size: 22px;
            margin-bottom: 15px;
        }
    }

    &__sub-title {
        margin-bottom: 14px;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: normal;
        color: $purpley-grey;
        font-family: $font-regular;

        @media (max-width: 667px) {
            font-size: 14px;
        }
    }

    &__list {
        @include flex(column, flex-start, flex-start);

        &__item {
            @include flex(row, flex-start, center);
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 20px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 24px;
            letter-spacing: normal;
            color: $purpley-grey;
            font-family: $font-regular;

            @media (max-width: 667px) {
                font-size: 14px;
                padding-top: 4px;
                padding-bottom: 4px;
                align-items: flex-start;
            }
        }

        &__number {
            display: inline-block;
            width: 27px;
            min-width: 27px;
            height: 27px;
            margin-right: 18px;
            line-height: 27px;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: center;
            color: $purpley-grey;
            font-family: $font-semibold;
            border: solid 1px $purpley-grey;
            background-color: $white-smoke;
            box-sizing: border-box;
            border-radius: 50%;

            @media (max-width: 667px) {
                font-size: 12px;
                width: 20px;
                min-width: 20px;
                height: 20px;
                margin-right: 12px;
                margin-top: 1.4px;
                line-height: 20px;
            }
        }
    }

    .banner-button {
        margin-top: 8px;
        padding: 15px 80px;
        font-size: 18px;

        @media (max-width: 1200px) {
            margin-top: 30px;
        }

        @media (max-width: 667px) {
            margin-top: 20px;
            padding: 10px 60px;
            font-size: 14px;
        }
    }

    &__cards {
        padding-top: 34px;
        padding-left: 75px;

        @media (max-width: 1200px) {
            padding-left: 0;
        }

        @media (max-width: 768px) {
            display: none;
        }

        &__wrapper {
            position: relative;
            @include flex(row, center, center);
            width: 100%;
            min-height: 200px;

            @media (max-width: 1200px) {
                justify-content: center;
            }
        }
    }

    .relative {
        .product-card {
            width: 198px;
            height: 208px;

            &__body {
                max-height: 160px;
                height: 100%;
                width: 100%;
            }

            &__title,
            &__brand {
                font-size: 12px;
            }

            &__price-current {
                font-size: 13px;
            }

            &__price-old {
                font-size: 9px;
            }

            &__favorites {
                width: 21px;
                height: 17px;
            }

            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &:first-of-type {
            .product-card {
                width: 187px;
                right: -26px;
                z-index: 0;

                &__img {
                    margin-left: 0;
                }
            }

            .content-placeholder {
                height: 25%;
                padding-top: 15px;
            }
        }

        &:last-of-type {
            .product-card {
                width: 187px;
                left: -26px;
                z-index: 0;

                &__img {
                    margin-right: 0;
                }
            }

            .content-placeholder {
                height: 25%;
                padding-top: 15px;
            }
        }

        &:nth-of-type(2) {
            .product-card {
                width: 196px;
                height: 252px;
                z-index: 1;

                &__body {
                    max-height: 205px;
                }
            }

            .content-placeholder {
                height: 20%;
                padding-top: 15px;
            }
        }
    }

    &__footer {
        width: 100%;
    }
}
