@import '../../../styles/colors.scss';
@import '../../../styles/generals';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.mp-header {
    width: 100%;
    position: fixed;
    background: $white;
    border-bottom: 1px solid #0000002b;
    z-index: 3;

    &-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 87px;
    }

    &-rectangle {
        position: fixed;
        height: 6px;
        width: 100%;
        background-color: $cerise;
    }

    &__logo {
        cursor: pointer;
    }

    &-placeholder {
        max-width: 580px;
        width: 100%;
        height: 44px;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        padding: 24px 32px 18px;
    }

    &-actions {
        display: flex;
        align-items: center;

        .common-button {
            padding: 6px 8px;
            white-space: nowrap;
        }

        &-user-menu {
            position: relative;

            &__switcher {
                width: 36px;
                height: 36px;
                padding-right: 20px;
                box-sizing: content-box;
                cursor: pointer;

                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: rotate(45deg) translate(-50%, -50%);
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-bottom: 2px solid $pinkish-grey;
                    border-right: 2px solid $pinkish-grey;
                    cursor: pointer;
                }

                .user-logo {
                    width: 100%;
                    border: 1px solid #dfe2e5;
                    border-radius: 50%;
                }
            }
        }
    }

    &-actions__aside {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 0 4px;
    }

    &__aside-trigger {
        @include flex;
    }

    &-badge {
        @include flex(row, center, center);
        padding: 3px 7px;
        min-height: 24px;
        min-width: 24px;
        border-radius: 12px;
        background-color: $cerise;
        vertical-align: middle;
        transform: translateX(-8px);
        transition: .3s;

        &.is-hidden {
            opacity: 0;
        }
    }

    &-badge__value {
        font-family: $font-semibold;
        font-size: 15px;
        line-height: normal;
        color: $white;
        transform: translateY(1px);
    }

    &-coins {
        position: relative;
    }

    &-coins__icon {
        width: auto;
        height: 30px;
        cursor: pointer;
    }

    &-cart {
        margin: 0 10px;
    }

    &-cart__icon {
        width: auto;
        height: 30px;
        cursor: pointer;
    }

    &-favorites__icon {
        width: auto;
        height: 28px;
        cursor: pointer;
    }

    &__gift-me {
        margin-left: 20px;
        font-family: $font-semibold;
        font-size: 20px;
        height: 50px;
    }

    .common-button.transparent {
        font-family: $font-semibold;
        font-size: 20px;
        color: $charcoal-grey;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    .common-button.transparent-pink {
        width: 100px;
        height: 47px;
        font-family: $font-semibold;
        font-size: 18px;
        color: $cerise;
        background-color: transparent;
        border: 1px solid $cerise;
    }
}

.user-menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 245px;
    padding: 20px 8px 25px;
    border: 1px solid #dfe3e9;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
    box-sizing: border-box;
    background-color: $white;
    z-index: 5;

    // new part
    .common-button {
        width: 100%;

        &.dark-pink {
            margin-top: 17px;
        }
    }
    // new part

    &-wrapper {
        position: absolute;
        right: -20px;
        top: 40px;
        width: 245px;
        height: 0;
        overflow: hidden;
        transition: .2s;
        z-index: 6;

        &.is-active {
            height: 160px;
            transition: .4s;
        }
    }

    &-top {
        display: none;
        margin-bottom: 30px;

        &__title {
            margin-left: 25px;
            font-family: $font-regular;
            font-size: 17px;
            color: $black;
        }
    }

    &-sign-up {
        padding: 10px;
        background-image: radial-gradient(circle at 50% 25%, #fcfae2, #fffab2);
        border: solid 1px #dfdcc0;
        text-align: center;

        &__icon {
            display: block;
            width: 28px;
            height: 35px;
            margin: auto;
        }

        &__title {
            margin-top: 7px;
            font-family: $font-bold;
            font-size: 14px;
            color: black;
        }

        &__text {
            margin-top: 10px;
            font-family: $font-regular;
            font-size: 12px;
            color: $black;
        }

        .bold {
            font-family: $font-bold;
        }

        &__button {
            width: 100%;
            margin-top: 17px;
            padding: 12px 0;
            font-family: $font-regular;
            font-size: 14px;
            color: $white;
            background-color: $cerise;
            border: none;
            border-radius: 2px;
            cursor: pointer;
        }
    }

    &-devidor {
        position: relative;
        display: flex;

        &__text {
            padding: 10px;
            margin-left: 50%;
            transform: translateX(-50%);
            font-family: $font-semibold;
            font-size: 14px;
            background-color: $white;
            color: $charcoal-grey;
            text-transform: uppercase;
            z-index: 1;
        }

        &__line {
            position: absolute;
            top: 50%;
            width: 50%;
            height: 2px;
            background-color: $pinkish-grey-dk;
            transform: translateY(-50%);

            &_left {
                left: 0;
            }

            &_right {
                right: 0;
            }
        }
    }

    &-login {
        margin-top: 8px;
        padding: 0 7px;

        &__button {
            width: 100%;
            padding: 12px 0;
            font-family: $font-regular;
            font-size: 14px;
            color: $charcoal-grey;
            background-color: $pinkish-grey-dk;
            border-radius: 2px;
            border: none;
            cursor: pointer;
        }
    }

    &__link {
        text-decoration: none;
        position: relative;
        display: block;
        width: 100%;
        padding: 9px 14px;
        font-family: $font-semibold;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: $dark-grey-blue;
        box-sizing: border-box;
        box-shadow: inset 0 -1px 0 0 $pale-grey;
        cursor: pointer;

        &:hover,
        &:active {
            background: $pale-grey-two;
            color: $blue;
        }
    }

    &__item:last-child &__link {
        box-shadow: none;
    }
}

.is-login {
    .user-menu {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .mp-header {
        box-shadow: none;

        &-rectangle {
            height: 5px;
        }

        &-wrapper {
            min-height: 125px;
        }

        &__logo {
            width: 96px;
            height: 54px;
        }

        &-content {
            align-items: flex-start;
            padding: 14px 32px 14px;
            background: $gray-lt;
        }

        &-actions {
            height: 54px;

            &-user-menu {

                &__switcher {
                    width: 32px;
                    height: 32px;
                }

                &:after {
                    width: 6px;
                    height: 6px;
                }
            }

            .common-button {
                padding: 6px 8px;
                white-space: nowrap;
            }

            .common-button.transparent-pink {
                height: 30px;
                padding: 4px 8px;
                font-size: 16px;
            }
        }

        &-badge {
            padding: 0 7px 1px;
            min-height: 18px;
            min-width: 18px;
        }

        &-badge__value {
            font-family: $font-regular;
            font-size: 12px;
        }

        &-coins__icon {
            height: 23px;
        }

        &-cart {
            margin: 0 5px;
        }

        &-cart__icon {
            height: 23px;
        }

        &-favorites__icon {
            height: 22px;
        }

        &-wrapper--landing {
            min-height: 80px;
        }

        &-wrapper--landing .mp-header__logo {
            width: 150px;
            height: auto;
        }

        &-wrapper--landing .mp-header-content {
            align-items: center;
            padding: 26px 24px 12px;
        }

        &-wrapper--landing .common-button,
        &-wrapper--landing .common-button.transparent-pink {
            height: 40px;
            font-size: 17px;
        }

        &__gift-me {
            margin-left: 10px;
        }
    }
}

@media (max-width: 480px) {
    .mp-header {
        z-index: 6;

        &-wrapper {
            min-height: 102px;
        }

        &-placeholder {
            display: none;
        }

        &__logo {
            width: 66px;
            height: 24px;
        }

        &-actions {
            height: auto;
            position: relative;
            z-index: 5;

            &__favorites {
                height: 22px;

                .count {
                    width: 18px;
                    height: 18px;
                    left: 50%;
                    font-size: 12px;
                    line-height: 18px;
                    opacity: 0;
                    transition: .3s;

                    &.is-active {
                        opacity: 1;
                    }
                }
            }

            &-user-menu {

                &__switcher {
                    padding-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        &-content {
            padding: 20px 16px 48px;
        }

        &-content a {
            align-self: center;
        }

        .market-place-search-form {
            margin-top: 50px;
        }

        .common-button.transparent-pink {
            width: 60px;
            height: 28px;
            font-size: 14px;
        }

        &-wrapper--landing {
            min-height: 80px;
        }

        &-wrapper--landing .mp-header__logo {
            width: 110px;
        }

        &-wrapper--landing .mp-header-content {
            padding: 26px 14px 12px;
        }

        &-wrapper--landing .common-button,
        &-wrapper--landing .common-button.transparent-pink {
            font-size: 14px;
        }

        &-wrapper--landing .common-button.transparent-pink {
            width: 80px;
        }
    }

    .user-menu {
        width: 100%;
        height: 100%;
        padding-left: 13px;
        padding-right: 13px;
        border-left: none;
        border-right: none;
        border-bottom: none;
        border-radius: 0;
        background-color: $gray-lt;

        &-wrapper {
            display: none;
        }

        &-top {
            display: flex;
        }

        &-devidor {

            &__text {
                background-color: $gray-lt;
            }
        }
    }

    .is-login {
        .user-menu {
            border: 1px solid $pale-grey;
            border-radius: 4px;
            background-color: $white;
        }
    }
}
