@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.mp-modal-purchase__billing {
    flex-grow: 1;
    width: 100%;

    .billing-form {
        height: 100%;

        @media (max-width: 667px) {
            padding: 0;
        }
    }

    .billing-form form {
        height: 100%;
        position: relative;
    }

    .billing-form .card-item__address {
        @media (max-width: 480px) {
            padding-left: 50px;
        }
    }

    .billing-form .market-place-settings-content__actions {
        position: absolute;
        bottom: 0;
        right: 0;
        @media (max-width: 667px) {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            margin: 0;
            padding: 12px;
            background: $white;
        }
    }

    .billing-form--opened-address-form .market-place-settings-content__actions {
        position: static;
    }

    .common-input-wraper input {
        @media (min-width: 769px) {
            padding: 19px 16px;
            font-family: $font-regular;
            font-size: 20px;
        }
    }

    .form-settings-shipping .state,
    .form-settings-shipping .zip {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
    }

    .StripeElement {
        @media (min-width: 768px) {
            padding: 19px 16px;
            height: auto;
            min-height: 0;
            font-size: 20px;
        }
    }

    .accordion .accordion-default-item {
        pointer-events: none;
    }

    .billing-form-logos {
        @include flex(row, space-between, center);

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .form-logos {
        width: auto;
    }

    .billing-form-logos__text,
    .billing-form__label {
        font-family: $font-regular;
        font-size: 15px;
        color: #4a4a4a;
    }

    .billing-form__label {
        margin-top: 40px;

        @media (max-width: 480px) {
            margin-top: 20px;
        }
    }

    .billing-form__trust-badge-box {
        @include flex(row, flex-start, center);
        margin-top: auto;
        padding-bottom: 10px;

        @media (max-width: 1130px) {
            padding-bottom: 80px;
        }

        @media (max-width: 667px) and (min-width: 481px) {
            margin-top: 40px;
        }

        @media (max-width: 480px) {
            margin-top: 20px;
        }
    }

    .billing-form__trust-badge {

        &:not(:first-child) {
            margin-left: 20px;
        }

        &.norton {
            max-width: 90px;
        }

        &.truste {
            max-width: 130px;
        }

        &.mcafee {
            max-width: 110px;
        }

        @media (max-width: 667px) {

            &:not(:first-child) {
                margin-left: 10px;
            }

            &.norton {
                max-width: 80px;
            }

            &.truste {
                max-width: 110px;
            }

            &.mcafee {
                max-width: 90px;
            }
        }
    }
}
