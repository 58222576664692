
.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    text-align: center;
    z-index: 6;
    top: 0;
    left: 0;

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
