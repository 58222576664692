@import '../../../styles/colors.scss';

.two-inputs-row {
    display: flex;
    justify-content: space-between;

    &.displaced {
        & > div:first-child {
            flex-basis: 65%;
        }
        & > div:last-child {
            flex-basis: 35%;
        }
    }

    & > div:first-child {
        margin-right: 5px;
    }
    & > div:last-child {
        margin-left: 5px;
    }
}
.three-inputs-row {
    display: flex;
    justify-content: space-between;

    & > div:nth-child(2) {
        margin: 0 10px;
    }

    @media all and (max-width: 480px){
        flex-wrap: wrap;

        & > div:nth-child(2) {
            margin: 5px 0;
        }
    }


}
.common-input-wraper {
    $error-font-size: 10px;

    width: 100%;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
    padding-bottom: $error-font-size;
    label {
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        font-family: proxima_nova_light, Helvetica, sans-serif;
        letter-spacing: normal;
        text-align: left;
        color: $purpley-grey;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    input {
        width: 100%;
        padding: 13px;
        border: 1px solid #c7c7c7;
        border-radius: 2px;
        color: $charcoal-grey;
        font-size: 14px;
    }

    &.input-with-error {
        padding-bottom: 0;
        input {
            background-color: #fbe8ea;
            border: solid 1px $scarlet;
            color: $scarlet;
        }
        label, .error-message {
            color: #f64316;
        }
        .error-message {
            min-height: $error-font-size;
            font-size: $error-font-size;
            text-align: right;
            line-height: 1;
        }
    }
}

.radio-input input {
    visibility: hidden;
}
.radio-input + .radio-input__label {
    cursor: pointer;
}

/* The decor of the checkbox in the off state (background). */
.radio-input:not(checked) + .radio-input__label:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    top: 50%;
    left: 18px;
    transform: translateY(-50%) scale(0);
    z-index: 1;
    transition: .4s;
}

/* The decor of the checkbox is off (switch). */
.radio-input:not(checked) + .radio-input__label:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 15px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
    border-radius: 50%;
    background-image: linear-gradient(to top, #f2f4f8, #feffff);
    border: solid 0.6px #ced0da;
    box-sizing: border-box;
}

/* Change the background of the checkbox when it's on. */
.radio-input:checked + .radio-input__label:before {
    width: 9px;
    height: 9px;
    background-color: #7ab55c;
    top: 50%;
    left: 18px;
    transform: translateY(-50%) scale(1);
    border-radius: 50%;
    transition: .4s;
}

.radio-input:checked + .radio-input__label:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 15px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
    border-radius: 50%;
    background-image: linear-gradient(to top, #f2f4f8, #feffff);
    border: solid 0.6px #ced0da;
    box-sizing: border-box;
}

input {
    &::-webkit-input-placeholder {
        font-family: 'proxima_nova_regular', Helvetica, sans-serif;
        color: #c3c3c3;
    }

    &::-moz-placeholder {
        font-family: 'proxima_nova_regular', Helvetica, sans-serif;
        color: #c3c3c3;
    }

    &:-ms-input-placeholder {
        font-family: 'proxima_nova_regular', Helvetica, sans-serif;
        color: #c3c3c3;
    }
}
