@import "colors";

.circle-box {
    position: absolute;
    width: 100%;
    max-width: 325px;
    height: 100%;
    max-height: 325px;
    bottom: 0;
    right: -85%;
    border-radius: 50%;
    background: $cerise;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 28px;
    }

    &__title {
        margin-bottom: 12px;
        font-size: 26px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.27;
        letter-spacing: 0.1px;
        text-align: center;
        color: #ffffff;
        font-family: proxima_nova_regular, Helvetica, sans-serif;
    }

    &__text {
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 200;
        line-height: 1.25;
        letter-spacing: 0.1px;
        text-align: center;
        color: #ffffff;
        font-family: proxima_nova_light, Helvetica, sans-serif;
    }

    &__link {
        padding: 18px 28px;
        color: $white;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        text-decoration: none;
        border-radius: 2px;
        background:$bright-sky-blue;
        font-family: proxima_nova_regular, Helvetica, sans-serif;
    }
}

@media (max-width: 1366px) {
    .circle-box {
        max-width: 250px;
        max-height: 250px;
        right: -65%;

        &__title {
            margin-bottom: 10px;
            font-size: 20px;
        }

        &__text {
            margin-bottom: 18px;
            font-size: 18px;
        }

        &__link {
            padding: 10px 18px;
            font-size: 18px;
        }
    }
}

@media (max-width: 1024px) {
    .circle-box {
        max-width: 220px;
        max-height: 220px;
        right: -55%;

        &__title {
            margin-bottom: 8px;
            font-size: 18px;
        }

        &__text {
            margin-bottom: 16px;
            font-size: 16px;
        }

        &__link {
            padding: 8px 16px;
            font-size: 16px;
        }
    }
}

@media (max-width: 960px) {
    .circle-box {
        display: none;
    }
}
