@import "../../../styles/colors";

.order-history {
    @media (max-width: 480px) {
        width: 90%;
        margin: 0 auto;
    }

    &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.no-orders {
            justify-content: center;
        }
    }

    &__title {
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        font-size: 17px;
        color: $charcoal-grey;
    }

    &__filter {
        position: relative;
        width: 186px;
        font-family: proxima_nova_regular, Helvetica, sans-serif;

        &-icon {
            position: absolute;
            left: 11px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 16px;
            height: 16px;
            background-image: url('../../../images/calendar.svg');
            -webkit-background-size: cover;
            background-size: cover;

        }
    }

    .order-history-select__control {
        padding-left: 26px;

        &--is-focused,
        &:hover {
            box-shadow: none;
            border-color: hsl(0,0%,80%);
        }
    }

    .order-history-select__indicator-separator {
        display: none;
    }

    .order-history-select__indicator {
        padding: 5px;
        cursor: pointer;
    }

    .order-history-select__option--is-selected {
        background-color: rgba(0, 126, 255, 0.04);
        color: $black;
    }

    .order-history-select__option:hover {
        background-color: rgba(0, 126, 255, 0.08);
    }
}

.order-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #dfe2e5;
    box-sizing: border-box;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 14px;

    &__row {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 40px;
        padding: 5px 0;
        border-bottom: 1px solid #dfe2e5;

        &:last-child {
            border-bottom: 1px solid transparent;
        }
    }

    &__header {
        display: flex;
        width: 100%;
        font-size: 12px;
        color: #9b9b9b;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;

        .order-table__row {
            border-bottom: 1px solid #dfe2e5;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        color: #4A4A4A;
    }

    &__column {
        padding: 0 15px;
        box-sizing: border-box;
    }

    .column-date {
        max-width: 175px;
        width: 100%;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }

    .column-product {
        max-width: 220px;
        width: 100%;
    }

    .column-type {
        max-width: 160px;
        width: 100%;
    }

    .column-status {
        max-width: 110px;
        width: 100%;
    }

    .column-amount {
        width: 100%;
        max-width: 105px;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }

    .column-coins {
        color: $cerise;
    }

    .no-orders {
        padding: 45px;
        background: #f8f8f8;

        &__img {
            max-width: 70px;
            max-height: 70px;
        }

        &__title {
            margin-left: 40px;
            color: #4A4A4A;
            font-size: 20px;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
        }
    }

    .spinner-img {
        margin: 0 auto;
    }
}

@media (max-width: 667px) {
    .order-history {
        &__title {
            margin-top: 10px;
        }

        &-top {
            align-items: flex-start;
        }

        &-actions {
            flex-direction: column;
        }
    }

    .order-table {
        flex-direction: row;
        overflow-x: auto;

        &__row {
            flex-direction: column;
            align-items: flex-start;
            width: auto;
        }

        &__header {
            width: 20%;

            .order-table__row {
                justify-content: space-around;
                border-bottom: none;
                border-right: 1px solid #dfe2e5;
                width: 100%;
            }
        }

        &__body {
            width: 80%;
            flex-direction: row;

            .order-table__row {
                border-bottom: none;
                border-right: 1px solid #dfe2e5;

                &:last-of-type {
                    border-right: 1px solid transparent;
                }
            }
        }

        &__column {
            white-space: nowrap;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .column-datem,
        .column-product,
        .column-type,
        .column-status,
        .column-amount {
            max-width: 100%;
        }

        .no-orders {
            width: 100%;
            align-items: center;

            .no-orders__title {
                margin: 0;
            }
        }
    }
}
