@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.qt-banner {
    @include flex(column, flex-start, stretch);
    min-height: calc(100vh - 88px);

    &--cut-bg .qt-banner-main {
        background-image: url('../../../images/quickTo/quickto-banner-bg-cut.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 370px;
        background-color: #f5f5f5;

        @media (max-width: 480px)  {
            background-size: 440px;
            background-position: -250px 0;
        }
    }

    &--cut-bg .qt-banner-main__inner {
        background-color: transparent;
    }

    &-main {
        @include flex(row, center, stretch);
        flex-grow: 1;
        min-height: 268px;
        background-image: url('../../../images/quickTo/quickto-banner-bg.jpg');
        background-position: center;
        background-size: cover;

        @media (max-width: 768px)  {
            min-height: 418px;
        }
    }

    &-main__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100%;
        background-color: rgba(255, 255, 255, .5);
    }

    &-main__title {
        font-family: $font-semibold;
        font-size: 32px;
        line-height: 1;
        text-align: center;
        color: $charcoal-grey;

        @media (max-width: 480px) {
            font-size: 22px;
        }

        @media (max-width: 360px) {
            max-width: 280px;
            margin-top: 20px;
        }
    }

    &-main__subtitle {
        margin-top: 8px;
        font-family: $font-bold;
        font-size: 24px;
        line-height: 1.47;
        text-align: center;
        color: $blue;

        @media (max-width: 480px) {
            font-size: 18px;
        }
    }

    &__button {
        margin-top: 30px;
        min-width: 160px;
        height: 40px;
        font-family: $font-semibold;
        font-size: 16px;
    }

    &-footer {
        padding: 34px 0 50px;
        @include flex(column, flex-start, center);
        background-color: $gray-lt-two;
    }

    &-footer__title {
        font-family: $font-semibold;
        font-size: 24px;
        line-height: 1;
        text-align: center;
        color: $charcoal-grey;
    }

    &-footer__divider {
        height: 1px;
        width: 100%;
        max-width: 400px;
        margin: 20px 0;
        background-color: $purpley-grey;

        @media (max-width: 480px) {
            width: 80%;
        }
    }

    &-item-list {
        @include flex(row, space-between, flex-start);

        @media (max-width: 768px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &-item {
        @include flex(column);
        padding: 20px 30px;
        @media (max-width: 1350px) {
            align-items: flex-start;
            flex-grow: 1;
        }

        @media (max-width: 768px) {
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            max-width: 450px;
            padding: 16px;
        }

        @media (max-width: 480px) {
            max-width: 380px;
        }
    }

    &-item__icon {
        align-self: center;
        max-height: 56px;
        max-width: 75px;

        @media (max-width: 1350px) {
            height: 40px;
            width: 55px;
        }
    }

    &-item__text {
        margin-top: 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-width: 345px;

        @media (max-width: 768px) {
            margin-left: 10px;
        }
    }

    &-item__title {
        font-family: $font-semibold;
        font-size: 18px;
        line-height: 1;
        color: $blue;
        white-space: nowrap;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }

    &-item__subtitle {
        margin-top: 8px;
        font-family:$font-regular;
        font-size: 16px;
        line-height: 1;
        color: $charcoal-grey;

        @media (max-width: 768px) {
            font-size: 14px;
        }
    }

    &__brands {
        margin-top: 20px;
        max-width: 1000px;
        width: 100%;
        
        @media (max-width: 768px) and (min-width: 361px)  {
            max-width: 90%;
        }

        @media (max-width: 360px) {
            max-width: 100%;
        }
    }

    &__brands .qt-banner-footer__title {
        margin-bottom: 30px;
        padding: 0 10px;
        font-size: 24px;
    }
}
