@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/variables";

/* First, we designate styles for IE8 and older versions. Here we slightly ennoble the standard checkbox. */
.klickly-checkbox {
    vertical-align: top;
    margin: 0 3px 0 4px;
    width: 16px;
    height: 16px;
}

/* This is for all browsers, except for very old ones,
which do not support selectors with a plus. We show that the label is clickable. */
.klickly-checkbox + .klickly-checkbox__label {
    cursor: pointer;
}

/* Next comes the design of the checkbox in modern browsers,
as well as IE9 and above. Due to the fact that older browsers do not support selectors: not and: checked,
in them all the following styles will not work. */

/* Hide the original checkbox. */
.klickly-checkbox:not(checked) {
    position: absolute;
    opacity: 0;
}

.klickly-checkbox:not(checked) + .klickly-checkbox__label {
    position: relative; /* We will position the pseudo-checkbox relative to the label */
    display: inline-block;
    padding-left: 25px; /* Leave the place on the left of the label for the pseudo-checkbox */
    line-height: 18px;
    color: $purpley-grey;
    font-family: $font-regular;
    font-size: 12px;
    text-align: left;
}

/* The decor of the checkbox in the off state (background). */
.klickly-checkbox:not(checked) + .klickly-checkbox__label:before {
    content: '';
    position: absolute;
    top: -6px;
    left: 5px;
    width: 16px;
    height: 16px;
    z-index: 1;
}

/* The decor of the checkbox is off (switch). */
.klickly-checkbox:not(checked) + .klickly-checkbox__label:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-image: linear-gradient(to top, $silver, $white);
    border: solid 1px $purpley-grey;
}

/* Change the background of the checkbox when it's on. */
.klickly-checkbox:checked + .klickly-checkbox__label:before {
    width: 8px;
    height: 4px;
    top: 5px;
    left: 6px;
    border-left: 2px solid $white;
    border-bottom: 2px solid $white;
    transform: rotate(-45deg);

}

.klickly-checkbox:checked + .klickly-checkbox__label:after {
    content: ' ';
    position: absolute;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-image: linear-gradient(to top, $green, $green);
    border: solid 1px $green;
}

.klickly-checkbox-disabled:checked + .klickly-checkbox__label:after {
    background-image: linear-gradient(to top, $white-one, $white-one);
    border: solid 1px $white-one;
}
