@import url('https://fonts.googleapis.com/css?family=Prompt&display=swap');
@import "../../../styles/variables";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.landing {

    & * {
        box-sizing: border-box;
    }

    &__contaimer {
        max-width: 1024px;
        margin: 0 auto;
    }

    &__action {
        font-family: $font-regular;
        border-style: solid;
        border-width: 1px;
        border-radius: 2px;
    }

    &__action a {
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: center;
        text-decoration: none;
        color: inherit;
    }

    &__title {
        font-family: $font-semibold;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: $black;
    }

    &__subtitle {
        font-family: $font-regular;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $black;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    // -- header --

    &-header {
        position: relative;
    }

    &-header__link {
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: center;
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    &-header__container {
        @include flex(row, space-between, center);
        position: relative;
        max-width: 1024px;
        margin: 0 auto;
        padding: 6px 20px;
        z-index: 2;
        color: $black;
        background-color: $white;
    }

    &-header__logo {
        font-family: 'Prompt', sans-serif;
        font-size: 30px;
        text-transform: capitalize;
    }

    &-header__menu {
        @include flex(row, flex-start, center);
        font-family: $font-regular;
        font-size: 16px;
        line-height: normal;
    }

    &-header__action {
        min-width: 78px;
        font-size: 16px;
        padding: 1px 0;
    }

    &-header__nav {
        @media (max-width: 480px) {
            display: none;
        }
    }

    &-header__nav--mobile {
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        transform: translateY(-100%);
        transition: top .3s, transform .3s;
        z-index: 1;
        font-family: $font-regular;
        font-size: 16px;
        line-height: normal;
        background-color: $white;

        &.opened {
            top: 100%;
            transform: translateY(0);
        }
        @media (min-width: 481px) {
            display: none;
        }
    }

    &-header__nav ul {
        @include flex(column, stretch, flex-start);
        @media (min-width: 481px) {
            flex-direction: row;
        }
    }

    &-header__nav-item {
        padding: 10px 15px;
        width: 100%;
        text-transform: capitalize;
        box-shadow: inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.05);
        @media(min-width: 480px) {
            padding: 0 15px;
            width: auto;
            box-shadow: none;
            cursor: pointer;
        }
    }

    &-header__nav-item a {
        text-decoration: none;
        color: inherit;
    }

    &-header__nav-icon-wrapper {
        padding: 0 10px;
    }

    &-header__nav-icon {
        width: 15px;
        height: 12px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        @media(min-width: 481px) {
            display: none;
        }
    }

    &-header__nav-icon span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $black;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    // -- hamburger button --

    &-header__nav-icon span:nth-child(1) {
        top: 0;
    }

    &-header__nav-icon span:nth-child(2),
    &-header__nav-icon span:nth-child(3) {
        top: 5px;
    }

    &-header__nav-icon span:nth-child(4) {
        top: 10px;
    }

    &-header__nav-icon.opened span:nth-child(1) {
        top: 5px;
        width: 0;
        left: 50%;
    }

    &-header__nav-icon.opened span:nth-child(2) {
        transform: rotate(45deg);
    }

    &-header__nav-icon.opened span:nth-child(3) {
        transform: rotate(-45deg);
    }

    &-header__nav-icon.opened span:nth-child(4) {
        top: 5px;
        width: 0;
        left: 50%;
    }

    // -- banner --

    &-banner {
        background-image: url('../../../images/quickTo/landing/quickto-landing-banner-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &-banner__link {
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: center;
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    &-banner__container {
        max-width: 1024px;
        margin: 0 auto;
        min-height: 130px;
        padding: 23px 15px;

        @media (min-width: 481px) and (max-width: 767px) {
            padding: 40px 30px;
        }

        @media (min-width: 768px) {
            padding: 80px 50px 0;
            min-height: 450px;
        }

        @media (min-width: 1680px) {
            max-width: 1366px;
        }
    }

    &-banner__title {
        max-width: 64px;
        font-family: $font-regular;
        font-size: 12px;
        line-height: normal;
        color: $white;

        @media (min-width: 481px) and (max-width: 767px) {
            max-width: 120px;
            font-size: 20px;
        }

        @media (min-width: 768px) {
            max-width: 220px;
            font-size: 40px;
        }
    }

    &-banner__action {
        margin-top: 14px;
        max-width: 52px;
        padding: 1px 0 0;
        font-size: 8px;
        line-height: 10px;
        text-transform: capitalize;
        color: $white;
        border-color: $white;

        @media (min-width: 481px) and (max-width: 767px) {
            max-width: 90px;
            font-size: 14px;
            line-height: 22px;
        }

        @media (min-width: 768px) {
            margin-top: 40px;
            max-width: 177px;
            padding: 5px 0 2px;
            font-size: 30px;
            line-height: 36px;
        }
    }

    // -- differences --ff

    &-differences {
        padding: 40px 0 30px;
    }

    &-differences__container {
        max-width: 860px;
        margin: 0 auto;
    }

    &-differences__box {
        @include flex(column, space-between, center);

        @media(min-width: 768px ) {
            flex-direction: row;
            align-items: flex-end;
        }
    }

    &-differences__item {
        @include flex(column, center, center);
        margin-top: 30px;
        padding: 0 20px;
    }

    &-differences__item:nth-child(2) &-differences__icon-wrapper:before {
        position: absolute;
        content: 'Q';
        top: -3px;
        right: 6px;
        font-family: 'Prompt', sans-serif;
        font-size: 30px;
    }

    &-differences__icon-wrapper {
        position: relative;
    }

    &-differences__icon {
        max-width: 75px;
        max-height: 68px;
    }

    &-differences__text {
        margin-top: 30px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 19px;
        color: $black;
    }

    // -- best sellers --

    &-bestsellers {
        padding: 30px 5px;
    }

    &-bestsellers .landing__subtitle {
        margin-top: 5px;
    }

    &-bestsellers__container {
        max-width: 580px;
        margin: 0 auto;

        @media (min-width: 890px) {
            max-width: 880px;
        }
    }

    &-bestsellers__box {
        padding-top: 10px;
        @include flex(row, space-around, center, wrap, center)
    }

    &-bestsellers__item {
        max-width: 50%;
        margin-top: 30px;
        padding: 0 5px;

        @media(min-width: 768px) {
            max-width: 220px;
            padding: 0 10px;
        }
    }

    &-bestsellers__item a {
        display: block;
    }

    &-bestsellers__name {
        max-width: 120px;
        font-family: $font-regular;
        font-style: italic;
        @media(min-width: 768px) {
            max-width: 100%;
        }
    }

    &-bestsellers__image {
        height: auto;
        width: 100%;
        transition: .2s;
        cursor: pointer;
    }

    &-bestsellers__info {
        @include flex(column, center, center);
        font-family: $font-regular;
        font-size: 14px;
        line-height: normal;
        text-align: center;
        color: $black;
        @media(min-width: 768px) {
            margin-top: 10px;
        }
    }

    &-bestsellers__price {
        font-family: $font-semibold;
    }

    // -- brands --

    &-brands {
        padding: 30px 0;
    }

    &-brands__box {
        @include flex(row, space-between, center, wrap);
        margin: 25px auto 0;
        max-width: 280px;

        @media (min-width: 481px) and (max-width: 767px) {
            max-width: 590px;
        }
        @media (min-width: 768px) {
            max-width: 740px;
        }
    }

    &-brands__item-wrapper {
        padding: 10px;
        @media (min-width: 481px) and (max-width: 767px) {
            padding: 15px;
        }
        @media (min-width: 768px) {
            padding: 15px 25px;
        }

    }

    &-brands__item {
        width: auto;
        max-width: 100%;
        max-height: 20px;
        cursor: pointer;
        @media (min-width: 481px) and (max-width: 767px) {
            max-height: 26px;
        }
        @media (min-width: 768px) {
            max-height: 30px;
        }
    }

    // --footer --

    &-footer {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 17px;
        color: $black;
        background-color: $gray-lt-two;
    }

    &-footer__container {
        @include flex(column, flex-start, flex-start);
        width: 100%;
        margin: 0 auto;
        padding: 30px 20px 20px;
        @media (min-width: 481px) {
            flex-direction: row;
        }
    }

    &-footer__info {
        margin-top: 40px;
        @media (min-width: 481px) and (max-width: 767px) {
            margin-top: 0;
            margin-left: auto;
        }
        @media(min-width: 768px) {
            margin-top: 0;
            margin-left: 100px;
        }
    }

    &-footer__info &-footer__list-item {
        cursor: pointer;
    }

    &-footer__list {
        margin-top: 15px;
    }

    &-footer__list-item a {
        color: $black;
        text-decoration: none;
    }

    &-footer__payment-methods {
        margin-top: 30px;
        margin-left: auto;
        align-self: flex-end;
    }
}

.quickto-modal {

    &-wrapper {
        width: 550px;
        height: 350px;
        position: fixed;
        left: calc(50% - 275px);
        bottom: 100px;
        z-index: 5003;
        background-color: #fff;
        border-radius: 4px;
        -webkit-box-shadow: -1px 0px 68px 16px rgba(196,196,196,1);
        -moz-box-shadow: -1px 0px 68px 16px rgba(196,196,196,1);
        box-shadow: -1px 0px 68px 16px rgba(196,196,196,1);
        font-family: $font-regular;
        visibility: hidden;

        @media(max-width: 480px) {
            width: 320px;
            height: 300px;
            left: calc(50% - 160px);
        }
    }

    &-content {
        padding: 20px 30px;
        font-family: $font-regular;
        position: relative;
        height: 100%;
    }

    &-header {
        display: flex;
        justify-content: space-between;

        &__text {
            font-size: 40px;
            font-family: $font-semibold;
            padding: 10px 0 30px;

            @media(max-width: 480px) {
                font-size: 25px;
            }
        }

        &__close {
            position: absolute;
            right: 20px;
            top: 20px;
            width: 28px;
            height: 28px;
            opacity: 0.3;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            &:after, &:before {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 28px;
                width: 3px;
                background-color: #333;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        &__logo {
            font-size: 60px;
            text-transform: capitalize;
            font-family: $font-semibold;

            @media(max-width: 480px) {
                font-size: 45px;
            }
        }
    }

    &-body {
        display: flex;
        justify-content: space-between;

        &__row {
            padding: 3px 0;
            font-size: 18px;
            font-family: $font-regular;

            & a {
                color: #000000;
                text-decoration: none;
            }

            @media(max-width: 480px) {
                font-size: 12px;
            }

            &_one {
                line-height: 24px;

                @media(max-width: 480px) {
                    line-height: 16px;
                    font-size: 14px;
                }
            }
        }

        &__logo {
            text-transform: capitalize;
        }
    }

    &-footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 15px;

        &__ok-button {
            width: 120px;
            height: 40px;
            border-radius: 8px;
            background-color: #e9e9e9;
            color: #4a4a4a;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-regular;

            &:hover {
                background-color: #c9c9c9;
            }
        }
    }
}

.visible {
    visibility: visible !important;
}

.klickly-iframe-unit-wrapper {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 5;
    visibility: hidden;

    &_mobile {
        bottom: 10px;
        left: calc(50% - 160px);
    }
}

.klickly-iframe-animation-appears {
    visibility: visible;
    -webkit-animation: klicklyIframeAnimationAppears 1s ease-in;
    -moz-animation: klicklyIframeAnimationAppears 1s ease-in;
    -o-animation: klicklyIframeAnimationAppears 1s ease-in;
    animation: klicklyIframeAnimationAppears 1s ease-in;
}

@-webkit-keyframes klicklyIframeAnimationAppears {
    0% {
        -webkit-transform: translateY(100%);
    }
    100% {
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes klicklyIframeAnimationAppears {
    0% {
        -webkit-transform: translateY(100%);
    }
    100% {
        -webkit-transform: translateY(0);
    }
}

@-o-keyframes klicklyIframeAnimationAppears {
    0% {
        -webkit-transform: translateY(100%);
    }
    100% {
        -webkit-transform: translateY(0);
    }
}

@keyframes klicklyIframeAnimationAppears {
    0% {
        -webkit-transform: translateY(100%);
    }
    100% {
        -webkit-transform: translateY(0);
    }
}
