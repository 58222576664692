@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/mixins';

%text {
    font-family: $font-regular;
    font-size: 28px;
    line-height: 1.36;
    color: $charcoal-grey;

    @media (max-width:1060px) and (min-width: 668px) {
        font-size: 20px;
    }

    @media (max-width:667px) {
        font-size: 18px;
        letter-spacing: normal;
        line-height: 1.33;
    }
}

.offer-landing {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    padding: 80px 0;
    z-index: 6;
    background-color: $white;
    @media (max-width: 667px) {
        padding: 56px 0;
    }

    &__title {
        @include flex(row, center, center);
        font-family: $font-semibold;
        font-size: 44px;
        line-height: .95;
        color: $mine-shaft-black;

        @media (max-width: 667px) {
            font-size: 25px;
        }
    }

    &__title-image {
        margin-left: 12px;
        height: 60px;
        @media (max-width: 667px) {
            height: 34px;
        }
    }

    &__content {
        max-width: 1070px;
        margin: 0 auto;
        padding: 50px 13px 0;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 41.66667% 1fr;
        grid-column-gap: 40px;
        grid-template-areas:
            "image first-subtitle"
            ". list"
            ". last-subtitle"
            ". actions";

        @media (max-width: 667px) {
            max-width: 400px;
            padding-top: 0;
            grid-column-gap: 14px;
            grid-template-columns: 44% 1fr;
        }
    }

    &__image-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: image;
        grid-row: 1/5;
        grid-column: 1/2;
        border: 1px solid $pinkish-grey-dk;
        @media (max-width: 1060px) and (min-width: 668px) {
            grid-column: 1/2
        }
        @media (max-width: 667px) {
            grid-row: 2;
        }
    }

    &__image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    &__subtitle-container {
        grid-area: first-subtitle;
        padding-bottom: 9px;
        @media (max-width: 1060px) and (min-width: 668px) {
            grid-column: 2/3
        }
        @media (max-width: 667px) {
            grid-column: 1/3;
            padding: 30px 0 36px;
            text-align: center;

            & > .offer-landing__subtitle {
                max-width: 256px;
            }
        }

        &--second {
            grid-area: last-subtitle;
            padding-top: 15px;
            @media (max-width: 1060px) and (min-width: 668px) {
                grid-column: 2/3
            }
            @media (max-width: 667px) {
                grid-column: 1/3;
                grid-row: 3;
                padding-top: 40px;
                text-align: center;
            }
        }
    }

    &__subtitle {
        display: inline-block;
        @extend %text;
    }

    &__list-container {
        grid-area: list;
        @media (max-width: 1060px) and (min-width: 668px) {
            grid-column: 2/3
        }
    }

    &-list {
        list-style-type: decimal;
        list-style-position: inside;
    }

    &-list__item {
        padding: 9px 0;
        @extend %text;

        @media (max-width: 667px) {
            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &__action-wrapper {
        grid-area: actions;
        display: flex;
        padding-top: 30px;
        @media (max-width: 1060px) and (min-width: 668px) {
            grid-column: 2/3
        }
        @media (max-width: 667px) {
            grid-row: 5;
            grid-column: 1/3;
            justify-content: center;
            flex-direction: column;
        }
    }

    &__action {
        @include flex(row, center, center);
        width: 260px;
        height: 61px;
        font-family: $font-semibold;
        font-size: 26px;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-decoration: none;
        border-radius: 2px;
        color: $white;
        background-color: $cerise;

        &:last-child {
            margin-left: 20px;
            @media (max-width: 667px) {
                margin-left: 0;
                margin-top: 15px;
            }
        }

        @media (max-width: 1060px) {
            font-size: 20px;
            height: 47px;
            width: 150px;
        }

        @media (max-width: 667px) {
            width: 100%;
        }
    }

    &__action--secondary {
        font-style: italic;
        background-color: $gray-in-active;
    }
}
