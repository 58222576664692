@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.mp-modal-qty {
    @include flex(row, flex-start, stretch);
    height: 40px;
    white-space: nowrap;

    & > * {
        width: 40px;
        font-size: 14px;
        font-weight: 600;
        border: 2px solid $purpley-grey;
        color: $purpley-grey;
    }

    &__value {
        margin: 0 5px;
        font-family: $font-semibold;
        text-align: center;
        -moz-appearance: textfield;
        @include disable-focus-outline;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        @media (max-width: 667px) {
            font-size: 18px;
            font-weight: 400;
            border: 1px solid $gray;
            color: $pinkish-grey-dk;
        }
    }

    &__button {
        padding: 0 0 4px;
        @include disable-focus-outline;

        &:hover {
            cursor: pointer;
        }

        @media (max-width: 667px) {
            font-size: 20px;
            font-weight: 400;
            color: $pinkish-grey-dk;
            border: 1px solid $pinkish-grey-dk;
        }
    }
}
