@import "./colors";
@import "./mixins";
@import "./variables";

.open-modal {
    overflow: hidden;
}

.icon {
    cursor: pointer;
}

.relative {
    position: relative;
}

.line-through {
    text-decoration: line-through;
}

.market-place {
    transition: .4s;
    position: relative;

    &-search {
        padding: 40px 0;
        background-color: #f6f7f8;

        &__title {
            font-family: $font-semibold;
            font-size: 19px;
            text-align: center;
            color: $black;
            margin-bottom: 22px;
        }

        &-block {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: max-content;
            max-width: 70%;
            width: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            z-index: 5;
            top: 0;
        }

        &-form {
            display: flex;
            max-width: 90%;
            width: 100%;
            margin: 0 auto;
            border: 1px solid $cerise;
            border-radius: 2px;

            &__input-wrapper {
                display: flex;
                width: 100%;

                input {
                    padding: 11px 14px;
                    width: 100%;
                    background-color: $white;
                    border: none;
                    font-family: $font-semibold;
                    font-size: 17px;
                    line-height: 17px;
                    color: $cerise;
                    outline: none;

                    &::-webkit-input-placeholder {
                        font-family: $font-semibold;
                        color: $cerise;
                        font-size: 17px;
                        line-height: 17px;
                    }

                    &::-moz-placeholder {
                        font-family: $font-semibold;
                        color: $cerise;
                        font-size: 17px;
                        line-height: 17px;
                    }

                    &:-ms-input-placeholder {
                        font-family: $font-semibold;
                        color: $cerise;
                        font-size: 17px;
                        line-height: 17px;
                    }

                    &:-moz-placeholder {
                        font-family: $font-semibold;
                        color: $cerise;
                        font-size: 17px;
                        line-height: 17px;
                    }
                }
            }

            &__submit {
                position: relative;
                padding: 11px 14px;
                min-width: 54px;
                border: none;
                background-color: $cerise;
                border-top-right-radius: 1px;
                border-bottom-right-radius: 1px;
                cursor: pointer;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 20px;
                    width: 20px;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .mp-placeholder-logo {
        min-width: 170px;
        height: 44px;
    }

    .mp-placeholder-user-menu {
        min-width: 170px;
        height: 44px;
    }

    &-content {
        padding: 0 22px 22px;
        margin: 0 auto;
        box-sizing: border-box;

        &-top {
            position: relative;
            width: 100%;
            top: 0;
            left: 0;
            padding: 24px 30px 22px;
            background: #fff;
            z-index: 2;

            &.scrollUp.fixed {
                position: fixed;
                transform: translateY(88px);
                transition: transform .4s;
                @media (min-width: 481px) and (max-width: 768px) {
                    transform: translateY(108px);
                }
                @media (max-width: 480px) {
                    transform: translateY(82px);
                }
            }

            &.scrollDown.fixed {
                position: fixed;
                transform: translateY(0);
                transition: transform .4s;
            }

            &.scrollDown {
                transform: translateY(0);
                transition: transform .4s;
            }
        }

        &-bottom {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 20px;
            padding-bottom: 60px;
            min-height: 1px;
            text-align: center;
        }

        &-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 15px;
            overflow: hidden;
        }

        &-products {
            width: 100%;

            &__noresults {
                width: 140px;
                margin: 20px auto;
                padding: 145px 0 0 7px;
                font-size: 14px;
                color: $purpley-grey;
                background-image: url('../images/package-empty.svg');
                background-repeat: no-repeat;
                font-family: $font-regular;
            }

            .product-card {
                width: 220px;
                margin: 10px;
                min-height: 100px;

                &__img {
                    min-width: 100%;
                    min-height: 100px;
                }

                &__title {
                    font-size: 12px;
                    @media (max-width: 667px) {
                        font-size: 10px;
                    }
                }

                &__brand {
                    font-size: 12px;
                    @media (max-width: 667px) {
                        font-size: 10px;
                    }
                }

                &__favorites {
                    bottom: 10px;
                    width: 20px;
                    height: 16px;
                }

                &:hover, &:active {
                    transition: .3s ease-out;
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
                }

                &__price-current {
                    font-size: 13px;
                    @media (max-width: 667px) {
                        font-size: 11px;
                    }
                }

                .product-placeholder {
                    height: 200px;
                }
            }
        }
    }


    &.sale {
        transform: translateY(128px);

        @media (min-width: 481px) and (max-width: 768px) {
            transform: translateY(148px);
        }
        @media (max-width: 480px) {
            transform: translateY(130px);
        }
    }

    button {
        outline: none;
    }

    .masonry {
        margin: 0 auto;
    }
}

#olark-wrapper {
    .olark-launch-button.olark-size-sm {
        bottom: 48px !important;
    }
}

@media (max-width: 1024px) {
    .market-place {
        &-content {
            &-products {
                justify-content: space-evenly;
            }
        }
    }
}

@media (max-width: 768px) {

    .market-place {

        &-search {
            padding: 0 0 20px;
            background: #fafafa;

            &__title {
                display: none;
            }

            &-form {
                max-width: 390px;
                margin-top: 54px;

                &__input-wrapper input {
                    padding: 9px 12px;
                }
            }
        }

        &-content {
            padding: 26px 0 0;

            &-top {
                margin: 0;
            }

            &-products {

                .product-card {
                    width: 165px;
                    margin: 5px;
                }
            }
        }
    }
}
.with-loader {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        right: 50%;
        top: 50%;
        display: block;
        font-size: 10px;
        width: 1em;
        height: 1em;
        margin-top: -0.5em;
        animation: spinner 1500ms infinite linear;
        border-radius: 0.5em;
        z-index: 3;
        box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    }
    &:before {
        position: absolute;
        content: '';
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: $white;
        opacity: .5;
    }
}

@media (max-width: 667px) {
    .market-place {
        &-content {
            padding-top: 0;

            &-top {
                margin: 0;
                padding: 0;
                background: $white;
            }
        }
    }
}

@media (max-width: 480px) {
    .market-place {

        &-search {

            &__title {
                display: none;
            }

            &-form {
                position: relative;
                max-width: 300px;
                height: 32px;
                margin-top: 40px;

                &__input-wrapper {
                    font-size: 14px;
                }

                &__submit {
                    min-width: 40px;

                    img {
                        width: 17px;
                        height: 17px;
                    }
                }
            }

            &-block {
                position: absolute;
                width: 100%;
                min-width: 80%;
                height: 100%;
                bottom: 0;
                transform: translateX(-50%);
                left: 50%;
            }
        }

        &-content {

            &-wrapper {
                margin: 0;
            }

            &-products {
                margin-top: 10px;

                .product-card {
                    width: 145px;
                    margin: 5px;
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .market-place-content-products .product-card {
        width: 135px;
    }
}
