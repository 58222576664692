@import '../../../../../styles/colors';
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.details {
    @include flex(column);
    width: 100%;
    max-width: 460px;
    min-width: 340px;
    min-height: 100%;
    padding: 100px 30px 100px 60px;
    font-family: $font-regular;
    color: $charcoal-grey;
    background-color: $alice-blue-two;
    overflow: hidden;

    @media (max-width: 1024px) and (min-width: 769px) {
        padding-left: 30px;
        max-width: 330px;
        min-width: 300px;
    }

    @media (max-width: 768px) and (min-width: 668px) {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 260px;
        min-width: 260px;
    }

    @media (max-width: 667px) {
        flex-direction: column-reverse;
        max-width: 100%;
        min-width: 0;
        min-height: 0;
        padding: 40px 12px 10px;
        border-bottom: 2px solid $gray-alto;
    }

    &__title {
        margin-bottom: 15px;
        font-family: $font-semibold;
        font-size: 30px;
        line-height: .88;
        letter-spacing: normal;

        @media (max-width: 768px) and (min-width: 668px) {
            font-size: 24px;
        }

        @media (max-width: 667px) {
            display: none;
        }
    }


    // --------- order details


    &-order {
        width: 100%;

        @media (max-width: 667px) and (min-width: 481px) {
            margin-top: 6px;
        }

        @media (max-width: 480px) {
            margin-top: 10px;
        }
    }

    &-order__label {
        @include flex(row, flex-start, center);
    }

    &-order__label .icon {
        margin-left: 5px;

        @media (max-width: 667px) {
            width: 11px;
            height: 11px;
            transform: translateY(-1px);
        }
    }

    &-order__row {
        @include flex(row, space-between, center);
        margin: 5px 0;
        font-size: 22px;
        line-height: 1.36;

        @media (max-width: 768px) and (min-width: 668px) {
            font-size: 18px;
        }

        @media (max-width: 667px) {
            margin: 2px 0;
            font-size: 12px;
        }
    }

    &-order__row--total,
    &-order__row--coins-total,
    &-order__row--discount {
        font-family: $font-semibold;
    }

    &-order__row--total,
    &-order__row--coins-total{
        padding-top: 10px;
        font-size: 24px;
        line-height: normal;
        border-top: 2px solid $warm-grey;
        @media (max-width: 768px) and (min-width: 668px) {
            font-size: 20px;
        }
        @media (max-width: 667px) {
            padding-top: 4px;
            font-size: 18px;
            border-top-color: $gray-in-active;
        }
    }

    &-order__row--coins-total &-order__value {
        @include flex(row, center, center);
    }

    &-order__row--subtotal {
        @media (max-width: 667px) {
            font-family: $font-semibold;
        }
    }

    &-order__row--coins-earned {
        color: $cerise;
    }

    &-order__project-icon {
        width: 21px;
        height: 20px;
        margin-right: 4px;
        transform: translateY(-3px);
    }

    &-order__project-icon img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
    }

    &-order__row .__react_component_tooltip {
        max-width: 300px;
        font-family: $font-light;
    }

    &-order__row .info-icon {

        @media(max-width: 667px) {
            transform: translateY(0);
        }
    }


    // --------- product details


    &-product-wrapper {
        padding-top: 30px;
        @media (max-width: 667px) {
            padding-top: 0;
        }
    }

    &-product {
        display: flex;
    }

    &-product__image-wrapper {
        @include flex(row, center, center);
        width: 120px;
        height: 120px;
        min-width: 120px;
        margin-right: 25px;
        background: $white;
        border: 1px solid $pinkish-grey-dk;

        @media (max-width: 768px) and (min-width: 668px) {
            margin-right: 10px;
        }

        @media (max-width: 667px) {
            width: 85px;
            height: 85px;
            min-width: 85px;
            margin-right: 10px;
        }
    }

    &-product__image {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    &-product__info {
        @include flex(column, flex-start, flex-start);
    }

    &-product__name {
        font-family: $font-semibold;
        @include multiline-text-ellipsis(22px, 1.18, 2);

        @media (max-width: 768px) and (min-width: 668px) {
            @include multiline-text-ellipsis(18px, 1.18, 2);
        }

        @media (max-width: 667px) {
            @include multiline-text-ellipsis(14px, 1.29, 2);
        }
    }

    &-product__brand {
        max-width: 225px;
        margin: 5px 0;
        font-size: 22px;
        line-height: 1.2;
        color: $purpley-grey;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media (max-width: 1024px) and (min-width: 769px) {
            max-width: 130px;
        }

        @media (max-width: 768px) and (min-width: 668px) {
            max-width: 90px;
            font-size: 18px;
        }

        @media (max-width: 667px) {
            margin: 0 0 6px;
            font-size: 12px;
            line-height: 1.17;
            white-space: normal;
        }
    }

    &-product__option {
        font-family: $font-semibold;
        font-size: 20px;
        line-height: 1.33;

        @media (max-width: 768px) and (min-width: 668px) {
            font-size: 18px;
        }

        @media (max-width: 667px) {
            font-family: $font-regular;
            font-size: 12px;
        }
    }

    .calculated-on-step {
        color: $purpley-grey;
        font-size: 12px;
    }
}
