.account-settings {
    display: flex;
    flex-direction: column;
    max-width: 568px;
    width: 100%;

    &-form {
        display: flex;
        flex-direction: column;

        form {
            width: 100%;
        }

        .block-errors__text {
            bottom: -10px;
        }
    }

    .form-settings-shipping .state .province-field .province-field__hidden-input {
        height: 42px;
        font-size: 14px;
    }
}

.shipping-and-payment {
    @media (max-width: 480px) {
        .market-place-settings-content__actions {
            padding-right: 15px;
        }
    }
}
