@import "../../../../styles/colors";
@import "../../../../styles/variables";

.mp-modal-purchase__confirmation {
    width: 100%;

    .common-button.dark-pink {
        width: 100%;
        margin: 40px 0 8px;
        padding: 19px 16px;
        font-family: $font-light;
        font-size: 24px;
        line-height: 23px;

        @media (max-width: 667px) {
            margin: 0;
            padding: 12px 0;
            font-size: 17px;
        }
    }

    .wrapper-for-button {
        @media (max-width: 667px) {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: 12px;
            background: $white;
            z-index: 1;
        }
    }

    &.not-authenticated .accordion .accordion-default-item {
        pointer-events: none;
    }

    &.not-authenticated .accordion .accordion-default-item .accordion-arrow {
        display: none;
    }
}
