@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.quickto-cart {
    width: 220px;
    min-width: 220px;
    padding: 11px;
    min-height: inherit;
    background-color: $gray-porcelain;
    transition: .3s;
    @include animate-right-panel;

    @media(max-width: 480px) {
        width: auto;
        min-width: 100%;
    }

    &.is-active {
        transform: translateX(0);
    }

    &__head {
        @include flex(row, space-between, center);
        padding: 0 0 13px;
        font-family: $font-semibold;
        font-size: 17px;
        color: $charcoal-grey;
        border-bottom: 1px solid $gray-in-active;

        @media(max-width: 480px) {
            font-size: 14px;
        }
    }

    &-title {
        @include flex(row, flex-start, center);
    }

    &-title__icon {
        margin-right: 9px;
    }

    &-title span {
        transform: translateY(2px);
    }

    &__count {
        transform: translateY(4px);
    }

    &__list {
        padding-bottom: 12px;
    }

    &-product {
        @include flex(row, flex-start, stretch);
        padding: 16px 0;
    }

    &-product:not(:first-child) {
        border-top: 1px solid $gray-in-active;
    }

    &-product__img-wrapper {
        @include flex(row, center, center);
        min-width: 60px;
        width: 60px;
        height: 67px;
        border: 1px solid $gray-in-active;
    }

    &-product__img {
        max-width: 100%;
        max-height: 100%;
    }

    &-product__info {
        flex-grow: 1;
        @include flex(column, space-between, flex-start);
        margin-left: 8px;
        overflow: hidden;
    }

    &-product__name {
        max-width: 114px;
        font-family: $font-semibold;
        font-size: 12px;
        line-height: 1.08;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $charcoal-grey;
    }

    &-product__brand {
        max-width: 114px;
        font-family: $font-regular;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $purpley-grey;
    }

    &-product__price-box {
        margin-top: 3px;
        max-width: 100%;
        @include flex(row, flex-start, center);
        font-family: $font-semibold;
        font-size: 13px;
        white-space: nowrap;
        color: $cerise;
    }

    &-product__price {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-product__coins-icon {
        height: 11px;
        margin-right: 3px;
        transform: translateY(-1px);
    }

    &-product__delete {
        padding: 0;
        font-family: $font-regular;
        font-size: 11px;
        line-height: 1.18;
        color: $charcoal-grey;
    }

    &__checkout {
        height: 45px;
        width: 100%;
        font-family: $font-semibold;
        font-size: 18px;
        line-height: 1;
        letter-spacing: normal;
        color: $white;
        background-color: $cerise;

        &:disabled {
            background-color: $gray-in-active;
            cursor: not-allowed;
        }
    }

    &__checkout > span {
        display: inline-block;
        transform: translateY(1px);
    }
}
