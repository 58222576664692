@import "../../../styles/animation";
@import "../../../styles/colors";
@import "../../../styles/variables";

$listFilter: popular, newest, under10, arts, automotive, baby, beauty, clothing, electronics, games, garden,
grocery, home, movies, music, office, outdoors, personal, pets, phones, tools ;

.filter-bar {
    display: flex;
    overflow: hidden;

    &-wrapper {
        width: 100%;
        position: relative;

        .more-bar {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            width: 210px;
            background: #FFF;
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        padding-left: 26px;
        padding-right: 26px;
        white-space: nowrap;
        cursor: pointer;

        &:first-of-type {
            padding-left: 0;
        }

        &.is-active {
            .filter-bar__text {
                color: $cerise;

                &:after {
                    width: 100%;
                }
            }
        }

        &.more-filter {
            .filter-bar__text {
                position: relative;

                &:before {
                    position: absolute;
                    content: '';
                    border: 6px solid transparent;
                    border-top: 6px solid $purpley-grey;
                    right: -18px;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-top: 2px;
                }
            }
        }

        &.is-opened {
            .select {
                visibility: visible;
                z-index: 1;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);

                -webkit-transition-delay: 0s;
                -moz-transition-delay: 0s;
                -ms-transition-delay: 0s;
                -o-transition-delay: 0s;
                transition-delay: 0s;

                overflow-y: auto;
                max-height: 300px;
            }

            .wrapper-select {
                padding: 10px;
                z-index: 2;
            }
        }

        .wrapper-select {
            width: 240px;
            visibility: hidden;
            overflow: hidden;
            position: absolute;
            top: 100%;
            left: -20px;
            padding: 0;
        }
    }

    &__text {
        position: relative;
        display: inline-block;
        padding-bottom: 6px;
        font-family: $font-regular;
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $purpley-grey;
        cursor: pointer;

        &:after {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            height: 3px;
            width: 0;
            background-color: $cerise;
            transition: .3s;
        }
    }
}

.more-bar {
    .select {
        background: $white;
        border-radius: 4px;
        box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.22);
        border: solid 1px $pale-grey;
        max-height: 0;
        visibility: hidden;

        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);

        -webkit-transition:
            visibility 0s linear 200ms,
            -webkit-transform 200ms linear;
        -moz-transition:
            visibility 0s linear 200ms,
            -moz-transform 200ms linear;
        -ms-transition:
            visibility 0s linear 200ms,
            -ms-transform 200ms linear;
        -o-transition:
            visibility 0s linear 200ms,
            -o-transform 200ms linear;
        transition:
            visibility 0s linear 200ms,
            transform 200ms linear;

        &__item {
            height: 37px;
            font-family: $font-semibold;
            font-size: 14px;
            font-weight: 600;
            line-height: 37px;
            text-align: left;
            color: $charcoal-grey;
            border-bottom: 1px solid $pale-grey;

            &.is-active {
                .select__text {
                    background: $pale-grey-two;
                    border-bottom: 1px solid $pale-grey;
                    color: $blue;

                    &:after {
                        background: url('./icons/check-mark_icon.svg') 0 0 no-repeat;
                    }
                }
            }
        }

        &__text {
            position: relative;
            display: block;
            height: 37px;
            padding-left: 14px;
            color: $charcoal-grey;
            cursor: pointer;
            font-family: $font-semibold;
            font-size: 14px;
            text-decoration: none;

            &::after {
                content: '';
                position: absolute;
                right: 14px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 11px;
                height: 8px;
                -webkit-transition: all .35s;
                -o-transition: all .35s;
                transition: all .35s;
            }

            &:hover {
                cursor: pointer;
                background: $duck-egg-blue;
                border-bottom: 1px solid $pale-grey;
            }
        }
    }
}

@media (max-width: 768px) {
    .filter-bar-wrapper {
        .more-bar {
            background: transparent;
        }
    }
}

@media (max-width: 667px) {
    .filter-bar-wrapper {
        width: 100%;
        padding: 9px 10px;
        box-sizing: border-box;

        .content {
            touch-action: manipulation;
        }

        .scrollarea .scrollbar-container {
            &.horizontal {
                height: 5px;
                background: $gray;
                opacity: 1;
                z-index: 2;

                .scrollbar {
                    height: 5px;
                    margin-top: 0;
                    background: $pinkish-grey-dk;
                    border-radius: 2px;
                }
            }
        }

        .more-bar {
            display: none;
        }
    }

    .filter-bar {
        display: flex;
        overflow: visible;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        box-sizing: border-box;
        min-width: calc(100vw - 20px);
        height: 64px;
        align-items: flex-start;

        &__icon {
            display: block;
            width: 38px;
            height: 38px;
            margin-bottom: 5px;

            @each $filter in $listFilter {
                &.#{$filter} {
                    background: url("./icons/#{$filter}.svg") 0 0 no-repeat;
                    background-size: contain;
                }
            }
        }

        &__text {
            padding-bottom: 0;
            font-size: 8px;
            line-height: 10px;
            color: $biscay;
            display: inline-block;
        }

        &__item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            margin-right: 12px;
            margin-bottom: 12px;
            padding: 0;
            box-sizing: border-box;

            &.is-active {
                @each $filter in $listFilter {
                    .#{$filter} {
                        animation-name: animateOpacity;
                        transition: .4s;
                        background: url("./icons/#{$filter}-active.svg") 0 0 no-repeat;
                        background-size: contain;
                    }
                }

                .filter-bar__text {
                    color: #313d4f;

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.category-filter-wrapper {
    position: relative;
    min-height: 70px;
}
