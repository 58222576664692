@import '../../../styles/variables';
@import '../../../styles/colors';
@import '../../../styles/mixins';

.product-landing {

    &__line {
        height: 6px;
        background-color: $cerise;
    }

    &__content {
        @include flex(row, space-between, stretch);
        max-width: 1148px;
        padding: 72px 20px 20px;
        margin: 0 auto;

        @media (max-width: 700px) {
            flex-direction: column;
            align-items: center;
        }

        @media (max-width: 480px) {
            padding: 20px;
        }
    }

    &__data {
        width: 400px;

        @media (max-width: 700px) {
            width: auto;
            max-width: 80%;
        }

        @media (max-width: 480px) {
            max-width: 100%;
        }
    }

    &__info {
        position: relative;
        width: 500px;
    }

    &__image {
        @include flex(row, center, center);
        width: 100%;
        height: 400px;
        margin-bottom: 10px;

        @media (max-width: 1000px) {
            height: 350px;
        }

        @media (max-width: 700px) {
            height: auto;
            margin-bottom: 20px;
        }
    }

    &__image img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &__row {
        @include flex(row, space-between, center);

        @media (max-width: 700px) {
            align-items: flex-start;
        } 
    }

    &__name {
        font-family: $font-semibold;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 0;
        color: $charcoal-grey;
    }

    &__brand {
        font-family: $font-regular;
        font-size: 18px;
        line-height: 1.45;
        letter-spacing: 0;
        text-transform: uppercase;
        color: $charcoal-grey;
    }

    &__price {
        @include flex(column, flex-start, flex-end);
        padding-left: 30px;
        font-family: $font-semibold;
        color: $charcoal-grey;
    }

    &__price--new {
        color: $cerise;
        line-height: 1.43;
    }

    &__price-label {
        white-space: nowrap;
        font-size: 14px;
        line-height: 1.43;
    }

    &__price-value {
        font-size: 20px;
        line-height: 1;
        text-transform: uppercase;
    }

    &__info {
        @include flex(row, center, center, wrap);
        margin-left: 40px;

        @media (max-width: 700px) {
            margin-left: 0;
            margin-top: 20px;
            width: auto;
            max-width: 80%;
        }

        @media (max-width: 480px) {
            max-width: 100%;
        }
    }

    &__paragraph {
        width: 100%;
        padding: 10px 0;
        font-family: $font-regular;
        font-size: 30px;
        line-height: 40px;
        color: $charcoal-grey;

        @media (max-width: 1000px) and (min-width: 699px) {
            font-size: 24px;
            line-height: 26px;
        }

        @media (max-width: 700px) {
            font-size: 20px;
            line-height: 22px;
        }
    }

    &__paragraph b,
    &__paragraph strong {
        font-family: $font-semibold;
    }

    &__paragraph strong {
        color: $cerise;
    }

    &__action-box {
        @include flex(row, flex-start, center);
        position: absolute;
        bottom: 0;
        width: 100%;

        @media (max-width: 700px) {
            position: static;
            margin-top: 20px;
        }
    }

    &__action-divider {
        display: none;
        font-family: $font-regular;
        font-size: 26px;
        line-height: 1.54;
        color: $charcoal-grey;
    }

    &__action {
        @include flex(row, center, center);
        height: 56px;
        width: 210px;
        font-family: $font-semibold;
        font-size: 24px;
        line-height: 1;
        text-decoration: none;
        border-radius: 2px;
        border: 1px solid $cerise;
        color: $cerise;

        &:last-child {
            margin-left: 20px;
        }

        @media (max-width: 1000px) {
            height: 40px;
            width: 160px;
            font-size: 20px;
        }
    }

    &__action--filled {
        background-color: $cerise;
        color: $white;
    }

    &__action--large {
        width: 100%;

        @media (max-width: 700px) {
            margin-bottom: 10px;
        }

        @media (min-width: 700px) {
            display: none;
        }
    }

    &:not(.product-landing--free) {

        & .product-landing__action--large {
            display: none;
        }

        & .product-landing__row {
            align-items: flex-start;
        }

        & .product-landing__price--new {
            display: none;
        }

        & .product-landing__price-label {
            display: none;
        }

        & .product-landing__price-value {
            line-height: 1.43;
        }
    }
}

.product-landing--secondary {

    .product-landing__line {
        display: none;
    }

    .product-landing__data {
        width: 450px;
        padding: 20px;
        border: 1px solid $warm-grey;

        @media (max-width: 700px) {
            width: auto;
        }
    }

    .product-landing__name {
        font-size: 22px;
    }

    .product-landing__brand {
        font-size: 20px;
        color: $purpley-grey;
    }

    .product-landing__info {
        align-items: flex-end;
        justify-content: flex-start;

        @media (max-width: 700px) {
            justify-content: center;
        }
    }

    .product-landing__action-box {
        position: static;
        width: 400px;
        flex-direction: column-reverse;
        @media (max-width: 1000px) {
            width: 300px;
        }

        @media (max-width: 700px) {
            align-items: center;
        }
    }

    .product-landing__action {
        width: 100%;
        height: 60px;
        font-size: 26px;

        &:last-child {
            margin-left: 0;
        }
    }

    .product-landing__action-divider {
        display: block;
    }
}

.product-landing--free {

    .product-landing__info {
        align-items: flex-end;
    }
    
    .product-landing__action-box {
        position: static;
    }

    .product-landing__action-box .product-landing__action {
        width: 50%;
    }

    .product-landing__price--original .product-landing__price-value {
        text-decoration: line-through;
    }
}
