@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.favorites-block {
    width: 100%;
    min-width: 250px;
    min-height: 50px;
    height: 60vh;
    @include flex(column, flex-start, stretch);
    @include animate-right-panel;

    &__top {
        @include flex(row, space-between, center);
        padding: 15px 10px;
        border-bottom: 1px solid $gray-in-active;
        background-color: $gray-lt;
    }

    &__top .title {
        @include flex(row, flex-start, center);
        font-family: $font-semibold;

        &__icon {
            display: block;
            width: 16px;
            height: 13px;
            background-image: url("../../../images/heart-active.svg");
            -webkit-background-size: cover;
            background-size: cover;
        }

        &__text {
            font-size: 17px;
            color: $charcoal-grey;
            margin-left: 10px;
        }
    }

    &__top .count {
        font-family: $font-semibold;
        font-size: 17px;
        color: $charcoal-grey;
    }

    &__products {
        max-height: calc(100vh - 340px);
        padding: 0 10px;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background-color: $gray-lt;

        .favorite-product {
            display: flex;
            padding: 12px 0 12px 0;
            border-top: 1px solid $gray-in-active;

            &:first-child {
                border-top: none;
            }

            &__photo {
                min-width: 60px;
                width: 60px;
                height: 60px;
                background-color: $white;
                position: relative;
                cursor: pointer;

                & > img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            &-col {
                @include flex(column);
            }

            &-row {
                @include flex(column);
            }

            &__info {
                @include flex(column, space-around, space-between);
                width: 100%;

                margin-left: 7px;
                font-family: $font-regular;
                color: $charcoal-grey;
                min-width: 130px;

                a {
                    text-decoration: none;
                }

                &__actions {
                    display: flex;
                    margin-top: 5px;
                    font-size: 12px;

                    &__delete {
                        display: block;
                        padding-right: 5px;
                        cursor: pointer;
                    }

                    &__buy {
                        display: block;
                        position: relative;
                        padding-left: 5px;
                        text-decoration: none;
                        color: $cerise;
                        font-family: $font-semibold;
                        cursor: pointer;

                        &:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: "";
                            width: 1px;
                            height: 100%;
                            background-color: $charcoal-grey;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .favorites-block {
        min-width: 0;
        max-height: none;

        &__products .favorite-product__info {
            min-width: 0;
            width: auto;
        }
    }
}

@media (max-width: 480px) {
    .favorites-block {
        margin: 0;
        height: 100%;
    }

    .favorites-block__products {
        max-height: 100%;
        flex-grow: 1;
    }
}
