@import "./colors";

.settings-container {
    position: relative;
    display: flex;
    padding: 52px 42px;
    justify-content: space-between;
    max-width: 1024px;
    margin: 0 auto;
    z-index: 0;

    &--quick-to {
        max-width: 1180px;
    }
}

@media (max-width: 480px) {
    .settings-container {
        display: block;
        padding: 0;
    }
    .settings-header {
        display: none;
    }

    .settings-wrapper {
        .mp-footer {
            display: none;
        }
    }
}

.market-place-settings {
    position: relative;
    display: flex;
    padding: 52px 42px;
    justify-content: space-between;
    max-width: 1024px;
    margin: 0 auto;
    z-index: 0;

    &-menu {
        display: flex;
        flex-direction: column;
        width: 15%;

        &__item {
            position: relative;
            margin-top: 25px;
            padding: 5px 8px;
            font-family: proxima_nova_light, Helvetica, sans-serif;
            color: $purpley-grey;
            text-decoration: none;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                height: 0;
                width: 3px;
                background-color: $cerise;
                transition: .3s;
            }

            &.is-active {
                color: $cerise;

                &:before {
                    height: 100%;
                }
            }
        }
    }

    &-content {
        width: 80%;
        padding-bottom: 50px;

        &__actions {
            align-self: flex-end;
            margin-top: 46px;

            .button {
                width: 160px;
                padding: 11px 50px;
                border: 1px solid;
                border-radius: 2px;
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                font-size: 18px;
                outline: none;
                cursor: pointer;

                &_cancel {
                    background-color: transparent;
                    color: $pinkish-grey-dk;
                    border-color: $pinkish-grey-dk;
                }

                &_save {
                    margin-left: 21px;
                    background-color: $cerise;
                    color: $white;
                }
            }

            .disabled {
                pointer-events: none;
                background-color: gray;
            }
        }

        @media (max-width: 480px) {
            width: 100%;
        }

        .shipping-tab__name,
        .shipping-tab__address {
            font-size: 14px;
        }
    }

    .form-settings-payment {
        .row-card-mobile-view {
            .form-row-block {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                max-width: 242px;
                width: 100%;

                &:first-of-type {
                    max-width: 305px;
                }
            }
        }
    }
}

.user-menu-settings {
    position: relative;
    text-align: center;

    &__title {
        padding-top: 22px;
        padding-bottom: 26px;
        font-size: 17px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }

    .user-menu-top__back {
        position: absolute;
        top: 22px;
        left: 12px;
    }
}

.market-place-settings-content {
    .market-place-settings-content__actions {
        .common-button {
            padding: 12px 50px;

            &:last-child {
                margin-left: 20px;
            }
        }

    }
}
.form-settings-shipping {
    @media (max-width: 480px) {
        .two-inputs-row {
            &:first-child {
                flex-direction: column;
                .common-input-wraper {
                    margin: 0
                }
            }

        }

        .market-place-settings-content__actions {
            margin-top: 15px;

            .common-button {
                margin: 5px;
            }
        }
    }
}
@media (max-width: 768px) {
    .market-place-settings {
        .form-settings-payment {
            .row-card-mobile-view {
                flex-direction: column;
                align-items: flex-start;

                .form-row-block {
                    &:first-of-type {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.tabs-container {

    @media (max-width: 480px) {
        position: fixed;
        height: 100%;
        width: 100%;
        left: -100%;
        top: 0;
        transition: .5s;
        background: $white;
        margin-top: 61px;
        z-index: 2;

        &.menu-opened {
            left: 0;
        }
    }
}
.settings-tab {
    position: relative;
    margin-top: 25px;
    padding: 5px 8px;
    font-family: proxima_nova_light, Helvetica, sans-serif;
    color: $purpley-grey;
    font-size: 14px;

    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 0;
        width: 3px;
        background-color: $cerise;
        transition: .3s;
    }

    &__link {
        display: inline-block;
        cursor: pointer;
    }

    &.tab_active {
        color: $cerise;
        font-family: proxima_nova_bold, Helvetica, sans-serif;

        &:before {
            height: 100%;
        }
    }

    @media (max-width: 480px) {
        margin-top: 0;
        padding: 22px 12px;
        border-top: 1px solid $pinkish-grey;
        color: $dark-grey-blue;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;

        &:before {
            display: none;
        }

        &.tab_active {
            color: $dark-grey-blue;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;

            &:before {
                display: none;
            }
        }
        &:after {
            position: absolute;
            content: '';
            width: 12px;
            height: 12px;
            right: 12px;
            border-right: 1px solid $pinkish-grey;
            border-bottom: 1px solid $pinkish-grey;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            display: block;
        }
    }

}
.aside-mobile-header {
    position: relative;
    text-align: center;

    &.menu-opened {
        position: fixed;
        top: 0;
        background: #ffffff;
        width: 100%;
        z-index: 2;
    }

    .header-arrow {
        position: absolute;
        top: 20px;
        left: 12px;

        width: 16px;
        height: 16px;
        background-image: url('../images/back.svg');
        background-size: cover;
        cursor: pointer;
    }

    &:before {
        position: fixed;
        content: '';
        left: 0;
        top: 0;
        height: 5px;
        width: 100%;
        background-color: $cerise;
        z-index: 1;
    }

    .header-title {
        display: inline-block;
        padding: 22px 0;
        font-size: 17px;
        color: $charcoal-grey;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }

    @media (min-width: 481px) {
        display: none;
    }

}
.aside {
    margin-top: 50px;
    max-width: 150px;
    @media (max-width: 480px) {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
    }
}

.no-avatar-logo {
    position: relative;
    height: 122px;
    width: 122px;
    padding: 27px 0;
    border: 1px solid $pinkish-grey;
    border-radius: 999rem;
    text-align: center;
    box-sizing: border-box;

    &__icon {
        width: 30px;
        height: 30px;
        margin: 0 auto;
        background-image: url('../images/plus.svg');
        -webkit-background-size: cover;
        background-size: cover;
    }

    &__text {
        display: block;
        margin-top: 18px;
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        font-size: 17px;
        color: $purpley-grey;
    }

    @media (max-width: 480px) {
        margin: 0 auto;
    }
}

.fb-avatar {

    height: 122px;
    width: 122px;
    border: 1px solid $pinkish-grey;
    border-radius: 999rem;
    box-sizing: border-box;
    padding: 0;

    img {
        height: 120px;
        border-radius: 999rem;
    }
}
