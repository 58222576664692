@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.product-carousel {
    @media (max-width: 667px) {
        height: 225px;
        max-width: 300px;
        margin: 40px auto 0;
    }

    &__item {
        margin: 4px;
    }

    &__item-img {
        width: auto;
        height: auto;
        max-width: 70px;
        max-height: 70px;
        cursor: pointer;
        border: 1px solid transparent;
        
        @media (max-width: 970px) and (min-width: 769px) {
            max-width: 50px;
            max-height: 50px;
        }

        @media (max-width: 768px) and (min-width: 668px) {
            max-width: 40px;
            max-height: 40px;
        }

        @media (max-width: 667px) {
            max-width: 34px;
            max-height: 34px;
        }
    }

    &__item-img.is-active {
        border: 1px solid $pinkish-grey-dk;
    }

    &--desktop {
        transform: translateY(-4px);
    }


    // ---------- slick overrides


    .slick-slider {
        width: 100%;
    }

    .slick-list {
        margin: 0 auto;
    }

    .slick-track {
        align-items: center;
    }

    .slick-dots {
        top: -45px;
        max-height: 20px;

        @media (max-width: 480px) {
            top: -42px;
            padding: 0 24px;
        }
    }

    .slick-dots li {
        width: 10px;
        height: 10px;
        margin: 0 10px;
    }

    .slick-dots button {
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid $pinkish-grey-dk;
    }

    .slick-dots button:before {
        content: '';
        width: 10px;
        height: 10px;
        line-height: 10px;
    }

    .slick-dots .slick-active button {
        background-color: $pinkish-grey-dk;
    }
}
