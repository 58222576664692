@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.mp-modal {
    @include flex(column, space-between, center);
    position: relative;
    max-width: 1320px;
    width: 100%;
    margin: 50px auto;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);

    &.full-sized {
        width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        height: auto;
        margin: 0;
        border-radius: 0;

        @media (min-width: 668px) {
            justify-content: center;
            height: 100vh;
            max-height: 100vh;
        }
    }

    &.full-sized .mp-modal-product {
        align-items: center;
        min-height: 0;
    }

    &.full-sized .mp-modal-product__carousel {
        min-height: 0;
    }

    &.full-sized .mp-modal-purchase {
        min-height: inherit;
    }

    &.full-sized &__back {
        display: none;
    }

    &.full-sized .purchase-complete {
        @media (max-width: 667px) {
            // height: 100vh;
        }
    }

    @media (max-width: 960px) {
        height: auto;
        min-height: 0;
    }

    @media (max-width: 768px) and (min-width: 668px) {
        width: 96%;
    }

    @media (max-width: 667px) {
        display: flex;
        width: 100%;
        top: 0;
        margin: 0;
        z-index: 8;
        overflow-y: auto;
        border-radius: 0;
        box-shadow: none;
    }

    &__back {
        position: absolute;
        display: block;
        width: 26px;
        height: 26px;
        top: 30px;
        left: 30px;
        background: url(../../images/back.svg) 0 0 no-repeat;
        background-size: cover;
        cursor: pointer;
        z-index: 1;

        @media (max-width: 768px) and (min-width: 668px) {
            left: 20px;
            top: 20px;
        }

        @media (max-width: 667px) {
            top: 12px;
            left: 12px;
            height: 16px;
            width: 16px;
        }
    }

    &__close {
        position: absolute;
        top: 27px;
        right: 27px;
        width: 26px;
        height: 26px;
        background: url(../../images/cancel.svg) 0 0 no-repeat;
        cursor: pointer;
        background-size: cover;

        @media (max-width: 768px) and (min-width: 668px) {
            top: 20px;
        }

        @media (max-width: 667px) {
            display: none;
        }
    }

    // ---------- product info

    &-product {
        @include flex(row, space-between, stretch);
        width: 100%;
        min-height: 534px;
        padding: 60px 50px 40px;

        @media (max-width: 768px) and (min-width: 668px) {
            padding-left: 20px;
            min-height: 430px;
        }

        @media (max-width: 667px) {
            flex-direction: column;
            min-height: 0;
        }

        @media (max-width: 667px) and (min-width: 481px) {
            padding: 12px 24px 40px;
        }

        @media (max-width: 480px) {
            padding-top: 10px;
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    &-product__carousel {
        width: 66.3%;
        min-height: 100%;
        @media (max-width: 667px) {
            width: 100%;
            min-height: 0;
        }
    }

    &-product__info {
        width: 33.7%;
        min-height: inherit;
        @media (max-width: 667px) {
            width: 100%;
        }
    }

    &__footer {
        width: 100%;
    }

    &__more-wrapper {
        display: block;
        padding: 0 12px 12px;
        text-align: right;
        background-color: $alice-blue-two;

        @media (min-width: 668px) {
            display: none;
        }
    }

    &__more {
        font-family: $font-regular;
        font-size: 14px;
        line-height: normal;
        color: $blue;
        cursor: pointer;
    }

    // ---------- product purchase

    &-purchase {
        @include flex(row, flex-start, stretch);
        width: 100%;

        @media (max-width: 667px) {
            flex-direction: column;
        }
    }

    &-purchase__steps {
        flex-grow: 1;
        @include flex(column, flex-start, flex-start);
        padding: 100px 60px 40px 30px;
        overflow-x: hidden;

        @media (max-width: 1024px) and (min-width: 769px) {
            padding-right: 30px;
        }

        @media (max-width: 768px) and (min-width: 668px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (max-width: 667px) {
            width: 100%;
            padding: 20px 10px 80px;
        }
    }

    &-purchase__step-list {
        @include flex;
        width: 100%;
        margin-bottom: 20px;

        @media (max-width: 667px) {
            justify-content: center;
        }
    }

    &-purchase__step {
        position: relative;
        font-family: $font-semibold;
        font-size: 18px;
        line-height: normal;
        letter-spacing: .7px;
        color: $pinkish-grey-dk;
        text-transform: uppercase;
        cursor: pointer;

        &:first-of-type {
            @media (max-width: 667px) {
                @include flex(row, flex-end, center);
                width: 35px;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &:not(:first-child) {
            padding-left: 23px;

            @media (max-width: 768px) and (min-width: 668px) {
                padding-left: 16px;
            }
        }

        &:not(:first-child):after {
            position: absolute;
            content: '';
            top: 50%;
            left: 6px;
            width: 8px;
            height: 8px;
            border-right: 2px solid $pinkish-grey-dk;
            border-bottom: 2px solid $pinkish-grey-dk;
            transform: translateY(calc(-50% - 1px)) rotate(-45deg);

            @media (max-width: 768px) and (min-width: 668px) {
                left: 2px;
            }
        }

        @media (max-width: 850px) {
            font-size: 14px;
        }

        @media (max-width: 768px) and (min-width: 668px) {
            letter-spacing: 0;
        }
    }

    &-purchase__step.is-active {
        color: $cerise;
    }

    &__special-link-text {
        font-size: 12px;
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $purpley-grey;
        font-family: $font-light;
        @media (max-width: 667px) {
            font-size: 10px;
            max-width: 60%;
            text-align: center;
            margin: 10px auto;
        }
    }

    &__special-link {
        color: $blue;
        text-decoration: none;
    }

    // ---------- purchase steps elements overrides

    &-purchase__billing .accordion .card-item,
    &-purchase__billing .user-email > div,
    &-purchase__confirmation .accordion .card-item,
    &-purchase__confirmation .user-email > div,
    .shipping-tab__label {

        @media (max-width: 768px) and (min-width: 668px) {
            font-size: 16px;
        }
        @media (max-width: 480px) {
            font-size: 14px;
        }
    }

    &-purchase__confirmation .user-email,
    &-purchase__billing .user-email {
        text-align: right;
        font-family: $font-regular;
    }

    &-purchase__confirmation .user-email > div,
    &-purchase__billing .user-email > div {
        height: 62px;
        line-height: 62px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 20px;
        text-align: left;
        border-radius: 2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border: 1px solid $pinkish-grey;
        background-color: $gray-lt;

        @media (max-width: 667px) {
            height: 44px;
            line-height: 44px;
            padding-left: 10px;
            font-size: 14px;
        }
    }

    &-purchase__confirmation .user-email > span,
    &-purchase__billing .user-email > span {
        font-size: 12px;
        color: $blue;
        line-height: 2;
        cursor: pointer;
    }

    &-purchase__confirmation .card-item,
    &-purchase__billing .card-item {
        padding-top: 19px;
        padding-bottom: 19px;
        font-family: $font-regular;
        font-size: 20px;
        color: $charcoal-grey;
        letter-spacing: 0;
    }

    .card-item {
        @media (max-width: 1185px) {
            flex-direction: column;
        }

        @media (max-width: 768px) {
            padding: 12px 14px;
        }

        @media (max-width: 667px) and (min-width: 481px) {
            flex-direction: row;
        }

        @media (max-width: 667px) {
            font-size: 14px;
        }
    }

    .card-item__info {
        @media (max-width: 1185px) {
            justify-content: flex-start;
        }
    }

    .card-item__info,
    .card-item__address {
        @media (max-width: 1185px) {
            width: 100%;
        }
    }

    .card-item-logo {
        @media (max-width: 1185px){
            width: auto;
        }
    }

    .card-item .expires {
        @media (max-width: 1185px) {
            width: auto;
            margin-left: 51px;
        }

        @media (max-width: 667px) and (min-width: 481px) {
            margin-left: 22px;
        }
    }

    .card-item__address {
        @media (max-width: 1185px) {
            margin-top: 8px;
            position: static;
            padding-left: 50px;
        }

        @media (max-width: 667px) and (min-width: 481px) {
            margin-top: 0;
            padding-left: 15px;
        }

        @media (max-width: 480px) {
            padding-left: 75px;
            margin-top: 6px;
        }
    }

    // ----------- accordion

    .accordion {
        max-width: 100%;
        margin-top: 10px;
    }

    .accordion .headline {
        margin-bottom: 5px;

        @media (max-width: 480px) {
            line-height: normal;
        }
    }

    .accordion .headline__title {
        display: none;
    }

    .accordion-default-item {
        display: block;
    }

    .accordion .headline {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        padding: 0;
        background: #fff;
        border: none;
    }

    .accordion .edit-button {
        font-family: $font-regular;

        @media (max-width: 667px) {
            font-size: 12px;
        }
    }

    .accordion-items-list {
        height: 0;
    }

    .accordion-address {
        @media (max-width: 667px) {
            padding: 10px 25px;
            font-size: 12px;
        }
    }

    // ---------- common-button

    .market-place-settings-content__actions .common-button {
        width: 100%;
        padding: 19px 55px;
        height: auto;
        font-size: 22px;
        line-height: 23px;

        @media (max-width: 667px) {
            margin: 0;
            padding: 13px 55px;
            font-size: 14px;
        }
    }

    // ---------- common-input

    .common-input-wraper input {
        font-family: $font-regular;

        @media (max-width: 667px) {
            padding: 13px 10px;
            font-size: 14px;
        }
    }

    // ---------- security-logos

    .security-logos {
        @include flex(row, flex-end, center);
        width: 100%;
        margin-top: 5px;
    }

    .security-logos__icon {
        max-width: 50px;
        max-height: 30px;
        width: auto;
        height: auto;
        margin-left: 5px;
    }
}

.open-modal {
    @media (max-width: 667px) {
        overflow: hidden;
    }
}

.overlay.overlay-y {
    @media (max-width: 667px) {
        z-index: 8;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &.step-productInfo,
        &.step-loggedInAs,
        &.step-customerInfo,
        &.step-shipping,
        &.step-billing,
        &.step-confirmation {
            padding: 0;
            background: $white;
        }

        &.overlay-auth {
            z-index: 7;
        }
    }
}

#favorites-discount-tooltip {
    border: 1px solid $cerise;
    background: $white;
    color: $cerise;
    padding: 15px;
    opacity: 1;
    max-width: 235px;

    font: {
        family: $font-semibold;
        size: 16px;
    }

    &.show {
        margin-top: 10px !important;
    }

    &:after,
    &:before {
        border-color: transparent;
    }
}

.marketplace-link {
    display: flex;
    align-items: center;
    font-family: proxima_nova_regular, Helvetica, sans-serif;
    font-size: 11px;
    font-style: italic;
    letter-spacing: -.2px;
    line-height: 1;
    color: #313d4f;
    text-decoration: none;

    &-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        padding: 0 18px 2px;
        z-index: 9;
    }

    &__logo {
        margin-left: 3px;
        width: 39px;
        height: 15px;
    }
}
