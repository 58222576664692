@import "../../../styles/animation";
@import "../../../styles/colors";
@import "../../../styles/mixins";

.product-placeholder {
    @include flex(column, flex-start, flex-start);
    height: 100%;
    padding: 10px;
    background: $white;

    &--loading {
        .img-placeholder__img,
        .line {
            animation: hintloading 2s ease-in-out 0s infinite reverse;
        }
    }
}

.img-placeholder {
    width: 100%;
    height: 60%;
    flex-grow: 1;

    &__img {
        border: 1px solid white;
        width: 100%;
        height: 100%;
        background-color: $white-smoke;
    }
}

.content-placeholder {
    @include flex(row, space-between, flex-end);
    flex-wrap: wrap;
    height: 25%;
    padding-top: 8px;
}

.line {
    height: 12px;
    background: $white-smoke;
    border-radius: 2px;
    margin-bottom: 5px;

    &--70 {
        width: 70%;
    }

    &--50 {
        width: 50%;
    }

    &--25 {
        width: 25%;
    }
}
