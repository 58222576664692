@import "../../../styles/mixins";
@import "../../../styles/colors";
@import "../../../styles/variables";

.tutorial-modal {
    position: relative;
    max-width: 540px;
    width: 100%;
    margin: 40px auto;
    padding-bottom: 100px;
    background: $white;
    @media (max-width: 667px) {
        max-width: 100%;
        height: 100%;
        margin: 0;
    }

    &__wrapper {
        z-index: 8;
    }

    &__main {
        background: $white;
        @media (max-width: 667px) {
            padding-top: 30px;
            padding-bottom: 70px;
        }
    }

    &__top-line {
        height: 5px;
        width: 100%;
        background-image: linear-gradient(262deg, $white, $dark-cream 0, $sunflower-yellow 29%, $violet-pink 80%, $heliotrope 100%);
    }

    &__close {
        position: absolute;
        top: 18px;
        right: 20px;
        width: 14px;
        height: 14px;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
        @media (max-width: 667px) {
            top: 16px;
            z-index: 2;
        }
    }

    &__header {
        padding: 26px 40px 10px;
        @media (max-width: 667px) {
            @include flex(row, flex-start, center);
            position: relative;
            padding: 20px 14px 26px;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.44);
            z-index: 1;
        }

        &__logo {
            display: inline-block;
            max-width: 80px;
            width: 100%;
            max-height: 30px;
            height: 100%;
            margin-bottom: 12px;
            @media (max-width: 667px) {
                max-height: 24px;
                margin-bottom: 0;
            }
        }

        &__title {
            display: none;
            @media (max-width: 667px) {
                display: inline-block;
                margin-left: 10px;
                line-height: normal;
                letter-spacing: normal;
                color: $cerise;
                font-family: $font-light;
                font: {
                    size: 15px;
                    weight: 300;
                    style: normal;
                    stretch: normal;
                }
            }
        }

        &__line {
            width: 100%;
            height: 2px;
            background: $gray-in-active;
            @media (max-width: 667px) {
                display: none;
            }
        }
    }

    &__title {
        margin-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 29px;
        letter-spacing: normal;
        text-align: center;
        color: $cerise;
        font-family: $font-bold;
        font: {
            size: 24px;
            weight: bold;
            style: normal;
            stretch: normal;
        }
        @media (max-width: 667px) {
            width: 90%;
            margin: 10px auto 0;
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__sub-title {
        width: 75%;
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px;
        font-family: $font-regular;
        line-height: 29px;
        letter-spacing: normal;
        text-align: center;
        color: $biscay;
        font: {
            size: 24px;
            weight: bold;
            style: normal;
            stretch: normal;
        }
        @media (max-width: 667px) {
            width: 80%;
            font-size: 18px;
            line-height: 24px;
        }
        @media (max-width: 374px) {
            width: 100%;
            font-size: 16px;
            line-height: 22px;
            padding: 0;
        }

        &:first-of-type {
            margin-top: 14px;
        }
    }

    .text-bold {
        font-family: $font-bold;
    }

    &__block-img {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        box-sizing: border-box;
        @include flex(column, center, center);

        &__img {
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .step-welcome {
        .tutorial-modal__sub-title {
            width: 90%;
            @media (max-width: 667px) {
                width: 95%;
            }
        }

        .tutorial-modal__block-img {
            height: 342px;
            margin-top: -10px;
            padding: 0;
            @media (max-width: 667px) {
                height: auto;
            }
            @media (max-width: 375px) {
                height: 306px;
            }
            @media (max-width: 360px) {
                height: 260px;
            }

            &__img {
                height: 342px;
                background-position: 0 10px;
                @media (max-width: 667px) {
                    height: 544px;
                }
                @media (max-width: 568px) {
                    height: 462px;
                }
                @media (max-width: 480px) {
                    height: 392px;
                }
                @media (max-width: 375px) {
                    height: 306px;
                }
                @media (max-width: 360px) {
                    height: 260px;
                }
            }
        }
    }

    .step-favorite {
        .tutorial-modal__block-img {
            height: 400px;
            padding: 0;
            @media (max-width: 667px) {
                height: auto;
                margin-top: 36px;
            }
            @media (max-width: 480px) {
                margin-top: 20px;
            }
            @media (max-width: 375px) {
                height: 300px;
            }
            @media (max-width: 360px) {
                height: 260px;
            }

            &__img {
                height: 346px;
                background-position: -574px 0;
                @media (max-width: 667px) {
                    height: 540px;
                    background-position: -696px 0;
                }
                @media (max-width: 568px) {
                    height: 452px;
                    background-position: -580px 0;
                }
                @media (max-width: 480px) {
                    height: 385px;
                    background-position: -495px 0;
                }
                @media (max-width: 375px) {
                    height: 302px;
                    background-position: -385px 0;
                }
                @media (max-width: 360px) {
                    height: 260px;
                    background-position: -338px 0;
                }
            }
        }

        .tutorial-modal__sub-title {
            width: 85%;
            @media (max-width: 667px) {
                width: 80%;
            }
        }
    }

    .step-confirm {
        .tutorial-modal__block-img {
            height: 371px;
            padding: 0;
            @media (max-width: 667px) {
                height: auto;
                margin-top: 36px;
            }
            @media (max-width: 480px) {
                margin-top: 20px;
            }

            &__img {
                height: 346px;
                background-position: -1136px 0;
                @media (max-width: 667px) {
                    height: 540px;
                    background-position: -1396px 0;
                }
                @media (max-width: 568px) {
                    height: 460px;
                    background-position: -1190px 0;
                }
                @media (max-width: 480px) {
                    height: 388px;
                    background-position: -1003px 0;
                }
                @media (max-width: 375px) {
                    height: 302px;
                    background-position: -780px 0;
                }
                @media (max-width: 360px) {
                    height: 260px;
                    background-position: -674px 0;
                }
            }
        }

        .tutorial-modal__sub-title {
            width: 70%;
            margin: 14px auto 0;
            @media (max-width: 667px) {
                width: 80%;
            }
        }
    }

    &__block-btn {
        text-align: center;
        @media (max-width: 667px) {
            position: fixed;
            width: 100%;
            left: 0;
            bottom: 0;
            padding: 10px 14px;
            background: $white;
        }
    }

    .common-button {
        max-width: 260px;
        width: 100%;
        font-family: $font-semibold;
        font-size: 17px;
        @media (max-width: 667px) {
            max-width: 100%;
        }
    }
}
