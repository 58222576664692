
.overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 8;

    @media all and (max-width: 480px){
        height: calc(100vh - 50px);
    }

    &-y {
        overflow-y: auto;
    }
}

@media (max-width: 480px) {
    .overlay {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        &-y {
            overflow-y: auto;
        }
    }

    .overlay.image-view-in-mobile .slick-track {
        display: flex !important;
    }

    .overlay.image-view-in-mobile .product-carousel {
        width: 100%;
        height: 140px;
    }

    .overlay.image-view-in-mobile .product-carousel__item:focus {
        outline: none;
    }
}
