@import '../../../styles/mixins';

.brands-carousel {

    &.slick-slider {
        @include flex(row, center, center);
    }

    .slick-list {
        width: 100%;
    }

    &__item img {
        max-height: 40px;
        width: auto;
        margin: 0 auto;
        @media (max-width: 960px) and (min-width: 481px) {
            max-height: 34px;
        }

        @media (max-width: 480px) and (min-width: 376px) {
            max-height: 28px;
        }

        @media (max-width: 375px) {
            max-height: 22px;
        }
    }

    &__prev, &__next {
        cursor: pointer;
        
        @media (max-width: 480px) {
            img {
                max-width: 36px;
            }
        }
    }
}
