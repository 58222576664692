@import 'colors.scss';
@import 'mixins.scss';

.overlay {
    &-auth {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px;
    }

    &-auth.full-sized {
        @include flex(column, center);
        min-width: 100vw;
        min-height: 100vh;
        background-color: $white;

        @media (max-width: 480px) {
            padding-top: 30px;
            justify-content: flex-start;
        }
    }

    &-auth.full-sized .auth-logo__img {
        max-width: 212px;
    }

    &-auth.full-sized > .auth {
        box-shadow: none;
        border: none;
        min-height: 0;
        padding: 15px 40px 15px;

        @media (max-width: 480px) {
            padding: 0;
        }
    }
}

.auth {
    position: relative;
    margin: auto;
    padding: 25px 58px 90px;
    max-width: 460px;
    min-height: 744px;
    width: 100%;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
    border: solid 1px #dfe3e9;

    &-logo {
        margin-top: 12px;
        text-align: center;

        &__img {
            max-width: 200px;
            width: 100%;
            height: 70px;
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        padding: 0 14px 22px 10px;
    }

    &__form {
        position: relative;
        padding: 0 10px;
    }

    .form {
        &__title {
            max-width: 260px;
            margin: 10px auto 20px;
            font-size: 28px;
            letter-spacing: 0.1px;
            text-align: center;
            font-family: 'proxima_nova_regular', Helvetica, sans-serif;
            font-weight: normal;
        }

        &__title.small {
            font-size: 20px;
            max-width: 300px;
        }

        &__text {
            margin-top: 20px;
            font-size: 16px;
            font-family: 'proxima_nova_regular', Helvetica, sans-serif;
            color: #4a4a4a;
            text-align: center;
        }

        &__link {
            font-size: 16px;
            color: #2ac7fd;
            cursor: pointer;
        }

        &-row {
            position: relative;
        }
    }

    &__bottom {

        .form__link {
            margin-top: 10px;
            align-self: flex-end;
        }

        .block-errors__text {
            bottom: -10px;
        }
    }

    .common-input-wraper {
        padding-bottom: 18px;
        margin-bottom: 0;

        input {
            padding: 17px 13px;
            font-size: 16px;
        }
    }

    &__close {
        display: block;
        width: 26px;
        height: 26px;
        margin-left: auto;
        margin-right: -25px;
        background-image: url('../images/cancel.svg');
        background-size: cover;
        cursor: pointer;
    }

    &__close.disabled {
        pointer-events: none;
    }

    &__button {
        width: 100%;
        padding: 17px 0;
        background-color: $cerise;
        border-radius: 2px;
        font-family: proxima_nova_light, Helvetica, sans-serif;
        font-size: 16px;
        color: #fff;
        border: none;
        cursor: pointer;
    }

    &__button_fb {
        @include flex(row, center, center);
        background-color: #3b5998;
    }

    &__button_fb img {
        height: 20px;
        margin-right: 10px;
    }

    &__button_fb span {
        transform: translateY(2px);
    }

    &-devidor {
        position: relative;
        display: flex;

        &__text {
            padding: 10px;
            margin-left: 50%;
            transform: translateX(-50%);
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
            font-size: 14px;
            background-color: #fff;
            color: $charcoal-grey;
            text-transform: uppercase;
            z-index: 1;
        }

        &__line {
            position: absolute;
            top: 50%;
            width: 50%;
            height: 1px;
            background-color: #dfe2e5;
            transform: translateY(-50%);
            display: none;

            &_left {
                left: 0;
            }

            &_right {
                right: 0;
            }
        }
    }

    .form {
        max-width: 100%;

        &-row {
            position: relative;

            &:first-of-type {
                margin-top: 0;
            }

            &:not(:first-of-type) {
                margin-top: 17px;
            }
        }
    }

    &.sign-up {
        .common-input-wraper {
            margin-bottom: 0;
        }
    }

    &.sign-in {

        .forgot-link {
            width: 100%;
            margin: 15px auto 0;
            text-align: center;
            font-size: 16px;
            color: #2ac7fd;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
        }
    }

    .common-button {
        width: 100%;
        padding: 17px 0;
        font-size: 16px;
    }

    .sign-in-form,
    .sign-up-form {
        margin-top: 0;
        text-align: center;

        .block-errors__text {
            bottom: 5px;
        }
    }

    .sign-up-form {
        .form__text {
            color: #313d4f;
            font-family: proxima_nova_regular, Helvetica, sans-serif;

            &.sign-in {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

        .privacy-info {
            max-width: 245px;
            margin: 0 auto;
            font-size: 14px;

            .form__link {
                color: #313d4f;
                font-size: 14px;
                font-weight: bold;
                text-decoration: none;
                font-family: proxima_nova_semibold, Helvetica, sans-serif;
            }
        }
    }

    .sign-reset-form {
        &__title {
            font-size: 16px;
            text-align: center;
        }

        &__message {
            font-size: 14px;
            text-align: center;
        }

        input {
            height: auto;
            padding: 17px 13px;
            font-size: 16px;
        }

        .block-errors {
            top: 0;
            right: 0;
        }

        .btn-forgot {
            margin-top: 15px;
            padding: 17px 13px;
            height: auto;
            font-size: 16px;
        }
    }

    .reset-your-password {
        &__title {
            margin-bottom: 5px;
            font-size: 16px;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
        }

        .input-wrapper {
            margin-bottom: 10px;
        }

        input,
        button {
            padding: 17px 13px;
            height: auto;
        }
    }

    &-footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 25px;
        text-align: center;
        font-size: 20px;
        line-height: 21px;
        color: #4a4a4a;
        border-top: 2px solid #dfe2e5;
        cursor: pointer;
        text-decoration: none;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }

    input {
        &::-webkit-input-placeholder {
            font-family: 'proxima_nova_regular', Helvetica, sans-serif;
            color: #c3c3c3;
        }

        &::-moz-placeholder {
            font-family: 'proxima_nova_regular', Helvetica, sans-serif;
            color: #c3c3c3;
        }

        &:-ms-input-placeholder {
            font-family: 'proxima_nova_regular', Helvetica, sans-serif;
            color: #c3c3c3;
        }
    }
}

@media (max-width: 1440px) and (min-width: 481px) {
    .auth {
        min-height: 634px;
    }
}

@media (min-width: 769px) and (max-width: 1440px) {
    .auth {
        padding: 15px 40px 70px;
        max-width: 440px;
        border-radius: 6px;
        background-color: #ffffff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
        border: solid 1px #dfe3e9;

        &__close {
            width: 22px;
            height: 22px;
        }

        &-logo {
            margin-top: 10px;

            &__img {
                max-width: 180px;
                height: 58px;
            }
        }

        .form__title {
            margin: 8px auto 16px;
            font-size: 24px;
            max-width: 230px;
        }

        &__button {
            padding: 14px 0;
        }

        .common-input-wraper {
            padding-bottom: 14px;

            input {
                padding: 14px 13px;
                font-size: 16px;
            }
        }

        .common-button {
            padding: 14px 0;
        }

        .sign-up-form {
            .form__text.sign-in {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }

        &-footer {
            padding: 15px 25px;
        }
    }
}

@media (max-width: 768px) {
    .auth {
        margin: 0 auto;
        padding: 0 34px 60px;
        background-color: #ffffff;

        &-logo__img {
            max-width: 126px;
            height: 44px;
        }

        &__form {
            padding: 0;
        }

        .form__title {
            margin-top: 8px;
            margin-bottom: 18px;
            font-size: 18px;
        }

        .form__link {
            font-size: 12px;
        }

        &__button {
            padding: 12px 0;
            line-height: 19px;
            font-size: 14px;
        }

        .common-input-wraper {
            padding-bottom: 10px;

            input {
                padding: 12px 13px;
                font-size: 14px;
                line-height: 14px;
            }
        }

        .common-button {
            padding: 12px 13px;
            font-size: 14px;
            line-height: 19px;
        }

        .sign-in-form,
        .sign-up-form {
            .form__text {
                font-size: 12px;
                line-height: 14px;
            }

            .privacy-info {
                max-width: 220px;

                .form__link {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }

        &__close {
            width: 18px;
            height: 18px;
            margin-top: 18px;
            margin-right: -15px;
        }

        .sign-in {
            .forgot-link,
            .form__text {
                font-size: 12px;
                line-height: 10px;
            }

            .forgot-link {
                margin: 10px auto;
            }
        }

        &__button_fb span {
            transform: translateY(1px);
        }

        &-footer {
            padding: 15px;
            font-size: 16px;
            line-height: 15px;
        }

        .sign-reset-form,
        .reset-your-password {
            &__title,
            &__text {
                font-size: 14px;
            }

            .btn,
            .input {
                margin-bottom: 0;
                padding: 12px 13px;
                font-size: 14px;
            }

            .btn-forgot {
                line-height: 19px;
            }

            .block-errors {
                top: 5px;
            }
        }
    }

    .overlay {
        &-auth {
            display: block;
            padding: 28px 10px 50px;
        }
    }
}

@media (max-width: 480px) {
    .overlay {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        &-y {
            overflow-y: auto;
        }
    }

    .auth {
        margin: 0 auto;
        padding: 0 34px 60px;
        box-shadow: none;
        min-height: 565px;
        border: none;
        border-top: 1px solid #dfe3e9;
        box-sizing: border-box;

        &__close {
            margin-right: -15px;
        }

        &__top {
            flex-direction: row;
            align-items: center;
            padding: 20px 0;
        }
    }
}

.sign {
    position: relative;
    padding: 10px;
}

.sign-delimiter {
    display: flex;
    margin: 6px 0 8px 0;
    align-items: center;
    &__line {
        width: 115px;
        height: 1px;
        background-color: #c7c7c7;
    }
    &__or {
        text-align: center;
        flex-basis: 20%;
        color: #9b9b9b;
        font-size: 14px;
        text-transform: uppercase;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }
}

.sign-social {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__button {
        width: 130px;
        height: 36px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 20%;
        border-radius: 1px;
        cursor: pointer;
        text-align: center;
        color: #ffffff;
    }
    &__button-facebook {
        background-color: #3b579d;
    }
    &__button-twitter {
        background-color: #2aa3ef;
    }
    &__icon {
        line-height: 36px;
        font-size: 30px;
    }
}

.sign-title {
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    color: #4a4a4a;
    margin: 15px 0 9px 0;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
}

.sign-message {
    font-weight: 300;
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: #9b9b9b;
    margin-bottom: 15px;
    font-family: proxima_nova_regular, Helvetica, sans-serif;

    &-bold {
        font-family: proxima_nova_bold, Helvetica, sans-serif;
    }
}

.forgot-link {
    display: inline-block;
    color: #4a90e2;
    margin: 0 auto 24px auto;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-family: proxima_nova_semibold, Helvetica, sans-serif;
}

.sign-in-form {
    margin-top: 12px;
    .input-wrapper {
        margin-bottom: 10px;
        padding-bottom: 8px;
        .input {
            margin-bottom: 0;
        }
    }
    .input-wrapper:last-child {
        margin-bottom: 500px;
    }
}

.sign-reset-form {
    &__title {
        margin-top: 8px;
        font-size: 14px;
        color: #4a4a4a;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }
    &__message {
        margin: 7px 0 19px 0;
        font-size: 11px;
        color: #9b9b9b;
        font-family: proxima_nova_regular, Helvetica, sans-serif;
    }
}

.sign-up-form {
    margin-top: 10px;
    .input:first-of-type {
        margin-bottom: 18px;
    }
    .block-errors__text {
        bottom: 8px;
    }
    button {
        margin-top: 3px;
    }
}

.sign-out {
    &__btn {
        width: 125px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: rgb(155, 155, 155);
        cursor: pointer;
        border-radius: 1px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(192, 192, 192);
        border-image: initial;
        margin: auto;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        &--primary {
            background-color: rgb(246, 166, 35);
            color: rgb(255, 255, 255);
            border-color: rgb(246, 166, 35);
        }
    }
    &__message {
        width: 236px;
        height: 24px;
        font-size: 20px;
        letter-spacing: -0.5px;
        text-align: center;
        font-weight: 500;
        color: rgb(74, 74, 74);
        margin: 67px auto 70px;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }
}

.widget250 {
    .sign {
        padding: 0;
    }
}

.widget600 {
    .sign {
        text-align: center;
        &-message {
            margin-top: 10px;
            color: #000;
            font-size: 14px;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
        }
        &-delimiter {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .form-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            &__label {
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                font-size: 11px;
                color: rgb(155, 155, 155);
                text-transform: uppercase;
            }
            &__logo {
                width: 40px;
                height: 13px;
            }
        }
    }
    .sign-up-form {
        .input[name='password']:nth-child(2) {
            margin: 0;
            margin-bottom: 5px;
        }
        .input:first-of-type {
            margin-bottom: 18px;
        }
        .input:last-of-type {
            margin-bottom: 50px;
        }
    }
    .sign__logo {
        display: inline-block;
        text-align: left;
        &__text {
            letter-spacing: 1.7px;
            font-size: 6px;
            text-transform: uppercase;
            color: #4a4a4a;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
        }
        &__img {
            margin-top: 6px;
            width: 100px;
            height: 33px;
        }
    }
    .sign-title {
        color: #4a4a4a;
        font-size: 14px;
        line-height: 1.43;
        text-align: center;
    }

    .forgot-link {
        margin-top: 23px;
    }

    .input-wrapper {
        margin-bottom: 0;
        padding-bottom: 15px;
    }
}

